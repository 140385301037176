import React from 'react';
import { Box } from '@mui/system';
import { Link} from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function DisplayOffer({slides,isLoading}) {
  return (
    <Box sx={{p:'32px 0',display:'flex',flexWrap:'wrap'}} >
            <Box sx={{p:' 0 16px 16px  0',width:{ xs: '100%', md: '33.33%', },display:'flex',justifyContent:'center'}} className="firtst-off" >
                {isLoading?
                <Stack spacing={1} sx={{width:'100%',}}> 
                    <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 }}} /> 
                </Stack>
                :<div ><div className="beffect">
                        <Link to={`/${slides&&slides[0].name}`}>
                            {/* "//cdn.shopify.com/s/files/1/0531/1604/3454/files/490x600.jpg?v=1614293348"  */}
                        <img src={slides&&slides[0].url}
                        alt="" className="img-responsive center-block" />   
                        </Link>  
                    </div> 
                </div>}
            </Box>
            <Box sx={{  width:{ xs: '100%', md: '66.67%',}}} className="last-off" >
                <Box sx={{p:' 0 16px 0  0',width:{xs:'100%',md:'100%',}}}  >
                {isLoading?
                <Stack spacing={1} sx={{width:'100%',}}> 
                    <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 }}} /> 
                </Stack>
                :
                <div className="beffect">
                    <Link to={`/${slides&&slides[1].name}`}>
                    <img src={slides&&slides[1].url} alt="" className="img-responsive center-block" />   
                    </Link>  
                </div>}
                </Box>
                <Box sx={{display:'flex',flexWrap:'wrap'}} >
                    <Box sx={{p:' 0 16px 0  0',width:{ xs: '100%', md: '50%'},display:'flex',justifyContent:'center' }}  >
                        <div className="beffect">
                            <Link to={`/${slides&&slides[2].name}`}>
                            <img src={slides&&slides[2].url} alt="" className="img-responsive center-block" />   
                            </Link>  
                        </div> 
                    </Box>
                    <Box sx={{p:' 0 16px 0  0', width:{ xs: '100%', md: '50%',},display:'flex',justifyContent:'center'}}  >
                        <div className="beffect">
                            <Link to={`/${slides&&slides[3].name}`}>
                            <img src={slides&&slides[3].url} alt="" className="img-responsive center-block" />   
                            </Link>  
                        </div> 
                    </Box>

                </Box>

            </Box>

        </Box>
  )
}

