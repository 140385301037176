// import React from 'react';
// import Box from '@mui/material/Box'; 

// const FAQ = () => {
//     return (
//     <>
//     <Box sx={{width:'100%'}} >
//         <Box  className="container" >

//             FAQ
//         </Box>
//     </Box>
//     </>
//     );
// }
// export default FAQ;

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Banner from "./homes/banner";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Helmet from "react-helmet";

const Accordions=({Summary,Details,panel})=>{
    // const [expanded, setExpanded] = React.useState('panel1');
    // const handleChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    // };
    // expanded={expanded ===`${panel}`} onChange={handleChange(`${panel}`)}
    return(
    <Accordion  sx={{width:'100%',margin:'15px 0'}} >
        <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1">
            <Typography sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1.2rem'}}}>{Summary} </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography sx={{fontSize:{xs:'0.8rem',md:'1rem'}}}>{Details}</Typography>  
        </AccordionDetails>
    </Accordion> 
    )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box  role="tabpanel"  hidden={value !== index} {...other} sx={{width:{ xs: '100%', md: '85%' },}}
      id={`vertical-tabpanel-${index}`}  aria-labelledby={`vertical-tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 1 }}>{children}</Box>
      )}
    </Box>
  );
}
function TabHeading({heading}) { 
    return (
      <Box sx={{width:'100%',display:'flex',justifyContent:'center'}} > 
        <Typography sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1.5rem'},color:'#ffad19'}}>{heading}</Typography> 
      </Box>
    );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function FAQ() {
  const [value, setValue] = React.useState(0);
 
  const { innerWidth: width } = window;
//   console.log(width,height);, innerHeight: height

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <>
  {/* <Helmet>
      <title>FAQ || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
      <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
    Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
    create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
  </Helmet> */}
  <Banner name={`FAQ`}/>
  <Box sx={{width:'100%'}} >
    <Box  className="container" >
        {/* height: 224 */}
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: { xs: 'block', md: 'flex' }, width:'100%',p:'2rem 0',mt:5 }}>
      <Tabs  // orientation={{xs: 'horizontal',md:'vertical'}},fontFamily:'Work Sans' 
        orientation={width<900?`horizontal`:`vertical`}
        variant="scrollable" value={value}  onChange={handleChange}  aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider',width:{ xs: '100%', md: '15%' }, }}>
        <Tab label="General" {...a11yProps(0)} sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'}}} />
        <Tab label="Video" {...a11yProps(1)} sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }} />
        <Tab label="Charges" {...a11yProps(2)} sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }}/>
        <Tab label="Sharing" {...a11yProps(3)}   sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }}/>
        <Tab label="Refund" {...a11yProps(4)}    sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }} />
        <Tab label="Watermark" {...a11yProps(5)}  sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }}/>
        {/* <Tab label="Item Seven" {...a11yProps(6)} sx={{fontWeight:600,fontSize:{xs:'0.8rem',md:'1rem'} }}/> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <TabHeading heading={`General`} />
        <Accordions panel={`panel1`}Summary={`How do I create my invite? `} 
            Details={`For e-invitations, you can log on to WeddingWishlist.com, click the “Invitations” option and just follow the steps.`}/> 
        <Accordions panel={`panel2`}Summary={`What kind of invites can I create at digiinvite.in?`} 
            Details={`At digiinvite.in we offer digital invitations for wedding, anniversary, engagement, birthday, housewarming and baby shower, 
            as well as baby birth announcements. You can choose from animated, video, multipage and regional-language invites. You can view our gallery at https://digiinvite.in`}/> 
        <Accordions panel={`panel3`}Summary={`How do I create my invite?`} 
            Details={`For an e-invitation, you can log on to www.digiinvite.in, select any E-Card (chosen by you), and just follow the steps.`}/>
        <Accordions panel={`panel4`}Summary={`I am looking for a specific design. Can Digiinvite help me create one just like that?`} 
            Details={`We offer a beautiful list of designer cards on our website. However, if you are looking for something specific, you can share a 
            design reference with us on info.digiinvite@gmail.com Our design team, upon full payment, can create a custom invite for you.`}/>
        <Accordions panel={`panel5`}Summary={`I am based in a different city/ Country. Can Digiinvite work on my invitation?`} 
            Details={`Yes. Our services are available across the country, irrespective of which city/ Country you are based in. For specific questions, please write to us at info.digiinvite@gmail.com`}/>

      </TabPanel>
      <TabPanel value={value} index={1}>
      <TabHeading heading={`Video Invitation`} />  
        <Accordions panel={`panel1`} Summary={`Do you do video invites? `} 
            Details={`Yes, we offer wedding video invites starting from ₹999. You can Visit at our Web http://digiinvite.in choose your preferred design and make the purchase. 
            Our team will customise it for you. Just write to us at info.digiinvite@gmail.com and our advisors will assist you.`}/> 

      </TabPanel>
      <TabPanel value={value} index={2}>
      <TabHeading heading={`Additional Editing & Charges`} />
        <Accordions panel={`panel1`} Summary={`What is Customised in the Standard Rate?`} 
            Details={`All Text Matter Details in the Invitation (Eg. Names, Date, Time, Venue, etc.)Images/ Photos (if shown in the template you choose)
            In the case of Video Invitation – Song can be changed. (Share song link song of your choice | Note that we do not provide song editing/ cutting services)
            If you want to change font style/color, or background colo it will be an additional Charges According to Changes & you need to tell us beforehand`}/> 

      </TabPanel>
      <TabPanel value={value} index={3}>
      <TabHeading heading={`How share with Friends`} />
        <Accordions panel={`panel1`} Summary={`How can I share my e-invitation with my friends?`} 
            Details={`Once you have created your invite, you will get an option of sharing it with your friends and family via email or Facebook. 
            If you are on mobile, you can also use this option to share the invite on WhatsApp. Alternatively, you can download the invite and share it on your own.`}/> 
        <Accordions panel={`panel2`} Summary={`Can I send my e-invite to as many people as I want to after purchasing it?`} 
            Details={`Yes, you can share your invite with as many people as you like once you have purchased it.`}/> 

      </TabPanel>
      <TabPanel value={value} index={4}>
      <TabHeading heading={`Order Mistake/ Cancellation and refund`} />
        <Accordions panel={`panel1`} Summary={`I want to cancel my order, what should I do?`} 
            Details={`Please phone us immediately on +91 88-587-83-587 or chat with us on our site and we’ll try to cancel your order before it is sent to our production team.
            Please phone us rather than sending an email, as your order may have already been produced before we get round to your email.
            Unfortunately, once the videos/ E-Card are created we can’t cancel it.`}/> 
        <Accordions panel={`panel2`} Summary={`Oh no! I've made a mistake with my order, can I change it?`} 
            Details={`Absolutely Yes!
            Mistakes happen and we’ll try our best to amend your order.
            Please mail us or whatsapp us the changes as soon as possible.`}/> 

      </TabPanel>
      <TabPanel value={value} index={5}>
      <TabHeading heading={`Watermark`} />  
        <Accordions panel={`panel1`} Summary={`I do not want the Logo & watermark, is that removed?`} 
            Details={`Yes. Logo & Watermark for PDF, ECard & GIF is removed.
            The logo & Watermark on the video is removed. However, Please note after the video ends, 
            the Logo in the last black frame stays (to avoid false copy claims about our work). To remove it kindly add Rs. 500/- to the above quote. `}/> 

      </TabPanel>
      {/* <TabPanel value={value} index={6}>
      <TabHeading heading={`6`} />  
        <Accordions panel={`panel1`} Summary={`Summary`} 
            Details={`Details`}/> 

      </TabPanel> */}
    </Box> 
    </Box>
  </Box>
    
  </>
  );
}

