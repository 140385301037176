/* eslint-disable array-callback-return */
// import React from 'react';

const ProReducer = (state, action) => {
    // if (action.type === "SET_LOADING") {
    //      return {...state,isLoading:true,};
    // }

    switch (action.type) {
        case "SET_LOADING":
            return ({...state,isLoading:true,});
        case "MY_API_DATA":
        // console.log(action.payload.item);
        const non = [];
        const features = [];
        const feature = [];
        feature.id = 1;
        feature.title = "feature";
        const latest=[];
        latest.id = 2;
        latest.title = "latest";
        const bestseller=[];
        bestseller.id = 3;
        bestseller.title = "bestseller";
        const deals=[];
        deals.id = 4;
        deals.title = "deals";
        const special=[];
        special.id = 5;
        special.title = "special";

        
        // pro.push("ok");
        const eachPro = [];
        const proTeg = [];
        const eachFeatures = [];
        const eachSpecial = [];
        const eachLatest = [];
        const category = [];
        const subCategory = [];
        // const filtered = 
        action.payload.item.map((cate,index) => {
                category.push(cate);
              const Pro = []; 
                const fPro = []; 
                cate.category.map((sub,inSub) => {
                    subCategory.push(sub);
                    sub.products.map((pro,i) => {
                        eachPro.push(pro);
                        const eachTeg =pro.pro_tag;
                        const eachProTeg =eachTeg.split(",").filter(value=>value).map(value=>value.trim());
                        proTeg.push(...eachProTeg);
                        // eachLatest.push(pro);
                        if(pro.Features==='Features'){eachFeatures.push(pro);}    
                        else if(pro.Features==='Special'){eachSpecial.push(pro);} 
                        else{fPro.push(pro);}
                        // if(index<4 || inSub<4 || i<1){ fPro.push(pro);}   
                        if(i<3){ eachLatest.push(pro);}    
                    })
                })
                Pro.title = cate.title;
                Pro.id = cate.id;
                Pro.pro = fPro;
                non.push(Pro);
                
            });
            // function compareNumbers(a, b) {
            //     console.log(a," -",b);
            //     return a.pro_id - b.pro_id;
            //   }
              
            feature.pro =eachFeatures;
            latest.pro =eachLatest;
            bestseller.pro =eachPro;
            deals.pro =action.payload.deals;
            special.pro =eachSpecial;
            features.push(feature);
            // latest.sort(compareNumbers());
            latest.pro.sort((b, a) => a.pro_id - b.pro_id); 
            features.push(latest);
            features.push(bestseller);
            features.push(deals);
            features.push(special);
        // action.payload.item
        const uniqueTeg = [...new Map(proTeg.map(item =>[item.toLowerCase(), item])).values()]
        // console.log(proTeg);
        // console.log(uniqueTeg);


        return ({...state,products:action.payload.item,banners:action.payload.banner,
            reviews:action.payload.reviews,blogs:action.payload.blogs,proTeg:uniqueTeg,
            pro:eachPro,category:category,subcategory:subCategory,
            featureProducts:features ,isLoading:false,});
        case "API_ERROR":
        return ({...state,isLoading:true,isError: true,});
        default:
            return (state);
    };
    // return (state);

    // return ({...state,isLoading:true,products:action.payload.item,});
   
}

export default ProReducer;
