import React,{useEffect,useState} from 'react'
import Banner from "./homes/banner";
// import BlogsCard from '../comp/blogsCard';
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { UseProContext } from "../context/productContext";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// import Helmet from "react-helmet";
import { Link} from "react-router-dom";

const StyledBlogs = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root": { fontFamily: "Work Sans" },
    "& .card":{padding: '1rem 10px',},
    "& .MuiCardHeader-title":{fontWeight: 600,fontSize:'1rem'},
  // "& label":{width:'25%'},
  //   "& input,textarea":{width:'75%'},
}));

export default function Blogs() {
  const values = UseProContext();
  // console.log(values.blogs){img:'',title:'',date:'',main:'',dis:'',tag:''}
  const [state, setState] = useState(null);
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log(state);
  useEffect(() => {
    document.title = `Blogs || India's Best Digital Invitation E Card Maker - Animated Invitation Maker - Digi Invite`;  
  }, []);
  const style = {position:'relative' ,top:'50%',left:'50%',transform:'translate(-50%, -50%)',
  width: {xs:'80%',sm:'75%',md:'60%'},overflowY:'scroll',height: '100vh',py: '25px'};
  const style2 = { width: '100%',bgcolor: 'white',border: '2px solid #fff',boxShadow: 24,p: {xs:1,sm:2,md:4},borderRadius: '6px',};
  const styleClose={position:'absolute',right:0,margin: '10px',zIndex: 2,fontWeight: 900,fontSize: 'x-large',
  // background: '#ffad19',
  color: '#fff',padding:'0 8px',borderRadius: '6px',cursor: 'pointer', }
  return (
    <StyledBlogs>
    <Banner name={`Blogs`}/>
      {/*<Box className='container'>
        <Box sx={{mt:5,mb:5,pt:5,pb:5}}> 
          <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'space-evenly'}}>
            {values.blogs&&values.blogs.map((blog,i)=>
            <Box className='card' key={i}> <BlogsCard {...blog} /></Box>)}
          </Box> 
        </Box>
      </Box>*/} 
      <Box sx={{mt:5,mb:5,pt:5,pb:5}} className='blog-section container'>
      {values.blogs&&values.blogs.map((blog,i)=><Box className='' key={i}> <Blog {...blog} setState={setState} setOpen={setOpen}/></Box>)}
      </Box>
      <Modal  aria-labelledby="modal-title"  aria-describedby="modal-description"
        open={open}  onClose={handleClose}  closeAfterTransition
        BackdropComponent={Backdrop}  BackdropProps={{ timeout: 500,}}>
        <Fade in={open}>
          <Box className="scrollModal blog-description " sx={style}>
            <div style={styleClose}  onClick={()=>handleClose()}  >×</div>
            <Box sx={style2} >
              <BlogDetail {...state} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </StyledBlogs>
  )
}


const Blog = (props) => {
  const {author,img,title,date,main,tag,setState,setOpen}=props;
  const tags = tag.split(",").filter(value=>value).map(value=>value.trim());
  // console.log(tags);
  const imgUrl=`${img?img:`https://www.digiinvite.in/ceo/images/blog/blogs.png`}`;
  const imgStyle={width:'100%',borderRadius: '8px',   background: `url(${imgUrl}) center top / cover no-repeat`,};
  function handelSetState() {
    setState(props);
    setOpen(true);
  }
  return (
    <>
      <div className="article row">
          <Box sx={{width:{xs:'100%',md:'50%'}}} >
            <div className="article-img">
              <Box sx={{...imgStyle,height:{xs:'170px',sm:'382px',md:'286px'},}}  ></Box>
            </div>
          </Box>
          <Box sx={{width:{xs:'100%',md:'50%'},px:{xs:1,md:3},pt:{xs:2,md:0}}} className="blog-description ">
            <div className="blogs-sub-title">
              <span className="blog-date"><i className="fa fa-calendar"></i>{date} </span> 
            <span className="author">      
              <i className="fa fa-user"></i>
              <span>{author?author:'DigiInvite'} </span>
            </span>                
          </div>
              <div className="home-blog-content blog-detail">				
                <h4 onClick={()=>handelSetState()}>{title}</h4>
                <p>{main}</p>
                <div className="blog-tag">
                  {tags&&tags.map(tag=><Link to={`/search/${tag}`} className="blog-tags" key={tag}>{tag}</Link>)}
                </div> 
                  <button className="btn" onClick={()=>handelSetState()}>Read more</button>
              </div>
            </Box>
      </div>
       
    </>
  );
}
 
const BlogDetail = (props) => {
  const {author,img,title,date,main,dis,dis2,dis3,dis4,dis5,tag}=props;
  const tags = tag.split(",").filter(value=>value).map(value=>value.trim());
  // console.log(tags);
  const imgUrl=`${img?img:`https://www.digiinvite.in/ceo/images/blog/blogs.png`}`;
  const imgStyle={position:'relative' ,width:'100%',borderRadius: '8px', background: `url(${imgUrl}) center top / cover no-repeat`,color:'#fff'};
  const dateStyle={position: 'absolute',top: '8px',left: '8px',backgroundColor: '#ffad19',borderRadius: '6px',padding: '8px 8px',};
  // function handelSetState() {
  //   setOpen(false);
  // }
  return (
    <>
      <div className="fullblog sinblogt row">
          <Box sx={{width:{xs:'100%',md:'100%'}}} >
            <div className="article-img">
              {/* <div className='close' onClick={()=>handelSetState()} >×</div> */}
              <Box sx={{...imgStyle,height:{xs:'40vw',sm:'36vw',md:'28vw'},}}  >
              <div className="blogs-sub-title" style={dateStyle}>
                <span className="blog-date" >{date} </span>
              </div>
              </Box>
            </div>
          </Box>
          <Box sx={{width:{xs:'100%',md:'100%'},pt:3}} className="blog-description ">
            <div className="blogs-sub-title">
              {/* <span className="blog-date"><i className="fa fa-calendar "></i>{date} </span>  */}
              <span className="author"><i className="fa fa-user"></i><span>By {author?author:'DigiInvite'}</span></span>                
            </div>
            <div className="home-blog-content blog-detail">				
                <h4> {title}</h4>
                <p>{main}</p>
                <p>{dis}</p>
                <p>{dis2}</p>
                <p>{dis3}</p>
                <p>{dis4}</p>
                <p>{dis5}</p>
                <div className="blog-tag">
                  <label>Tags:</label>
                  {tags&&tags.map(tag=><Link to={`/search/${tag}`} className="blog-tags" key={tag}>{tag}</Link>)}
                </div>  
                {/* .singlepro .social-sharing */}
                  <div className="social-sharing normal" data-permalink="http://digiinvite.in/blogs">
                    <label>Share this on:  </label>
                    <a target="_blank" className="share-facebook" rel="noreferrer"
                        href="//www.facebook.com/sharer.php?u=http://digiinvite.in/blogs">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a target="_blank" rel="noreferrer"  className="share-twitter"
                        href={`//twitter.com/share?text=${title}&amp;url=http://digiinvite.in/blogs`}>
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a target="_blank" className="share-whatsapp" rel="noreferrer"
                        href={`//api.whatsapp.com/send?text=${title} http://digiinvite.in/blogs`}> 
                        <i className="fa fa-whatsapp" style={{fontSize:'18px'}} ></i>
                    </a>

                    {/* <a target="_blank" rel="noreferrer"  className="share-pinterest"
                        href={`//pinterest.com/pin/create/button/?url=http://digiinvite.in/blogs&amp;media=${imgUrl}&amp;description=${title}`}>
                      <i className="fa fa-pinterest"></i>
                    </a> */}
                    {/* <a target="_blank" rel="noreferrer"  className="share-google"
                        href="//plus.google.com/share?url=http://digiinvite.in/blogs">
                      <i className="fa fa-google-plus"></i>
                    </a> */}
                </div>
              </div>
            </Box>
      </div>
      
    </>
  );
}


 

