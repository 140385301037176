import { Box } from "@mui/material";
import React from "react";
import { UseProContext } from "../context/productContext";
// import Popup from '../page/popup';
import { Link} from "react-router-dom";
import digiLogo from '../img/digiLogo-light.png';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const values = UseProContext();
  const category = values.subcategory;
  // console.log(values);
    // console.log(category);
  // const plugins=`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpage.ismartmedia&amp;tabs=timeline&amp;width=300&amp;height=270&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId`;
  // const plugins=`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpage.ismartmedia&tabs=timeline&width=300&height=270&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`;
  function handelCategory(cate) {
  //  const [titles,cate_link,sub_tag ]=[cate];
    return(
        <p key={cate.id}>
          <Link to={`/products/${cate.cate_link}/${cate.sub_tag}`} title="All Products">
            {cate.titles}
          </Link>
        </p>
    )
    
  }

  // var l = e(".back-to-top");
  // if (l.length) {
  //   var r = function () {
  //     e(window).scrollTop() > 400 ? l.addClass("show") : l.removeClass("show");
  //   };
  //   r(),
  //     e(window).on("scroll", function () {
  //       r();
  //     }),
  //     l.on("click", function (a) {
  //       a.preventDefault(), e("html,body").animate({ scrollTop: 0 }, 700);
  //     });
  // }

  return (
    <div>
      {/* {values.isLoading?<></>:<Popup />} */}
      
      <footer className="site-footer">
        <div className="">
          <div className="row fspc" style={{color:'white'}}>
            <Box className="fborder" sx={{width:{xs:'100%',sm:'50%',md:'35%'},px:{xs:3,md:5},}}>
              <Box sx={{px:5}}>
                <Link to='' >
                  <img src={digiLogo} alt="" />
                </Link> 
              </Box>
              <Typography   sx={{fontSize:'0.9rem',color:'rgb(221, 221, 221)',mt:2,textAlign: 'justify',fontWeight:400, }}>
                DigiInvite is a digital invitation card maker from India. which was started by Managing Director Diljit Singh Dhillon. 
                This company is a part of iSmart Media (a digital marketing company) which was started in 2012, and after the success 
                of iSmart Media, the DigiInvite Company was launched
                 which helps all kinds of invitations reach you and your relatives.
              <Link to='/about' style={{color:'white',fontWeight:600,}} > Read More</Link>
              </Typography>
            </Box>
            
            <Box className="fborder" sx={{width:{xs:'100%',sm:'50%',md:'20%'},p:{xs:3,md:5}}}>
              <h4 style={{color:"#ffffff"}}>
                Searchable Links
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#fservice"
                ></button>
              </h4>

              <ul id="fservice"  className="site-footer__links collapse footer-collapse"> 
                <li>
                  <Link  to="/products/Wedding/Punjabi-Wedding-Invitations" style={{color:"#dddddd"}}>
                  Punjabi Wedding 
                  </Link>
                </li>

                <li>
                  <Link to="/products/Wedding/Hindu-wedding-Invitation" style={{color:"#dddddd"}}>
                  Hindu wedding 
                  </Link>
                </li>


                <li>
                  <Link  to="/products/Wedding/Royal-Wedding" style={{color:"#dddddd"}}>
                  Royal Wedding
                  </Link>
                </li>
                <li>
                  <Link  to="/products/Wedding/Rajasthani-Wedding-Invitations" style={{color:"#dddddd"}}>
                  Rajasthani Wedding
                  </Link>
                </li> 
                <li>
                  <Link to="/products/Party-Invites/Birthday-Party" style={{color:"#dddddd"}} >
                  Birthday Party
                  </Link>
                </li>
              </ul>
            </Box>

            <Box className="fborder" sx={{width:{xs:'100%',sm:'50%',md:'20%'},p:{xs:3,md:5}}}> 
              <h4 style={{color:"#ffffff"}}>
                Pages
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#fservice"
                ></button>
              </h4>
              <ul
                id="fservice"
                className="site-footer__links collapse footer-collapse"
              >

                <li>
                  <Link  to="/faq" style={{color:"#dddddd"}}>
                    FAQ
                  </Link>
                </li>

                <li>
                  <Link to="/about" style={{color:"#dddddd"}}>
                    About Us
                  </Link>
                </li>

                <li>
                  <Link to="/Privacy-Policy" style={{color:"#dddddd"}} >
                    Privacy Policy
                  </Link>
                </li>

                <li>
                  <Link  to="/Terms-Conditions" style={{color:"#dddddd"}}>
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </Box>
            <Box className="fborder footcont" sx={{width:{xs:'100%',sm:'50%',md:'25%'},p:{xs:3,md:5}}} >
              <h4 style={{color:"#ffffff"}}>
                Contact us
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#fcontact"
                ></button>
              </h4>

              <div id="fcontact" className="collapse footer-collapse">
                <div className="address" style={{color:""}}>
                  <p>
                    <a style={{color:"#dddddd"}} href="https://goo.gl/maps/uDajWHd8wiMEdUZX9" target={`_blank`}>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon"
                        viewBox="0 0 410.344 410.344"
                        style={{width:"22px",height:"22px",}} 
                      >
                        <path
                          d="M205.212,0C119.37,0.088,49.816,69.678,49.772,155.52c0,83.52,143.36,245.36,149.44,252.16
          c2.947,3.292,8.005,3.571,11.297,0.624c0.219-0.196,0.427-0.404,0.624-0.624c6.08-6.88,149.44-168.64,149.44-252.16
          C360.528,69.709,291.022,0.132,205.212,0z M205.212,390.16c-27.36-32-139.44-166.32-139.44-234.72
          C65.772,78.429,128.201,16,205.212,16s139.44,62.429,139.44,139.44C344.652,223.84,232.572,358.32,205.212,390.16z"
                        ></path>
                        <path
                          d="M208.012,78c-46.524,0.044-84.204,37.796-84.16,84.32c0.044,46.524,37.796,84.204,84.32,84.16
          c46.493-0.044,84.16-37.747,84.16-84.24C292.244,115.721,254.531,78.044,208.012,78z M208.012,230.56
          c-37.688,0-68.24-30.552-68.24-68.24s30.552-68.24,68.24-68.24s68.24,30.552,68.24,68.24
          C276.208,199.99,245.681,230.516,208.012,230.56z"
                        ></path>
                      </svg> 
                      Bathinda, Punjab 151001 India
                    </a>
                  </p>
                  <p>
                    <a  href="tel:8858783587" target={`_blank`}style={{color:"#dddddd",fontWeight:600,fontSize:'1rem'}}>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon"
                        viewBox="0 0 473.806 473.806"
                        style={{width:"21px",height:"20px",}}
                      >
                        <path
                          d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
          c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
          c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
          c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
          c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
          c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
          c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
          C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
          c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
          c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
          c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
          c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
          c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
          c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
          l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
          C420.456,377.706,420.456,388.206,410.256,398.806z"
                        ></path>
                        <path
                          d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
          c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
          S248.656,111.506,256.056,112.706z"
                        ></path>
                        <path
                          d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
          c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
          c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
                        ></path>
                      </svg>
                      88-587-83-587
                    </a>
                  </p>
                  <p>
                    <a style={{color:"#dddddd"}} href="mailto:info@digiinvite.in" target={`_blank`}>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon"
                        viewBox="0 0 512 512"
                        style={{width:"22px",height:"21px",}}
                      >
                        <path d="m494.2,488c0,0 0-225.8 0-301 0-3.1-3.9-7-7.7-9.9l-78.7-57.1v-63.1c0-6.2-5.2-10.4-10.4-10.4h-89.7l-45.7-33.3c-3.1-2.1-8.3-2.1-11.5,0l-45.7,33.3h-89.7c-6.2,0-10.4,5.2-10.4,10.4v62.4l-79.7,57.9c-4.7,2.9-7.7,6.7-7.7,9.9 0,75.7 0,303 0,303 0,5.9 4.7,10 9.6,10.4 0.3,0 0.5,0 0.8,0h456c6.7-0.1 10.5-5.3 10.5-12.5zm-19.8-282.3v263.6l-172.1-137.8 172.1-125.8zm-7.7-18.3l-58.9,42.9v-86.2l58.9,43.3zm-210.9-154.5l18.3,13.5h-36.7l18.4-13.5zm131.2,34.4v178.2l-131.2,95.6-131.2-95.6v-178.2h262.4zm-349.8,138.4l172.1,125.8-172.1,138.6v-264.4zm67.6,25.4l-60.4-44 60.4-43.9v87.9zm-48.9,249.5l170.1-136.9 23.5,17.2c4.5,3.4 7.9,3.4 12.5,0l23.5-17.2 171.1,136.9h-400.7z"></path>
                        <rect
                          width="140.5"
                          x="186.1"
                          y="118.3"
                          height="19.8"
                        ></rect>
                        <rect
                          width="140.5"
                          x="186.1"
                          y="181.8"
                          height="19.8"
                        ></rect>
                        <rect
                          width="140.5"
                          x="186.1"
                          y="245.3"
                          height="19.8"
                        ></rect>
                      </svg>
                      info@digiinvite.in
                    </a>
                  </p>
                </div>

              </div>
            </Box>


                {/* <div>
                    <h4 style={{color:"#ffffff"}}>Payment</h4>
                  <Box component="ul" sx={{display:"flex"}} className=" foot-payment ">
                    <li>
                      <a href="/#">
                        <i className="fa fa-cc-mastercard"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fa fa-cc-visa"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fa fa-credit-card"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fa fa-cc-paypal"></i>
                      </a>
                    </li>
                  </Box>
                </div> */}
              {/* <img src="https://www.digiinvite.in/ceo/assets/images/footer2.png" alt="" width={'100%'} /> */}
              {/* <iframe   src={plugins} loading={"lazy"} style={{border:"none",overflow:"hidden",}} width={"100%"} height={"270px"} frameBorder={0} scrolling={"no"}/> */}
                {/* <li>
                  <Link to="/search" style={{color:"#dddddd"}} >
                    Search
                  </Link>
                </li> */}
            {/* <div className=" fborder">
              <h4 style={{color:"#ffffff"}}>
                Category
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#fproduct"
                ></button>
              </h4>

              <ul
                id="fproduct"
                className="site-footer__links collapse footer-collapse"
              >
                <li>
                  <a style={{color:"#dddddd"}} href="/collections/sofa">
                    Furniture
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/collections/headphone">
                    Electronics
                  </a>
                </li>

                <li>
                  <a
                    style={{color:"#dddddd"}}
                    href="/products/nullam-ultricies-purus-quis-odio-finibus-ultrices"
                  >
                    Moniters
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/collections">
                    Lighting
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/collections/all">
                    Controllers
                  </a>
                </li>
              </ul>
            </div> */}



            {/* <Box className="fborder" sx={{width:{xs:'100%',sm:'50%',md:'25%'},p:{xs:3,md:5}}}>
              <h4 style={{color:"#ffffff"}}>
                Pages
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#fservice"
                ></button>
              </h4>
            </Box> */}
              {/* <img src="https://www.digiinvite.in/ceo/assets/images/footer1.png" alt="" width={'100%'} /> */}
              {/* <iframe  src={plugins}   loading={"lazy"}   style={{border:"none",overflow:"hidden",}} width={"100%"}  height={"270px"}   frameBorder={0}   scrolling={"no"}/> */}
            {/* <div className="fborder">
              <h4 style={{color:"#ffffff"}}>
                Extras
                <button
                  type="button"
                  className="toggle collapsed"
                  data-toggle="collapse"
                  data-target="#faccount"
                ></button>
              </h4>

              <ul
                id="faccount"
                className="site-footer__links collapse footer-collapse"
              >
                <li>
                  <a style={{color:"#dddddd"}} href="/pages/wishlist">
                    Wishlist
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/pages/contact-us">
                    Contact Us
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/collections">
                    All Collections
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/collections/all">
                    all Products
                  </a>
                </li>

                <li>
                  <a style={{color:"#dddddd"}} href="/blogs/news">
                    Blog
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>


        <div className="text-center ">
          <div className="footblink">
            <div className="">
              <div className="row" style={{justifyContent:'center'}}>
                {category.map(cate=>handelCategory(cate))}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-menu text-center">
          <div className="container">
            <div className="row">
              <div className="copyr">
                <p style={{color:"#dddddd"}} className="copyright-type__5">
                  Copyright © 2024-2025, DigiInvite
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer> 
    </div>
  );
};

export default Footer;
