import React, { useEffect,useState } from 'react';
import { UseCartContext } from '../context/CartContext';
import { UseProContext } from '../context/productContext';
import { Link } from "react-router-dom";
import FormatPrice from "./FormatPrice";
// import CartProductList from './CartProductList';useContext,
// import CartCheckoutRow from './CartCheckoutRow';
// import CartTotalRow from './CartTotalRow';
// import CartContext from '../../context/CartContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { styled} from '@mui/material/styles';

const CartStyle = styled(Box)(({ theme }) => ({
  backgroundColor: 'white', 
    "& .MuiTypography-root":{ fontFamily: 'Work Sans',
    // '&:hover':{color:`#ffad19`}MuiButton-text
    '&:hover':{color:`#ffad19`, }
  },
    "& .MuiButton-text":{ fontFamily: 'Work Sans',color:`#fff`,backgroundColor: '#ffad19',
    '&:hover':{color:`#ffad19`,backgroundColor: '#fff',}
  },  
    // "& .MuiButton-root":backgroundColor: '#000'{ fontFamily: 'Work Sans', backgroundColor: '#ffad19', }, 
}));

const Wishlist = ({closeCart}) => {

  const values=UseProContext();
  const product =values.products;

  const {carts,setCarts}=UseCartContext();
  // const cartCount =carts.count;
  const cartPros =carts.wishlist;
  // console.log(carts);
  const handleCloseWishS =()=>{
    closeCart(null);
  }
  const handleCloseWish=()=>{
    // const AddProId=[];
    // const productId =localStorage.getItem('pro_id');
    // AddProId.push(productId);
    // AddProId.push(wishId);
    // localStorage.setItem('pro_id', AddProId);
    // setCarts({type:"ADD_CART_DATA",payload:localStorage.pro_id,pro:product});
    const options = [];
    const wishId =localStorage.getItem('wish_id');
    const AddProId = wishId.split(",");
    AddProId.forEach(element => {
      if(element>0){
        let option={"id": element,"typ": "","logo": 0,"Language": 0,"Version": 0};
        options.push(option);
      }
    }); 
    if (localStorage.getItem("pro_form") ===null || localStorage.getItem("pro_form") ==='' ) {
      const option=JSON.stringify([options]);
      localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:options});
    }else{
      const savePro =localStorage.getItem('pro_form');
      const Pro=JSON.parse(savePro);
      const cards=[...options,...Pro];
        const unique = [...new Map(cards.map(item =>[item['id'], item])).values()]
        // console.log(unique);
        const option=JSON.stringify(unique);
        localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:unique});
    }
    localStorage.setItem('wish_id', '');
    setCarts({type:"MY_WISHLIST_DATA",payload:localStorage.wish_id,pro:product});
    closeCart(null);
  }


  const [cartPro, setCartPro] = useState(cartPros);
  useEffect(() => {
   setCartPro(cartPros);
  }, [cartPros]);
  
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setTotalPrice(null);
    // eslint-disable-next-line array-callback-return
    Object.keys(cartPro).map((obj) => {
      // cartPro[obj].count*
      const totals=cartPro[obj].price;
      setTotalPrice(oldArray=>oldArray+totals);
    });
  }, [cartPro]);
 
  function deleteProduct(id) {
      localStorage.setItem('wish_id', '');
      cartPro.forEach((pro) => {
        if(pro.pro_id!==id){
          for (let i = 0; i < pro.count; i++) {
            let pro_ids = localStorage.getItem('wish_id');
            localStorage.setItem('wish_id', pro.pro_id+','+pro_ids);
          }
        }
      });
      let newCart = localStorage.getItem('wish_id');
      if(!newCart){newCart=','}
      setCarts({type:"MY_WISHLIST_DATA",payload:newCart,pro:product});
      // const upResults = cartPro.filter(obj => obj.pro_id!==id);
      // setCartPro(upResults);
  }
 

    return (
      <CartStyle >
      {cartPro.length  > 0 ?
      <Box sx={{p:0,}} id="slidedown-cart" > 
        <Box component={'ul'}>

        {Object.keys(cartPro).map((obj, i) =>
          <Box sx={{m:0,display:'flex'}} key={i} component={'li'}>
            <Box sx={{p:1,}}>
            <Link to={`/product/${cartPro[obj].code}`}>
            <figure> 
              <img width='60px' src={cartPro[obj].img[0]&&cartPro[obj].img[0]?`${cartPro[obj].img[0].url}`
              :`https://www.digiinvite.in/ceo/assets/images/pro.png`} alt={cartPro[obj].pro_title} />
            </figure>
            </Link>
            </Box>
            <Box sx={{p:1,  }} className="product-details">
            <Link to={`/product/${cartPro[obj].code}`}>
              <Typography sx={{ fontWeight: 600,fontSize:'0.8rem',  }} className="product-name" >
                {cartPro[obj].pro_title}
              </Typography>
            </Link>
              <Box sx={{ fontSize:'0.8rem',display:'flex', flexWrap:'wrap' }} >
                
                 <span style={{width:'75%',}}> 
                  {cartPro[obj].code} <br/>
                  <FormatPrice price={cartPro[obj].price} />
                 </span>  
                 <IconButton onClick={()=>deleteProduct(cartPro[obj].pro_id)} sx={{width:'25%'}}><DeleteIcon /></IconButton>
              </Box> 
            </Box> 
          </Box>
          )}
          </Box>
        
        <Box sx={{p:"0 16px",display:'flex',justifyContent:'space-between'}}>
          <Box sx={{fontWeight: 600,fontSize:'1rem',  }} >
          Cart total:  
          </Box>
          <Box sx={{fontWeight: 600,fontSize:'1rem',  }} >
          <FormatPrice price={totalPrice}  />
          </Box>
          {/* <Typography sx={{fontWeight: 700,fontSize:'1.5rem',  }} >
            Total price With 18% Gst = {gstPrice}
          </Typography> */}
        </Box>
        <Box sx={{p:"0 16px",display:'flex',justifyContent:'space-evenly'}} className="actions">
          <Link to='checkout' preventScrollReset={true}><Button onClick={handleCloseWish} className="btn">Checkout</Button></Link>
            
          {/* <Link to='carts' preventScrollReset={false}></Link> */}
            <Button onClick={handleCloseWish} className="btn">Add to Cart</Button>
        </Box>
      </Box>
      :<Box sx={{p:1}}className="no-items">
        <Typography sx={{ fontSize:'0.8rem',  }} className="text-continue">
        Your Wishlist is currently empty! <IconButton onClick={handleCloseWishS} sx={{textAlign:'end'}}><CloseIcon /></IconButton>
        </Typography>
      </Box> }
      </CartStyle>
    );
  
}

export default Wishlist;
