import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import {product} from '../comp/data';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import Helmet from "react-helmet";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// import { Link } from "react-router-dom";
// import PropTypes from 'prop-types';, { SliderThumb }
import Slider from '@mui/material/Slider';
// import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import Fab from '@mui/material/Fab';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import IconButton from '@mui/material/IconButton';

import Drawer from '@mui/material/Drawer';
import Products from '../comp/Products';

// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import StoreIcon from '@mui/icons-material/Store';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
//alpha, import SimpleImageSlider from "react-simple-image-slider";

import { UseProContext } from '../context/productContext';
// import { UseCartContext } from '../context/CartContext';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

function valuetext(priceValue) {
  return `${priceValue}°C`;
}
const ThemeProviders = styled(Box)(({ theme }) => ({
  // "& .MuiTypography-root":{fontFamily: 'Work Sans', },
  "& input": { border: 0, "&:focus": { border: 0, }, },
}));

export default function FilterCate() {
  const values = UseProContext();
  const product = values.products;
  // console.log(values);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    fontFamily: 'Work Sans',
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  // const {setCarts}=UseCartContext();
  // carts,
  // function handleAddProduct(productId,proCode) {
  //   const AddProId=[];
  //   AddProId.push(productId);
  //   const saveProId =localStorage.getItem('pro_id');
  //   AddProId.push(saveProId);
  //   localStorage.setItem('pro_id', AddProId);
  //   setCarts({type:"MY_CART_DATA",payload:localStorage.pro_id,pro:product});
  // }

  return (
    <ThemeProviders>
      <ThemeProvider theme={theme}>
        {/* <Helmet>
          <title> India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
          <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
        Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
        create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
      </Helmet> */}
        {/* <Typography variant="h3">Responsive h3</Typography> */}
        <SubCategory product={product} category={values.category} subcategory={values.subcategory} pro={values.pro} />
      </ThemeProvider>
    </ThemeProviders>
  )
}


function SubCategory({ product, pro, category, subcategory }) {


  // console.log(category);

  let { cate, sub_cate } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cateOption = [];
  category.map(cate => cate.cate_tag ? cateOption.push(cate.cate_tag) : '');

  // category.forEach(cate => {
  //   const newCate=[{id:cate.id,title:cate.title,}]
  //   cateOption.push(...newCate);
  // });


  // console.log(cateOption);

  // useEffect(() => {
  //   cateOption.map(cate=>cate.category.remove());
  // }, [cateOption]);

  const [options, setOptions] = React.useState([...subcategory]);

  useEffect(() => {
    const options_in = [];
    product.map((page) =>
      cate === page.cate_tag ? page.category && page.category.map((cat) =>
        cat.sub_tag ? options_in.push(cat.sub_tag) : console.log('no options')
      ) : <></>
    );;
    setOptions(options_in);
  }, [cate, product])

  const [priceValue, setPriceValue] = useState([2, 100]);
  const [categories, setCategories] = React.useState(cate);
  const [subCategories, setSubCategories] = React.useState(sub_cate);
  const [types, setType] = useState(null);
  // console.log(types)
  // const [proClass, setProClass] = React.useState('product product-grid');
  const [anchorelNav, setAnchorelNav] = React.useState(null);
  useEffect(() => {
    setCategories(cate);
    setSubCategories(sub_cate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cate])

  useEffect(() => {
    setSubCategories(sub_cate);
  }, [sub_cate])

  useEffect(() => {
    const options_al = [];
    product.map((page) =>
      categories === page.cate_tag ? page.category && page.category.map((cat) =>
        cat.sub_tag ? options_al.push(cat.sub_tag) : console.log('no options')
      ) : <></>
    );
    setOptions(options_al);
  }, [categories, product]);

  // console.log(product)

  // const [state, setState] = useState([0,4]);


  return (
    <>
      <Box sx={{
        width: '100%', bgcolor: 'background.paper', p: '30px 0 0 0',
        display: 'flex', justifyContent: 'center',
      }}>
        <Typography sx={{ fontWeight: 400, fontSize: '0.9rem', }} >
          Home / {subCategories ? <>{categories} / {subCategories}</> : <>{categories}</>}
        </Typography>

      </Box>
      <Box sx={{ display: { xs: 'block', md: 'flex' }, bgcolor: 'background.paper', p: 4, }}>
        <Drawer anchorel={anchorelNav} keepMounted open={Boolean(anchorelNav)}
          onClose={() => setAnchorelNav(null)} sx={{ display: { xs: 'block', md: 'none' }, }}>
          <Box sx={{ p: 3, display: { xs: 'block', md: 'none', sm: 'block', }, justifyContent: 'center', }}>
            <div>
              <Box sx={{ textAlign: 'end' }}><Button onClick={() => setAnchorelNav(null)}>close</Button></Box>
              <br />
              <Filter setAnchorelNav={setAnchorelNav} setCategories={setCategories} categories={categories} setSubCategories={setSubCategories}
                subCategories={subCategories} cateOption={cateOption} options={options} setType={setType} setPriceValue={setPriceValue} priceValue={priceValue} />
            </div>
          </Box>
        </Drawer>
        <Box sx={{ width: { xs: '100%', md: '30%' }, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', }}>
          <Filter setAnchorelNav={setAnchorelNav} setCategories={setCategories} categories={categories} setSubCategories={setSubCategories}
            subCategories={subCategories} cateOption={cateOption} options={options} setType={setType} setPriceValue={setPriceValue} priceValue={priceValue} />
        </Box>

        <Box sx={{ width: { xs: '100%', md: '70%' }, display: 'flex', justifyContent: 'center', }}>
          <Box sx={{ width: "100%", p: { xs: 0, md: 5 } }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.2rem', m: '1px 20px', }} >{subCategories ? subCategories : categories}</Typography>
            <Box>
              <Button sx={{ display: { xs: 'inline-flex', md: 'none' }, m: 1 }}
                onClick={() => setAnchorelNav(1)}
                variant="outlined" color="secondary" >
                <ViewWeekIcon sx={{ fontSize: 30 }} color="action" />
              </Button>

            </Box>
            
            <Box className='row'>
              {product.map((page) => (
                <React.Fragment key={page.id}>
                  {categories === page.cate_tag && (
                    <>
                      {subCategories ? (
                        page.category.map((category) => (
                          <React.Fragment key={category.id}>
                            {subCategories === category.sub_tag && (
                              category.products.map((item) => (
                                <React.Fragment key={item.pro_id}>
                                  {item.price >= priceValue[0] * 60 && item.price <= priceValue[1] * 60 && (
                                    types ?
                                    (types === 'video' && item.video.length > 0) ||
                                    (types === 'pdf' && item.pdf.length > 0)
                                   ? (
                                    <Box key={page.id} sx={{ width: { xs: '50%', sm: '33.33%', md: '25%' }, p: 1 }}>
                                      <Products pro={item} />
                                    </Box>
                                  ) : <></>:
                                    <Box key={page.id} sx={{ width: { xs: '50%', sm: '33.33%', md: '25%' }, p: 1,}}>
                                      <Products pro={item} />
                                    </Box>
                                  )}
                                </React.Fragment>
                              ))
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        page.category.map((category) => (
                          <React.Fragment key={category.id}>
                            {category.products.map((item) => (
                              <React.Fragment key={item.pro_id}>
                                {item.price >= priceValue[0] * 60 && item.price <= priceValue[1] * 60 && (
                                  types ?
                                    (types === 'video' && item.video.length > 0) ||
                                    (types === 'pdf' && item.pdf.length > 0)
                                   ? (
                                    <Box key={page.id} sx={{ width: { xs: '50%', sm: '33.33%', md: '25%' }, p: 1 }}>
                                      <Products pro={item} />
                                    </Box>
                                  ) : <></>:
                                  <Box key={page.id}sx={{width: { xs: '50%', sm: '33.33%', md: '25%' },p: 1,}}>
                                    <Products pro={item} />
                                  </Box>
                                )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </Box>
                                {/* //   types? types==='video' && item.video.length>0?<Box key={page.id}sx={{width: { xs: '50%', sm: '33.33%', md: '25%' },p: 1,}}>
                                //   <Products pro={item} />
                                // </Box>:types==='pdf' && item.pdf.length>0? <Box key={page.id}sx={{width: { xs: '50%', sm: '33.33%', md: '25%' },p: 1,}}>
                                //     <Products pro={item} />
                                //   </Box>:<>NON</>: */}
            {/* <Box className='row'>
        {product.map((page) => (<React.Fragment key={page.id}>
        {categories===page.cate_tag ? <> 
        {subCategories ? <>
          {page.category && page.category.map((category) => ( <React.Fragment key={category.id}> 
            {subCategories && subCategories===category.sub_tag ? <>
              {category.products && category.products.map((item) => (<React.Fragment key={item.pro_id}>
              {item.price < priceValue[0]*60 || item.price > priceValue[1]*60 ? <></>:
              type? //type=='pdf'?   item.pdf
               <Box key={page.id} sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}>
                <Products pro={item} />
                </Box>: 
                <Box key={page.id} sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}>
                   <Products pro={item} />
                </Box>} 
              </React.Fragment>))}
            </>:<></>}
          </React.Fragment>))}
        </> :<>
           {page.category && page.category.map((category) => ( <React.Fragment key={category.id}> 
              {category.products && category.products.map((item) => (<React.Fragment key={item.pro_id}>
              {item.price < priceValue[0]*60 || item.price > priceValue[1]*60 ? <></>: 
                <Box key={page.id} sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}>
                   <Products pro={item} />
                </Box>} 
              </React.Fragment>))}
          </React.Fragment>))}
        </>
        }
        </>: <></>}
        </React.Fragment>))}

        </Box> */}
          </Box>
        </Box>

        {/* cate = {categories} <br/> select sub category */}
        {/* {pro.map((item,i) =>state[0]<=i && state[1]>i? 
            <Box key={pro.id} sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}>
                <Products pro={item} />
            </Box>:<></>
        )}  */}
      </Box>
    </>
  )
}

const Filter = ({ setAnchorelNav, setCategories, categories, setSubCategories, subCategories, cateOption, options, setType, setPriceValue, priceValue }) => {
  return (
    <div>

      <Autocomplete value={categories}
        options={cateOption}
        // getOptionLabel={(cateOption) => cateOption.title}
        onChange={(event, newValue) => {
          // console.log(newValue);
          setCategories(newValue); setSubCategories(''); // setOptions([]);
        }}
        id="controllable-states" sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Categories" />} />
      <br />
      <Autocomplete value={subCategories}
        onChange={(event, newValue) => { setSubCategories(newValue); setAnchorelNav(null) }}
        id="controllable-states2" options={options} sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Sub Categories" />} />
      <br />
      <Box width={300}>
        <FormLabel >
          <Typography sx={{ fontWeight: 600, fontSize: '1.1rem', }} >FILTER BY PRICE</Typography>
        </FormLabel>
        <Slider getAriaLabel={() => 'Temperature range'}
          value={priceValue} getAriaValueText={valuetext} // valueLabelDisplay="auto"  
          onChange={(event, newValue) => setPriceValue(newValue)} />
        <Typography sx={{ fontWeight: 500, fontSize: '1rem', }} >
          Price: ₹ {priceValue[0] * 60}.00 — ₹ {priceValue[1] * 60}.00
        </Typography><br />
        <FormGroup>
          <FormControl>
            <FormLabel >
              <Typography sx={{ fontWeight: 600, fontSize: '1.1rem', }} >FILTER BY TYPE</Typography>
            </FormLabel>
            <RadioGroup onChange={(e, newValue) => setType(newValue)} aria-labelledby="radio-buttons-group" defaultValue="female" name="radio-buttons-group">
              {/* <FormControlLabel value="eCard"  control={<Radio  />} label="eCard Invitation" /> */}
              <FormControlLabel value={""} control={<Radio />} label="All" />
              <FormControlLabel value="video" control={<Radio />} label="Video Invitation" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF Invitation Card" />
              {/* <FormControlLabel value="Gif"  control={<Radio />} label="Gif Invitation" /> */}
              {/* <FormControlLabel value="Website"  control={<Radio />} label="Website Invitation" /> */}
            </RadioGroup>
          </FormControl>
        </FormGroup>
        {/* <Button sx={{fontWeight: 600,fontSize:'1.2rem',m:2,  }} onClick={()=>setAnchorelNav(null)} >CLEAR</Button>
                      <Button sx={{fontWeight: 600,fontSize:'1.2rem',m:2,  }} >FILTER</Button> */}
      </Box>
    </div>
  );
}



