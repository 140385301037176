import React from 'react';
import Typography from "@mui/material/Typography"; 
import { UseProContext } from '../../context/productContext';
import { Box } from '@mui/system';
// import { Link} from "react-router-dom";

const Banner = ({name}) => {
    const values = UseProContext();
    const banner=values.banners.banner&&values.banners.banner[7]?values.banners.banner[7].url:``;
    return (
        <div className="container">
          <Box sx={{position:'relative',height: { xs: "150px", md:'300px'},display:'flex',justifyContent:'center',alignItems:'center'}}
            style={{backgroundImage:`url('${banner}')`,textAlign:'center',backgroundPosition: '50%',
            backgroundSize: 'cover',}}
          >
            <Typography  variant="h2" sx={{ fontWeight: 400, fontSize:{ xs: "2rem", md:'3rem'}, color: "black",}}>
            {name}
            </Typography> 
            <Box  sx={{position:'absolute',left:'50%',bottom:'-20px',transform:'translateX(-50%)'}}>
              {/* <nav aria-label="breadcrumb" className="breadcrumb-row"> */}
              {/* </nav> */}
                <Box sx={{background:'#ffad19',display:'flex',p:'1rem 2rem',borderRadius:'6px',width:{ xs: "175px", md:'auto'}}}>
                  <Typography  sx={{ fontWeight: 400, fontSize:{ xs: "0.8rem", md:'1rem'}, color: "white" }}>
                    Home  <i className={`fa fa-angle-right `}></i> {name}
                  </Typography>
                </Box>
            </Box>
          </Box>
        </div>
    );
}

export default Banner;
