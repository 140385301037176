import React from 'react';
import Box from '@mui/material/Box'; 
import Banner from "./homes/banner";
import Typography from '@mui/material/Typography';
// import Helmet from "react-helmet";

const Terms = () => {
    return (
    <>
    {/* <Helmet>
        <title> Terms & Conditions || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
        <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
      Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
      create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
    </Helmet> */}
    <Banner name={`Terms & Conditions`}/>
    <Box sx={{width:'100%',mt:5,mb:5,pt:2}} >
        <Box  className="container" >
            <Sub name={`Terms and Conditions`}>
                <p><i className='fa fa-check'></i> For ready templates, payment will be made in full in advance. No refund will be provided, as the design you see and approve is what you will be receiving.
                Only text and images will be changed in the provided samples.</p>
                <p><i className='fa fa-check'></i> The content and details provided by the customer will be final; content can’t be changed once you lock it.</p>
                <p><i className='fa fa-check'></i> Please provide the content carefully and cross-check for spelling mistakes as we will add the exact same content provided by you.</p>
                <p><i className='fa fa-check'></i>  If you want to change the font style, color, or background color, it will cost an additional ₹499 and you must notify us in advance.</p>
                <p><i className='fa fa-check'></i> Only the provided images will be added. You can’t change the pictures again and again. (Note that we do not offer a photo editing service. If you want, then the cost is ₹499/- per image edited.</p>
                <p><i className='fa fa-check'></i> No video frame will be moved, added, or changed from its original position. It is possible to charge an additional $499 per slide. </p>
                <p><i className='fa fa-check'></i> We do not provide additional changes once we deliver the HD version. After that, cost an e-card will cost 199/- and a video invite will cost 499/- per round of revision.</p>
            </Sub> 
            <Sub name={`If you don’t want to add images, Image slides can be removed.`}>
                <p><i className='fa fa-check'></i> Music can be changed as per your choice. But, 1 Song only (Share the song link of your choice. (Note that we do not provide song editing/ cutting services).</p>
                <p><i className='fa fa-check'></i> The revision will be done one time after the first draft of the video. If changes are required after finalization, charges will be applicable according to the changes necessary.</p>
                <p><i className='fa fa-check'></i>  No full watermark will be there on the final invite. Our logo will appear on the last slide of the invitation (Designed by : Digiinvite.in) View Sample. The removal cost will be an extra ₹299/-
                Finally, you will get two sizes for video invites. 1st HD version via Google Drive Link and 2nd will be the WhatsApp version.</p>
                <p><i className='fa fa-check'></i>  In case you need multiple versions, like one for the bride/ groom, or one version with only certain functions, and another will all functions, you have to pay 30% amount of the actual template.
                We do not share open files or templates. So please do not ask for them.</p>
                <p><i className='fa fa-check'></i>  We're still dealing with printing materials. However, if you require an HD File in PDF Format, we will provide you with the design of your invitations or signboards along with the HD PDF file.</p>
                <p><i className='fa fa-check'></i> By default, we make the design in 1080×1920 pixels, (Video & PDF Card) if anyone wants to change the size, they must provide the design size before ordering.</p>
                <p><i className='fa fa-check'></i> After the client’s event, we can share or upload their invitations or caricatures to our portfolio. If anyone objects to the same, they can deny it before order or ask for a delete on our portfolio.</p>
            </Sub>            
            <Sub name={`Delivered within 24 working hours.`}>
                <p><i className='fa fa-check'></i> JPG/PDF Ecard will be delivered within 24 working hours. (After placing the order)</p>
                <p><i className='fa fa-check'></i>  video/GIF, Caricature Ecard/Video invites will be delivered within 2-3 working Days. (After placing the order)
                In the case of a seasonal rush, delivery time can affect</p>
                <p><i className='fa fa-check'></i>  Our working hours will be Mon.–Sat. (IST 9.30 am – IST 6.00 pm)</p>
                <p><i className='fa fa-check'></i>  Urgent Video/Caricature The cost of the invite order will be an additional 1000/- INR</p>
                <p><i className='fa fa-check'></i>  Our prices are fixed because we are already selling at up to 60% discount rates; there is no place for bargaining over our prices.</p>
                <p><i className='fa fa-check'></i>  Payment will be not refundable in any circumstances.</p>
                <p><i className='fa fa-check'></i>  Payment will be 50% in advance and rest after finalized card.</p>
                <p><i className='fa fa-check'></i>  Font & Color can be changed.</p>
                <p><i className='fa fa-check'></i> For more information please feel free to contact @ +91 88-587-83-587
                info.digiinvite@gmail.com</p> 
            </Sub>
        </Box>
    </Box>
    </>
    );
} 
const Sub = ({name,children}) => {
    return (
        <Box sx={{mt:5,color:'gray'}}>
        <Typography variant='h4' sx={{fontWeight:600,fontSize:{xs:'1rem',sm:'1rem',md:'1.2rem'},lineHeight:'1.7rem',color:'#ffad19'}}>{name} </Typography>
        <Typography variant='span' sx={{fontSize:{xs:'0.8rem',sm:'1rem'}}}>{children}</Typography>  
        </Box>
    );
}
 


export default Terms;
