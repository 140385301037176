import React from 'react';
import Box from '@mui/material/Box'; 
import Banner from "./homes/banner";
import Typography from '@mui/material/Typography';
// import Helmet from "react-helmet";

const Privacy = () => {
    return (
    <>
      {/* <Helmet>
          <title>Privacy Policy || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
          <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
        Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
        create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
      </Helmet> */}
    <Banner name={`Privacy Policy`}/>
    <Box sx={{width:'100%',mb:5,}} >
        <Box  className="container" >
        
        <Sub name={`Our commitment towards data security`}> 
            <p>Thank you for visiting our web site. This privacy policy tells you how we use personal information collected at this site. 
                It is Digiinvite’s policy not to use or share the personal information about Visitors or Customers in anyway. You can entrust us on data security.</p>
            <p>Services and Web sites we sponsor have security measures in place to protect the loss, misuse, and alteration of the information under Animaker’s control. 
                While we make every effort to ensure the integrity and security of our network and systems, 
                we cannot guarantee that Digiinvite’s security measures will prevent third-party “hackers” from illegally obtaining this information.</p>
            <p>We may change this Privacy Policy at any time by posting the revised Privacy Policy in the “Privacy Policy” section of the Site. 
                The revised Privacy Policy is effective immediately when posted on the Site. You should review the Site and the Privacy Policy periodically to learn of any revisions to this Privacy Policy. 
                Your continued use of the Site after the effectiveness of such revisions will constitute your acknowledgment and acceptance of the terms of the revised Privacy Policy.</p>
        </Sub>
        <Sub name={`Collection and Use of Personal Information`}>
            <p>We will only collect personal information, such as your name, telephone number or email address, when it is voluntarily submitted to us on the Site. 
                We will use this information to comply with your request for information or as otherwise disclosed to you when you submit your information. 
                From time to time, we may refer to that information to better understand your needs and how we can improve our services. We may use that information to contact you. 
                We will not otherwise transfer the personal information you provide on the Site to any third party unless otherwise disclosed to you.</p>
        </Sub>
        <Sub name={`Cookie/Tracking Technology`}>
            <p>The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering 
                information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the Site. 
                Cookies can also help customize the Site for visitors. Personal information cannot be collected via cookies and other tracking technology, however, 
                if you previously provided personally identifiable information, cookies may be tied to such information. Aggregate cookie and tracking information may be shared with third parties.</p>
        </Sub>
        <Sub name={`Distribution of Information`}>
            <p>We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. We may do so when: (1) permitted or required by law; or, 
                (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions; or, (3) investigating fraud which has already taken place. 
                The information is not provided to these companies for marketing purposes.</p>
        </Sub>
        <Sub name={`Commitment to Data Security`}>
            <p>Your personally identifiable information is kept secure. Only authorized employees, agents and contractors (who have agreed to keep information secure and confidential) have access to this information. 
                All emails and newsletters from this site allow you to opt out of further mailings.</p>
        </Sub>
        <Sub name={`Privacy Contact Information`}>
            <p>If you have any questions, concerns, or comments about our privacy policy you may contact us using the information below: By e-mail: info.digiinvite@gmail.com</p>
            <p>We reserve the right to make changes to this policy. Any changes to this policy will be posted.</p>
        </Sub>
        </Box>
    </Box>
    </>
    );
}
const Sub = ({name,children}) => {
    return (
        <Box sx={{mt:5,color:'gray'}}>
        <Typography variant='h4' sx={{fontWeight:600,fontSize:{xs:'1rem',sm:'1rem',md:'1.2rem'},lineHeight:'1.7rem',color:'#ffad19'}}>{name} </Typography>
        <Typography variant='span' sx={{fontSize:{xs:'0.8rem',sm:'1rem'}}}>{children}</Typography>  
        </Box>
    );
}

export default Privacy;
