import React from 'react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link} from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Payment() {

    const paymentId =localStorage.getItem('paymentOldId');
    const payment =localStorage.getItem('payment');
    // const order =localStorage.getItem('order');

  return (
    <Box className='container'>
        <Box sx={{p:5,textAlign:'center'}}>
        <Box sx={{p:2}}>
        </Box>

            <Typography sx={{fontSize:'1.5rem',color:'green'}}> 
                Your order is placed successfully, <br/>and your payment is under verification.
            </Typography>
            <Link to="/">
                <Button >Go To Home</Button>

                {/* https://api.whatsapp.com/send?phone=+918858783587&text=hello order%20id=%20${order}, */}
            </Link>
            <Button href={`https://api.whatsapp.com/send?phone=+918858783587&text=I%20have%20paid%20the%20amount%20of%20${payment},%20and%20my%20payment%20ID%20is%20${paymentId}%20please%20Confirm%20My%20Payment%20and%20Order`} target="_blink" sx={{color:'green'}} ><WhatsAppIcon/> WhatsApp</Button>
            
        </Box>
    </Box>
  )
}
