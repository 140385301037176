import React from 'react';
import Box from '@mui/material/Box';
import Slider from "react-slick";

import { styled } from '@mui/material/styles';
const BoxStyle = styled(Box)(({ theme }) => ({ 
    padding:{ xs: '0', md: '0 50px 0 0'},
    "& .slick-next":{opacity:'0',display:'none', }, 
    "& .slick-prev":{opacity:'0',display:'none',}, 
    
}));
export default function Review({reviews}) { 
    var settings = {
        // dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      };
  return (
    <BoxStyle>
        <Slider {...settings}> 
            { reviews.map(review=>
            <Box className="testimonial" key={review.id}>
              <Reviews img={review.url}name={review.name} 
              review={review.dis} deg={review.deg} typ={review.typ} />
            </Box>
            )}
            {/* <Box className="testimonial">
              <Reviews img={``}name={`name`} review={'reviwdd'} />
            </Box>  */}
        </Slider>
        {/* <Box className="row">
            <Box sx={{width:{ xs: '100%', md: '25%' }}} className="test-img  ">
                      <img className="img-responsive center-block" src="//cdn.shopify.com/s/files/1/0531/1604/3454/files/testi1_100x100.png?v=1614292865" alt="" />
            </Box>
            <Box sx={{width:{ xs: '100%', md: '75%' }}}  className="des_testimonial d-test  ">
                        <h4>mr. john deo</h4>
                      &nbsp;
                      <h5>- designer</h5>
                      <hr/>
                        <p></p>
                        <p>Sed elit quam, iaculis sed semper sit amet udin vitae nibh. at magna akal semperFusce commodydo molestie elit quam, iaculis sed sempsum Dolor tusima latiup udgn vitae nibh. </p>
                        <p></p>
            </Box>
        </Box> */}
    </BoxStyle>
  )
}
 
const Reviews = ({img,name,review,deg,typ}) => {
  const imgUrl=`${img?`http://www.digiinvite.in/ceo/images/${img}`:+typ===1?`https://www.digiinvite.in/ceo/assets/images/reviews1.png`:`https://www.digiinvite.in/ceo/assets/images/reviews0.png`}`;
  //  category
  return (
    <Box className="row"> 
      <Box sx={{width:{ xs: '100%', md: '25%' }}} className="test-img  ">
        <img src={imgUrl} alt="" />
      </Box>
      <Box sx={{width:{ xs: '100%', md: '75%' }}}  className="des_testimonial d-test  ">
          <h4>{name}</h4>
        &nbsp;
        {deg?<h5>- {deg}</h5>:''}
        <hr/>
          <p></p>
          <p>{review}</p>
          <p></p>
      </Box>
    </Box>
  );
}
 

