import React,{useEffect}from 'react'; 
import Box from '@mui/material/Box'; 
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Link} from "react-router-dom";
import Category from './homes/category';
import Slide from './homes/slide';
import Stereo  from './homes/Stereo';
import Popup from '../page/popup';
// import LogoSlide from './homes/LogoSlide'; 
import NewsLetter from './homes/Newsletter';
import DisplayOffer from './homes/displayOffer';
import Review from './homes/review'; 
import Features from './homes/Features'; 
import ShowCate from './homes/showCate';
import Deals from './homes/Deal';
import Special from './homes/Special';
import { UseProContext } from "../context/productContext";
// import Helmet from "react-helmet";

const StyledHome = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root":{fontFamily: 'Work Sans',},
//   "& .slick-next":{opacity:'0', }, 
//   "& .slick-prev":{opacity:'0',}, 
 }));

 
// const imgLink=[ 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo1.png', 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo2.png', 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo3.png', 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo4.png', 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo5.png', 
//     'https://wp.w3layouts.com/appflow/wp-content/themes/app-flow/assets/images/logo3.png', 
// ]
// const ButtonHover = styled(Box)(({ theme }) => ({
//   width:'18%',background : '#f7f7f7',borderRadius:'8px',
//   position: 'relative',top: '0px',
//   transition: `top 5s ease`,
//   transitionDuration: `0.2s`,
//   "&:hover ":{ top: '-10px', 
//     boxShadow:`0.4rem 0.4rem 1rem -0.4rem  black`,
//     "& a":{color:'blue', }
//   },
//  }));

const Home = () => {
    const values = UseProContext();
    const feature = values.featureProducts;
    // console.log(values.banners);
    useEffect(() => {
        document.title = "India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite";
    }, []);
    return (
    <StyledHome component='main' className="main-content"> 
    {/* <Helmet>
        <title>India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
        <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
      Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
      create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
    </Helmet> */}
    <Box sx={{display:'flex',flexWrap:'wrap'}} ><Box sx={{width:'100%'}} >

    <Box sx={{width:'100%'}} >
        <Box  className="container" >
        <Box sx={{display:'flex',flexWrap:'wrap'}} >
            <Box sx={{width:'22%',p:'30px 20px 0 0',display:{ xs: 'none', md: 'flex' }}} >
            <div className="menurel" style={{ position: "relative",width:'100%' }}>
                <div id="wr-menu-icon"> 
                    <h3 className="wr-menu" style={{ display: "flex",alignItems: "center",}} >
                        <button  className="btn-navbar navbar-toggle pull-left" type="button">
                        <div className="ico-menu" style={{ margin: "5px auto",}} >
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                        </button>
                        <Box className="cate" >All Categories</Box>
                    </h3>
                </div>
                <div  id="under-menu"   className="collapse in" style={{ height: "auto", position: "relative" }}>
                    <ul className="site-nav">
                        <Category values={values}/>
                    </ul>
                </div>
                </div>
            </Box>
            <Box sx={{width:{ xs: '100%', md: '78%' }}} >
                {/* {values.isLoading?<>
                <Stack spacing={1} sx={{p:"0 16px",width:'100%',pt:3,}}> 
                    <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 }}} /> 
                </Stack>
                </> :""} */}
                <Slide slides={values.banners.slide} isLoading={values.isLoading} />
            </Box>
            
        </Box>
        </Box>
    </Box>

    <Box sx={{width:'100%'}} >
        <Box   className="container" >
            <DisplayOffer slides={values.banners.banner} isLoading={values.isLoading} />            
        </Box>
    </Box>

    <Box sx={{width:'100%'}} >
        <Box   className="container" >
            <Features feature={feature} isLoading={values.isLoading}/>            
        </Box>
    </Box>

    <Box sx={{width:'100%'}} >
        <Box  className="container" >
            {/* <Box  className=" offer-text" > 
                <div className="text-center">
                    <h4>Rewards just for shopping!</h4>
                    <p>Don’t forget to opt into News to have your offers and rewards 
                        delivered right to your inbox!</p>
                </div> 
            </Box> */}
            <div className="collectionItems"> 
                <div className="beffect">
                    <Link to={`/${values.banners.banner&&values.banners.banner[5].name}`}>
                        <img src={values.banners.banner&&values.banners.banner[5].url} 
                            alt="" className="img-responsive center-block" />
                    </Link> 
                </div> 
            </div>
        </Box>
    </Box>

    <Box sx={{width:'100%'}} >
        <Box  className="category-home container" >
            <Box  sx={{display:{ xs: 'block', md: 'flex' }}} >
                <Box sx={{width:{ xs: '100%', md: '33%' },p:'20px 15px',display:'flex',justifyContent:'center'}} className="catehome-img" >
                    {values.isLoading?
                    <Stack spacing={1} sx={{width:'100%',display:{xs: 'none', md:'flex'}}}> 
                        <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 },borderRadius:'8px'}} /> 
                    </Stack>
                    :
                    <div className="beffect">
                        <Link to={`/${values.banners.banner&&values.banners.banner[4].name}`}>
                        <img src={values.banners.banner&&values.banners.banner[4].url}  
                        alt="" className="img-responsive center-block" />   
                        </Link>  
                    </div> }
                </Box>
                <Box sx={{width:{ xs: '100%', md: '67%' },p:'20px 15px'}}className="catehome" >
                    <ShowCate  category={values.subcategory} isLoading={values.isLoading} />
                </Box>
            </Box>
        </Box>
    </Box>
    <Box sx={{width:'100%'}} >
        <Box  className="container " >
            {feature[3]&&feature[3].pro.length>0?
            <Box  className="allbgc " >
                <h1 className="heading"><span>Deal of The Week</span></h1>
                <Deals feature={feature[3]} />
            </Box>
            :""}
        </Box>
    </Box>
    {/* <Box sx={{width:'100%'}} >
        <Box  className="container" >
            <Box  className=" logoslide" >
                <LogoSlide img={imgLink} />
            </Box>
        </Box>
    </Box> */}
    <Box sx={{width:'100%'}} >
        <Box  className="container " >
            {feature[4]&&feature[4].pro.length>0?
            <Box  className="allbgc " >
                <h1 className="heading"><span>Special Templates</span></h1>
                <Special feature={feature[4]} />
            </Box>
            :""}
        </Box>
    </Box>
    <Box sx={{width:'100%',mt:5}} >
        <Box  className="container " >
        <div className="collectionItems"> 
          <div className="beffect">
            <Link to={`/${values.banners.banner&&values.banners.banner[6].name}`}>
                <img src={values.banners.banner&&values.banners.banner[6].url}
                    alt="" className="img-responsive center-block" />
            </Link> 
          </div> 
        </div>
        </Box>
    </Box>
    

    <Box sx={{width:'100%',mt:5}} >
        <Box   className="container" >  
        <Stereo/> 
        </Box>
    </Box>

    <Box sx={{width:'100%',bgcolor:'#f5f5f5',mt:5}} >
        <Box   className="container" > 
            <Box sx={{display:{ xs: 'block', md: 'flex' },flexWrap:'wrap',p:{ xs: '0', md:'16px 0' }}} >
                <Box sx={{width:{ xs: '100%', md: '50%' }}} ><Review  reviews={values.reviews} /></Box>    
                <Box sx={{width:{ xs: '100%', md: '50%' }}} ><NewsLetter /> </Box>    
            </Box>
        
        </Box>
    </Box>
             
 
           

    </Box>
    </Box> 
    {values.isLoading?<></>:<Popup />}
    </StyledHome>
    );
}





export default Home;
