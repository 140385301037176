import React from 'react';
import Slider from "react-slick"; 
import { Box } from "@mui/material";
import Products from '../../comp/Products'; 
const Special = ({feature}) => {
    var settings = { 
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                // dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
    }

    return (
        <Box>
            <Slider {...settings}>
                {feature?feature.pro.map((pro) => 
                    <Box key={pro.code} sx={{p:2}}>
                        <Products pro={pro} />
                    </Box>
                ):""} 
            </Slider>
            
        </Box>
    );
}

export default Special;
