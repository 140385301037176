import React from 'react';
import Box from '@mui/material/Box';
import Slider from "react-slick";
import Typography from "@mui/material/Typography";

import { styled } from '@mui/material/styles';
const BoxStyle = styled(Box)(({ theme }) => ({ 
    padding:{ xs: '0', md: '0 50px 0 0'},
    "& img":{border:'2px solid #ffad19',borderRadius:'50%',margin:'0.8rem auto'}, 
    "& h4":{color:'#ffad19', }, 
    "& p":{color:'gray', }, 
    "& h5":{color:'#777', }, 
    "& i":{color:'#ffad19', }, 
    "& .review":{background:'#f7f7f7',
      "&:hover ":{background:'#ffad19c4',
        "& h4,h5,p,i":{color:'white', },
        "& img":{border:'2px solid white', },
      }
    }, 
    
}));
export default function Review({reviews}) { 
    var settings = {
        // dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              // dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <BoxStyle>
        <Slider {...settings}> 
            { reviews.map(review=>
            <Box className="testimonials" key={review.id}>
              <Reviews img={review.url}name={review.name} 
              review={review.dis} deg={review.deg} typ={review.typ} />
            </Box>
            )} 
        </Slider> 
    </BoxStyle>
  )
}
 
const Reviews = ({img,name,review,deg,typ}) => {
  const imgUrl=`${img?`http://www.digiinvite.in/ceo/images/${img}`:+typ===1?`https://www.digiinvite.in/ceo/assets/images/reviews1.png`:`https://www.digiinvite.in/ceo/assets/images/reviews0.png`}`;
  //  category
  return (
    <Box sx={{pr:2}}> 
      <Box sx={{p:5,borderRadius:'12px'}} className='review' >
        <ul className='row'>
          <li><i className={`fa fa-star`} style={{fontSize:"xx-large"}}></i></li>
          <li><i className={`fa fa-star`} style={{fontSize:"xx-large"}}></i></li>
          <li><i className={`fa fa-star`} style={{fontSize:"xx-large"}}></i></li>
          <li><i className={`fa fa-star`} style={{fontSize:"xx-large"}}></i></li>
          <li><i className={`fa fa-star`} style={{fontSize:"xx-large"}}></i></li>
        </ul>
        <Typography sx={{mt:2,}}>“{review} ”</Typography>
      <Box   sx={{display:'flex',justifyContent:'space-between', alignItems:'center',}}>
        <Box sx={{width:{ xs: '100%', md: '25%' }}}  >
          <img src={imgUrl} alt="" width="100px" />
        </Box>
        <Box sx={{width:{ xs: '100%', md: '65%' }}}   >
          <Typography variant='h4' sx={{ fontSize:'1rem',fontWeight:600,
                display:'inline-block',textTransform:'capitalize'}} >
              {name}</Typography>
            {/* <br/> */}
          {/* &nbsp; */}
          {deg?<Typography variant='h5' sx={{ fontSize:'0.9rem',fontWeight:400,textTransform:'capitalize'}} >- {deg}</Typography>:''} 
        </Box>
      </Box>
      </Box>
    </Box>
  );
}
 

