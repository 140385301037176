import React from 'react';
import { useEffect,useState } from 'react';
import { UseCartContext } from '../context/CartContext';
// import Helmet from "react-helmet";

import { UseProContext } from '../context/productContext';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
// import CloseIcon from '@mui/icons-material/Close';
import FormatPrice from '../comp/FormatPrice';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import Banner from "./homes/banner";

import { styled } from '@mui/material/styles';
const StyledCart= styled(Box)(({ theme }) => ({
    "& .MuiTypography-root":{fontFamily: 'Ubuntu',},
    "table":{width:'100%',},
    "tbody":{width:'100%',},"tr":{width:'100%',},
    "th":{width:'50%',textAlign: 'start',},
    "td":{width:'50%',textAlign: 'end',fontWeight:600,},
   }));
   
export default function Carts() {
    const values=UseProContext();
    const product =values.products;
    
  useEffect(() => {
    document.title = `Carts || India's Best Digital Invitation E Card Maker - Animated Invitation Maker - Digi Invite- Digi Invite`;  
  }, []);

    const [inputs, setInputs] = useState('');
    // const options=['INR, ₹','USD, $'];
    const {carts,setCarts}=UseCartContext();
    // localStorage.setItem('currency', 'en-IN,INR');

    // const cartCount =carts.count;
    const cartPros =carts.cart;
    // console.log(cartPros);
    const [cartPro, setCartPro] = useState(cartPros);
    useEffect(() => {
        setCartPro(cartPros);
    }, [cartPros]);

    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        setTotalPrice(null);
        // eslint-disable-next-line array-callback-return
        Object.keys(cartPro).map((obj) => {
        // cartPro[obj].count*
        const options=cartPro[obj]
        const totals=options.price+options.logo+options.Language+options.Version+options.Caricature+options.Extra;
        setTotalPrice(oldArray=>oldArray+totals);
        });
    }, [cartPro]);

    const [gstPrice, setGstPrice] = useState(0);
    useEffect(() => {
        setGstPrice(totalPrice+totalPrice*0/100);
    }, [totalPrice]);

    // cartPro.map((pro)=>{
    //     console.log(pro);
    //  })
    function deleteProduct(id) {
        // localStorage.setItem('pro_id', '');
        // cartPro.forEach((pro) => {
        //     if(pro.pro_id!==id){
        //         for (let i = 0; i < pro.count; i++) {
        //             let pro_ids = localStorage.getItem('pro_id');
        //             localStorage.setItem('pro_id', pro.pro_id+','+pro_ids);
        //         }
        //     }
        // });
        // let newCart = localStorage.getItem('pro_id');
        // if(!newCart){newCart=','}
        // setCarts({type:"MY_CART_DATA",payload:newCart,pro:product});
        // const upResults = cartPro.filter(obj => obj.pro_id!==id);
        // setCartPro(upResults);
        const savePro =localStorage.getItem('pro_form');
        const Pro=JSON.parse(savePro);
        const toDelete = new Set([id, ' ']);
        const newArray = Pro.filter(obj => !toDelete.has(obj.id));
        // console.log(newArray);
        const option=JSON.stringify(newArray);
        localStorage.setItem('pro_form', option);
        setCarts({type:"ADD_CART_DATA",pro:product,data:newArray});
    }
    const ApplyCoupon=(e)=>{
      console.log('Apply Coupon code'+inputs);
      localStorage.setItem('coupon', inputs);
        // setInputs();
    }
    // const imgOffers={color:'green',fontWeight: 700, position: 'absolute',width: '100%',textAlign: 'center',};
  return (
    <>{carts.isLoading? <>loading</>:<>
    <Banner name={`Carts`}/>
    <StyledCart   className=" container checkout" >
    {/* <Helmet>
        <title> Carts || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
        <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
      Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
      create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
    </Helmet> */}
    
      <Box  sx={{width:'100%',bgcolor: 'background.paper', display: 'flex',justifyContent:'center',}}>
            <Typography sx={{fontWeight: 600,fontSize:'1rem',  }} >
                <Link to='/'>Home </Link>/ Cart
            </Typography>
      </Box>
      {/* <Autocomplete value={inputs}
         onChange={(event, newValue) => {setInputs(newValue);}}
         id="controllable-states2" options={options} sx={{ width: 300 }}
         renderInput={(params) => <TextField {...params} label="Change Currency" />}/> */}
     <Typography sx={{fontWeight: 700,fontSize:'2rem',  }} >
        recently added items 
     </Typography>
    <Box sx={{display:'flex',flexWrap: 'wrap',}}>
     <Box sx={{width:'100%'}}>
      {Object.keys(cartPro).map((obj, i) =>
      <Box sx={{display:'flex',p:3}} key={i} >
        <Box sx={{}}>
        <Link to={`/product/${cartPro[obj].code}`}>
            <figure> 
            {/* <span  style={imgOffers} > {cartPro[obj].offer}</span> */}
            <img  alt={cartPro[obj].pro_title} width='150px'  
            src={cartPro[obj].img.length>0?`${cartPro[obj].img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`}/>
            </figure>
        </Link>
        </Box>
        <Box sx={{p:2,width: { xs: '100%', md: '50%' },}}>
          <Link to={`/product/${cartPro[obj].code}`}>
            <Typography sx={{fontWeight: 600,fontSize:'1rem',  }} >
                {cartPro[obj].pro_title} 
            </Typography>
          </Link>
          <Typography sx={{fontWeight: 600,fontSize:'1rem',  }} >
             {cartPro[obj].code}  
          </Typography>
          <Box sx={{display:'flex'}}>
          <Box sx={{fontWeight: 600,fontSize:'1rem',textAlign:'start'  }} >
            <Typography><b>Option Price: </b> <FormatPrice price={cartPro[obj].logo+cartPro[obj].Language+cartPro[obj].Version+cartPro[obj].Caricature+cartPro[obj].Extra} /></Typography>
            <Typography><b>Card Price: </b> <FormatPrice price={cartPro[obj].price} /></Typography>
            <Typography><b>Total Price: </b> <FormatPrice price={cartPro[obj].price+cartPro[obj].logo+cartPro[obj].Language+cartPro[obj].Version+cartPro[obj].Caricature+cartPro[obj].Extra} /></Typography>
          </Box>
          <IconButton onClick={()=>deleteProduct(cartPro[obj].pro_id)}><DeleteIcon /></IconButton> 
          </Box>
        </Box> 
      </Box>
     )}

     </Box>
     <Box  sx={{width:'100%',display:'flex',flexWrap: 'wrap',}}>
        <Box  sx={{width:{ xs: '100%', md: '50%' },padding:'0 16%'}}>
            {/* <TextField id="outlined-basic" label="Coupon Code" variant="standard" /> */}
            <label htmlFor="Coupon">Apply Coupon Code</label>
            <input type="text" id='Coupon' placeholder='Apply Coupon Code' onBlur={(e)=>setInputs(e.target.value)} />
            <button className='btn btn-secondary' type="text" onClick={()=>{ApplyCoupon(1)}} >Apply</button>
        </Box>
        <Box  sx={{width:{ xs:'100%',md:'50%'},p:'0 16%'}}>
            <Link to='/products/Party-Invites'><button variant="contained" className='btn' >Continue Shopping</button></Link>
        </Box>
     </Box>
     <Box  sx={{width:'100%'}}><hr /></Box>
     <Box  sx={{width:{ xs: '100%', md: '50%' }}}></Box>
     <Box  sx={{width:{ xs: '100%', md: '50%' }}}>
        <Box sx={{p:{ xs: 0, md: 5 },}}>
            <Box sx={{p:{ xs: 1, md: 5 },bgcolor:'lightgray'}}>
                <Typography sx={{fontWeight: 700,fontSize:'1.5rem',  }} >
                    Cart totals
                </Typography>
                <table><tbody>
                {/* <tr key="1">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem',}} >Subtotal</Typography>
                    </th>
                    <td><FormatPrice price={totalPrice} /></td>
                </tr> */}
                {/* <tr key="2">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem', }} >18% Gst </Typography>
                    </th>
                    <td><FormatPrice price={gstPrice-totalPrice}   /></td>
                </tr> */}
                <tr key="3">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem'}}>Total</Typography>
                    </th>
                    <td><FormatPrice price={gstPrice}   /></td>
                </tr>
                </tbody></table>
                <Link to='/checkout'><button className='btn' >Checkout</button></Link>
            </Box>
        </Box>
     </Box>
     </Box>
    </StyledCart>
    </>
    }
        
    </>
  )
}
