/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import FormatPrice from "./FormatPrice";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
// import { Link } from "@mui/icons-material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { UseProContext } from '../context/productContext';
import { UseCartContext } from '../context/CartContext';
import Tooltip from '@mui/material/Tooltip';

const Products = ({ pro }) => {
  const values=UseProContext();
  const product =values.products;

  const { code, pro_title, img, price, m_price,offer,pro_id } = pro;
  const offPrice = m_price / 1;
  const href=`/product/${code}`;

  const img1=img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;
  const img2=img.length>1?`${img[1].url}`:img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;

  const {setCarts}=UseCartContext();
  function handleAddProduct(productId) {
    // const AddProId=[];
    // AddProId.push(productId);
    // const saveProId =localStorage.getItem('pro_id');
    // AddProId.push(saveProId);
    // localStorage.setItem('pro_id', AddProId);,"Caricature":stateCaria,"Extra":stateExtra,
    // setCarts({type:"ADD_CART_DATA",payload:localStorage.pro_id,pro:product});

    let options={"id": productId,"typ": "","logo": 0,"Language": 0,"Version": 0,"Caricature":0,"Extra":0,};
    if (localStorage.getItem("pro_form") ===null || localStorage.getItem("pro_form") ==='' ) {
      const option=JSON.stringify([options]);
      localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:[options]});
    }else{
      const savePro =localStorage.getItem('pro_form');
      const Pro=JSON.parse(savePro);
      const cards=[options,...Pro];
        const unique = [...new Map(cards.map(item =>[item['id'], item])).values()]
        const option=JSON.stringify(unique);
        localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:unique});
    }
  }

  function handleAddWish(productId) {
    const AddProId=[];
    AddProId.push(productId);
    const saveProId =localStorage.getItem('wish_id');
    AddProId.push(saveProId);
    localStorage.setItem('wish_id', AddProId);
    setCarts({type:"MY_WISHLIST_DATA",payload:localStorage.wish_id,pro:product});
  }

  function handleBuyProduct(productId) {
    handleAddProduct(productId);
    window.history.pushState({}, "", "/carts");
    const navEvent = new PopStateEvent('popstate');
    window.scrollTo({
      top: 0,//behavior: "smooth",
    });
    window.dispatchEvent(navEvent);
  }
  return (
    <>
      <Box sx={{P:"auto"}} className="item-row">
        <div className="product-container">
          <div className="imgblock">
            <Link to={href}>
              <img src={img1} alt={pro_title} />
            </Link>
            <Link to={href}>
              <img  src={img2} alt={pro_title} className="second-img"/>
            </Link>
          </div>
          {offPrice === price ? "" : <span className="sale">{offer}</span>}
          <Box className="wbproductdes ">
            <h5>
            <Link to={href} >
              <marquee  direction="left" scrollamount="3.5">{pro_title}</marquee>
            </Link>
              <p className="thumbdes ">{code}</p>
            </h5>
            <div className="product_price">
              <span className="grid-link__org_price">
                  <FormatPrice price={price} />
                  {price === offPrice ? ( <></>) : (
                    <span className="dis-price"><del> <FormatPrice price={offPrice} /></del></span>
                  )}
              </span>
            </div>
            <Box className="button-group">
              <div className="absbtn">
                <div className="add-to-wishlist">
                  <div className="show"> 
                  <Tooltip title="Add To Card " >
                    <Box component="a" className="add-cart-btn" onClick={()=>handleAddProduct(pro_id)} >
                        <AddShoppingCartIcon />
                    </Box> 
                  </Tooltip>
                    
                  </div>
                </div>
                <div className="quick-view-text">
                  <div className="show"> 
                  <Tooltip title="Add To Wish List " >
                    <Box component="a" className="add-wish-btn" onClick={()=>handleAddWish(pro_id)} >
                        <StarBorderIcon />
                    </Box> 
                  </Tooltip>
                  </div>
                </div>
                <div className="addcart">
                <Tooltip title="Buy Now " >
                  <Box component="a" className="add-check-btn" onClick={()=>handleBuyProduct(pro_id)} >
                    <ShoppingCartCheckoutIcon />
                  </Box>
                </Tooltip>
                </div>
                {/* <Link to={href} className="quick-view-text"><VisibilityIcon /></Link> */}
              </div>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Products;
