import React from "react";
import { Box } from '@mui/material';
import { Link } from "react-router-dom";
// import { UseProContext } from "../../context/productContext";
import { useState,useEffect } from "react";
import FormatPrice from "../../comp/FormatPrice";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


const menus = [
  { id:'1', name: 'Religion',url: '/#Religion'},
  {id:'1',name: 'Theme',url: '/#Theme'},
  {id:'1',name: 'Design',url: '/#Design'},  
  {id:'1',name: 'Format',url: '/#Format'},
  // {id:'2',name: '',url: '/#photo'},
  // {name: 'Policy',url: '/Policy'},  
];
// const menu = [ 
//   { id: 1,title: 'Wedding', 
//     submenu: [
//       {name: 'Religion',url: '/#Religion'},
//       {name: 'Theme',url: '/#Theme'},
//       {name: 'Design',url: '/#Design'},  
//       {name: 'Format',url: '/#Format'},
//       {name: '',url: '/#other'},
//     ]  
//   },
//   { id: 1,title: 'Party Invite', 
//   submenu: [
//     {name: 'Religion',url: '/#Religion'}, 
//   ]  
// },   
// ];
const Category = ({values}) => {
  // console.log(values.category);
  // const values = UseProContext();
    // const feature = values.featureProducts; 
    // const initialState=[{ pro_id:'',cate_id:'',pro_title:'',offer:'',price:'',img:'',m_price:'',code:''}]
    const [bestseller, setSeller] = useState(); 
    const [bestPro, setBestPro] = useState(); 
    const [select, setSelect] = useState(null); 

    useEffect(() => {
    if (values.featureProducts.length>1) {
      // console.log(values.featureProducts[2].pro[0].code);
      setSeller(values.featureProducts[2].pro)
    } 
    }, [values]);
 
      const imgUrl=`https://www.digiinvite.in/ceo/assets/images/pro.png`;

      function handelBest(id) { 
        setSelect(id);
        const best = bestseller.filter(pro=>pro.cate_id===id);
        setBestPro(best);
      } 

      // const [code,pro_id,cate_id,pro_title,img,price,m_price]=bestseller;bestseller[0].img.length>0?`${bestseller[0].img[0].url}`:
    

  return (
    <>
      <>
        {/* <div id="wr-menu-icon">div className="menurel" style={{ position: "relative",width:'100%' }}
          <h3 className="wr-menu" style={{ display: "flex",alignItems: "center",}} >
            <button  className="btn-navbar navbar-toggle pull-left" type="button">
              <div className="ico-menu" style={{ margin: "5px auto",}} >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </button>
            <Box className="cate" >All Categories</Box>
          </h3>
        </div> */}
          {/* dataToggle={'collapse'} dataTarget={'#under-menu'} ariaExpanded={'false'}  */}
        {/* <div  id="under-menu"   className="collapse in" style={{ height: "auto", position: "relative" }}>
          <ul className="site-nav"> */}
            {/* <li className="level1">
              <Link className="sitemenus" to="/products/all">All Category</Link>
            </li>  */}
          {values.isLoading?<>
            <Stack spacing={1} sx={{p:"0 16px"}}>
              {[1,2,3,4,5,6,7].map(id=><Skeleton variant="text" sx={{ fontSize: '1rem' }} key={id} />)}
            </Stack>
          </> :<>
            {values.category.map(cate=><React.Fragment key={cate.id}>
              <li className="level1 hovermenu dropdown mega-menu" onMouseEnter={()=>handelBest(cate.id)} onMouseLeave={()=>setSelect(null)}  onClick={()=>handelBest(cate.id)}>
               {cate.category.length>1?<>
                  <Link to={`/`} className=" sitemenus" > 
                    {cate.title} <i className={`fa ${cate.id===select?`fa-angle-right`:`fa-angle-down`} pull-right`}></i>
                  </Link>
                  {+cate.id<10?<>    
                  <Box className="site-nav-dropdown" sx={{position:{ xs: 'absolute', sm: 'absolute' , md: 'absolute' },left:{xs:'0%',sm:'0%',md: '100%' ,}}} >
                    <div className="parent-mega-menu ">
                      <Box className="row" sx={{display:{ xs: 'block', sm: 'flex'}}}>
                      {menus.map(subMenu=><React.Fragment key={subMenu.name}>
                       <div className="inner ">
                        <Link to={`/`} className="currentm">Search By {subMenu.name}</Link>
                          <ul className="dropdown">
                            {cate.category.map(sub=>
                            <React.Fragment key={sub.id} >
                              {sub.cate_tag===subMenu.name  ?
                                <li >
                                  <Link to={`/products/${cate.cate_tag}/${sub.sub_tag}`} >{sub.titles}</Link>
                                </li>
                              :<></>}
                            </React.Fragment> )}
                          </ul>
                        </div>
                      </React.Fragment>)}
                      </Box>
                    </div>
                  </Box>
                  </>:<>
                  <Box className="site-nav-dropdown" sx={{position:{ xs: 'absolute', sm: 'absolute' , md: 'absolute' },left:{xs: '0%', sm: '0%', md: '100%' ,}}}>
                    <div className="parent-mega-menu ">
                      <Box className="row" sx={{display:{ xs: 'block', sm: 'flex'}}}>
                        <div className="inner " style={{width:'50%'}}>
                          <Link to={`/`} className="currentm">
                            Search By Category
                          </Link> 
                          <ul className="dropdown">
                            {cate.category.map(sub=> 
                              <li key={sub.id} >
                                <Link to={`/products/${cate.cate_tag}/${sub.sub_tag}`}  >{sub.titles}</Link> 
                              </li> 
                            )}
                          </ul>
                        </div>
                        <Box className="mega-img row" sx={{width:'50%',display:{ xs: 'none', md: 'flex'}}}> 
                          <div style={{width:'100%',textAlign:'center'}}><h6>Bestseller</h6></div>
                          {bestPro&&bestPro.map((pro,i)=>pro.cate_id===cate.id && i<2?
                          <React.Fragment key={i}> 
                            <div style={{width:'50%'}}> 
                              <div style={{padding:'0 15px'}}>
                                <Link to={`/product/${pro.code}`}> 
                                  <img  src={pro.img.length>0?`${pro.img[0].url}`:`${imgUrl}`} alt=" "  />
                                </Link> 
                              </div>
                              <div className="details wbproductdes" style={{padding:'0 15px'}}>
                                <h5>
                                  <a href={`/product/`} className="grid-link__title"> 
                                    {pro.pro_title} 
                                  </a> 
                                  <p className="thumbdes ">{pro.code}</p>
                                </h5> 
                                <div className="product_price price-box">
                                  <span className="grid-link__org_price">
                                      <FormatPrice price={pro.price} />
                                      {pro.price/1 === pro.m_price/1 ? <></>: 
                                        <span className="dis-price"><del> <FormatPrice price={pro.m_price} /></del></span>
                                      } 
                                  </span>
                                </div>
                                {/* <Link to={`/product/`} className="product-title">{pro.pro_title}</Link>
                                <div className="price-box">
                                  <p>Rs.{pro.price}</p>
                                </div> */}
                              </div>
                            </div>
                          </React.Fragment>:"")}
                          {/* ${bestseller[0].code}${bestseller[0].code} {bestseller[0].pro_title}{bestseller[0].price}*/}
                            
                        </Box>
                      </Box>
                    </div>
                  </Box>
                  </>}
               </>:<> 
               </>}
              </li>
            </React.Fragment>)}
            
            {values.category.map(cate=><React.Fragment key={cate.id}>
              {cate.category.length>1?<></>:
                <li className="level1 hovermenu dropdown mega-menu">
                  <Link to={`/products/${cate.cate_tag}`} className="sitemenus">{cate.title} </Link>
                </li>}
            </React.Fragment>)}

          {/* <span
                className="spr-badge"
                id="spr_badge_6195390808254"
                data-rating="5.0"
              >
                <span className="spr-starrating spr-badge-starrating">
                  <i
                    className="spr-icon spr-icon-star"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="spr-icon spr-icon-star"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="spr-icon spr-icon-star"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="spr-icon spr-icon-star"
                    aria-hidden="true"
                  ></i>
                  <i
                    className="spr-icon spr-icon-star"
                    aria-hidden="true"
                  ></i>
                </span>
                <span className="spr-badge-caption">
                  1 review
                </span>
              </span>/${cate.cate_tag}  */}
            {/* <li>
                <li className="level1 hovermenu dropdown mega-menu ">
                  <a className=" sitemenus" href="/collections/deal-product">
                    Wedding ok
                    <i className="fa fa-angle-down pull-right"></i>
                  </a>

                  <div className="site-nav-dropdown">
                    <div className=" style_3">
                      <div className="parent-mega-menu  ">
                        <div className="row">
                          {menus.map(subMenu=><React.Fragment key={subMenu.name}>
                          <div className="inner ">
                              <a className="currentm" href={subMenu.name}>
                                Search By {subMenu.name}
                              </a>
                              <ul className="dropdown">
                                {}
                                {values.subcategory.map(sub=>
                                <React.Fragment key={sub.id} >
                                  {sub.cate_tag===subMenu.name ?
                                    <li >
                                      <a  href="/collections/watch">{sub.titles}</a>
                                    </li>
                                  :<></>}
                                </React.Fragment> )}
                              </ul>
                            </div>
                          </React.Fragment>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                
                <li className="level1 ">
                  <a  className=" sitemenus"  href="/products/DI0035"> 
                      Caricature Invites 
                  </a> 
                </li> 
                <li className="view_more level1"> 
                  <a className=" sitemenus" href="/collections/smart-watch">
                    <i className="fa fa-plus"></i> More Categories 
                  </a>
                </li>

                <li className="level1 wr_hide_menu" style={{ display: "none" }}>
                  <a className=" sitemenus" href="/collections/all">Top Offers</a>
                  <ul className="site-nav-dropdown"></ul>
                </li> 

                </li> */}   
                
            </>}     
          {/* </ul>
        </div> */}
      </>
    </>
  );
};

export default Category;
