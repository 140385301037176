import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box'; 
import Banner from "./homes/banner";
// import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
// import TextField from '@mui/material/TextField';
import axios from 'axios';
import { UseUserContext } from '../context/userContext '; 
import { useParams } from 'react-router-dom';
import { Link} from "react-router-dom";

// import {sandEmail} from '../comp/email';
const DivHover = styled(Box)(({ theme }) => ({
    padding:'3.5%',background:'white',color:'#6c6d72',
    borderRadius:'12px',
    // boxShadow:`1px 1.732px 60px 0 rgb(0 0 0 / 10%)`,
    position: 'relative',top: '0px', 
    transitionDuration: `0.5s`,
    border:`2px solid white`,
    "& span,h3 ":{  color:'#ffad19', cursor:'pointer',textAlign:'center',fontWeight:500 },
    "&:hover ":{ 
        // top: '-20px', 
    // color:'#ffad19',
    //   boxShadow:`1px 1.732px 60px 0 rgb(0 0 0 / 10%)`,
      border:`2px solid #ffad19`,
      "& a":{color:'white', }
    },
   }));



export  function ForgotPswHandler() {
    const {token}=useParams();
    const [after, setAfter] = useState(null);
    const [userName, setUserName] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [conPassword, setConPassword] = useState(null);

    if(token){
        localStorage.setItem('token-info', token);
        localStorage.setItem('old-password-changed', true);
        window.location.href = '/new-password';
    }else{
        if (localStorage.getItem("token-info") ==null) {
            window.history.pushState({}, "", "/Sign" );
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            localStorage.removeItem('old-password-changed');
        }
        // setOldPassword(oldPassword);
        // console.log('token not defined');
    }
    
    const isOldPsw=localStorage.getItem("old-password-changed");
    const users=UseUserContext();
    const [user, setUser] = useState({});
    useEffect(() => {
        setUser(...users.user);
    }, [users]); 
    // const [name, setName] = useState(null);
    useEffect(() => {
        user&&setUserName(user.userName);
        isOldPsw&&user&&setOldPassword(user.password);
    }, [isOldPsw, user]);
    function details(typ,value) { 
        switch (typ) {
            case "Name":
                // setName(value);
                break;
            case "OldPassword":
                setOldPassword(value);
                break;
            case "NewPassword":
                setNewPassword(value);
                break;
            case "ConformPassword":
                setConPassword(value);
                break;
            default:
                break;
        }    
      }
    const submitHandel=(e,typ)=>{
        e.preventDefault();
        // const login=localStorage.getItem("login");
        const auth  = 'sarwara774h6g2v2jig5d55hf77ks';
        const payload = {keys: auth ,typ:typ, userName:`${userName}`,isOldPsw:isOldPsw,
        oldPassword:oldPassword, newPassword:newPassword,conPassword:conPassword,};
        // console.log(payload);
        apiHandel(payload,typ);

        localStorage.removeItem('old-password-changed');
    }
    const apiHandel=async (payload,typ)=>{
        try{ 
            const res = await axios.post('https://www.digiinvite.in/api/user/index.php', payload);
            const dates =await res.data;  
            // const details =await dates.details;
            const result =await dates.result;
            result   ? localStorage.removeItem('old-password-changed'):setAfter(dates.errors);
            result&&window.history.pushState({}, "", "/profile" );
            const navEvent = new PopStateEvent('popstate');
            result&&window.dispatchEvent(navEvent) ;
            
            // console.log(dates);
            result&&alert('password changed successfully');
            
          } catch(error){
            console.log('API_ERRORS');
          }
    }
    
  return (
    <>
    <Banner name={`Change Password`}/>
    <Box sx={{width:'100%',mt:5,mb:5,pt:2}} >
        <div className="row   justify-content-center" >
        <DivHover sx={{width: { xs: "90%", md: "50%" },mt:3}}>
            {after&&after.map((er,i)=><p key={i} style={{color:'red',textAlign:'center'}}>{er}</p>)}
            <h3>Change Password</h3>
            <form  className='form-horizontal' onSubmit={(e)=>submitHandel(e,3)}>
                {isOldPsw? <></>:
                <div className="form-floating mb-4">
                    <label htmlFor="Old-Password">Old Password</label>
                    <input type="text" className="form-control" id="Old-Password" placeholder="Old Password" required
                    onBlur={(e)=>details('OldPassword',e.target.value)}  />
                </div>}
                <div className="form-floating mb-4">
                    <label htmlFor="New-Password">New Password</label>
                    <input type="text" className="form-control" id="New-Password" placeholder="New Password"  required
                    onBlur={(e)=>details('NewPassword',e.target.value)}/>
                </div> 
                <div className="form-floating mb-4">
                    <label htmlFor="Conform-Password">Confirm Password</label>
                    <input type="text" className="form-control" id="Conform-Password" placeholder="Conform Password"  required
                    onBlur={(e)=>details('ConformPassword',e.target.value)}/>
                </div> 
                <div className=" ">
                <Box className='row' sx={{width:{ xs: "80%",sm:"80%",  md: "80%" }}}>
                    <Box sx={{width:{ xs: "50%",sm:"50%", md: "50%" },p:{ xs: 1, md:2 }}}>
                        <Link variant="button" to={`/profile`} type="button" className="btn btn-primary " >Go to profile</Link> 
                    </Box>
                    <Box sx={{width:{ xs: "50%",sm:"50%", md: "50%" },p:{ xs: 1, md:2 }}}>
                        <button type="submit" className="btn btn-primary mb-4">Change Password</button>
                    </Box>
                </Box>
                </div>
            </form> 
        </DivHover>
        </div>
     
    </Box>
    
    </>
  )
}
export  function LogOutHandler() {
    useEffect(() => {
        localStorage.removeItem('token-info'); 
            window.history.pushState({}, "", "/Sign" );
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
        // setTimeout(function() { window.location.href = '';}, 500);
    }); 

    return (<>logout successfully </>  )
  }

const LogsHandler = () => {
    const users=UseUserContext(); 


    useEffect(() => {

        if (localStorage.getItem("token-info") ==null) {}else{
            window.history.pushState({}, "", "/profile" );
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);
            // tokenInfo = localStorage.getItem('token-info');
        }   
    });
    

    
  const [after, setAfter] = useState(null);
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);

  const [newUser, setNewUser] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [conPassword, setConPassword] = useState(null);
  const [name, setName] = useState(null);
  const [number, setNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState(null);
//   const [message, setMessage] = useState(null);

    const [state, setState] = useState(false);

  function details(typ,value) { 
    switch (typ) {
        case "Name":
            setName(value);
            break;
        case "NewUser":
            setNewUser(value);
            break;
        case "NewPassword":
            setNewPassword(value);
            break;
        case "NewConPassword":
            setConPassword(value);
            break;
        case "Number":
            setNumber(value);
            break;
        case "Email":
            setEmail(value);
            break;
        case "Country":
            setCountry(value);
            break;
        // case "Message":
        //     setMessage(value);
        //     break;
        case "UserName":
            setUserName(value);
            break;
        case "Password":
            setPassword(value);
            break;
        default:
            break;
    }    
  }


    // console.log( userName+' -'+password);

    const apiHandel=async (payload,typ)=>{ 
        try{ 
            const res = await axios.post('https://www.digiinvite.in/api/user/index.php', payload);
            const dates =await res.data;
            typ!==3 ?users.dispatch({type:"MY_API_DATA",payload:dates}): console.log('');
            localStorage.setItem('login', true);

            const details =await dates.details;
            const result =await dates.result;
            result && typ!==4  ? localStorage.setItem('token-info', details[0]):setAfter(dates.errors);

            result && typ===4 ?sandHandel(details):console.log('');

            // console.log(dates);
          } catch(error){
            console.log('API_ERRORS');
          }
    }

    function sandHandel(details) {
        sandEmail("Request to reset your password ",details[0],details[1]);
        setAfter([details[2]]);

    }

    const signHandel=(e,typ)=>{
        e.preventDefault();
        const login=localStorage.getItem("login");
        const auth  = 'sarwara774h6g2v2jig5d55hf77ks';
        const payload = {keys: auth ,typ:typ, userName:`${userName}`,password:password,login:login,
        newUser:newUser,newPassword:newPassword,conPassword:conPassword,name:name,number:number,country:country,email:email };
        // console.log(typ+userName+password+newUser+newPassword+name+number+email);
        apiHandel(payload,typ);
    }

    function sandEmail(sub,user,link) {
        const userSplit=user.split('@');
        const userName=userSplit[0];
        console.log(userName);
        window.Email.send({
            SecureToken: '7aad3d6f-6761-4a20-a0bd-fae3ec305526',
            To : user,
            From : '"Team DigiInvite  "<team.digiinvite@gmail.com>',
            Subject : sub,
            Body : `<html lang="en"><body><div id=":16r" class="ii gt" jslog="20277; u014N:xr6bB; 1:WyIjdGhyZWFkLWY6MTc1OTQzMDY4MDQ3MjE1NDA4OCIsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsW11d; 4:WyIjbXNnLWY6MTc1OTQzMTY0MjAyMTk5NzQ3NyIsbnVsbCxbXV0."><div id=":16w" class="a3s aiL msg-8993890158622981485"><div class="adM"></div><div style="padding: 0; margin: 0; background: #ffffff"><div class="adM"></div><table style="height: 100%; width: 100%; background-color: rgb(255, 255, 255)" align="center" >
            <tbody><tr><td valign="top" style="text-align:center;padding:20px;box-sizing:border-box"><img src="https://ci4.googleusercontent.com/proxy/Y1LFosLbJYpzSJat99GZ04fS2fesUXHLoInHyLVha_HzXEhkossrnHtgOa7RVFkeUMGz6LBge5ErH3VryRKvLKY8Mi5rYN-g2yaZKBtPVkrz92UWBJNEi6_XR7hN4UsauOIM470nAzNICjN2Jy3eAiMxVaW-UuiJPtpU9GRKz0qNfZGh-XU=s0-d-e1-ft#https://api.smtprelay.co/userfile/d1e4381b-868f-4e4e-98d9-f2c8ecffd0fe/WhatsApp_Image_2023-03-04_at_4.14.58_PM.jpeg" alt="Image" style="border-width:0px;border-style:none;max-width:1280px;width:100%" width="1280" class="CToWUd a6T" data-bit="iit" tabindex="0">
            <div class="a6S" dir="ltr" style="opacity: 0.01; left: 359px; top: 107px;"><div id=":1a5" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button" tabindex="0" aria-label="Download attachment " jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB" data-tooltip-class="a1V" data-tooltip="Download"><div class="akn"><div class="aSK J-J5-Ji aYr"></div></div></div></div></td></tr><tr><td valign="top" id="m_-8993890158622981485dbody" style=" width: 100%; height: 100%; padding-top: 30px; padding-bottom: 30px; background-color: rgb(255, 255, 255); " >
            <table align="center" border="0" cellpadding="0" cellspacing="0" style=" border-color: rgb(255, 173, 25); max-width: 600px; box-sizing: border-box; width: 100%; margin: 0px auto; " ><tbody><tr><td valign="top" align="center" style=" background-color: rgb(255, 255, 255); box-sizing: border-box; font-size: 0px; text-align: center; " ><div class="m_-8993890158622981485layer_2" style=" max-width: 600px; display: inline-block; vertical-align: top; width: 100%; " ><table style="border-collapse: collapse; width: 100%" border="0" cellpadding="0" cellspacing="0" >
            <tbody><tr><td valign="top" style=" padding: 20px; text-align: left; color: rgb(95, 95, 95); font-size: 16px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; word-break: break-word; direction: ltr; box-sizing: border-box; " ><div style=" text-align: center; color: rgb(255, 173, 25); font-size: 28px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; " > Hi, ${userName}</div><p style=" text-align: center; margin: 0px; padding: 0px; " ><br /></p><p style=" text-align: center; margin: 0px; padding: 0px; " > 
            We're sending you this email because you requested a password reset.<br> Click on this link to create a new password</p><p style=" text-align: center; margin: 0px; padding: 0px; " ><br /></p><p style=" text-align: center; margin: 0px; padding: 0px; " > Please Set a New Password.</p></td></tr></tbody></table></div></td></tr><tr><td valign="top" align="center" style=" background-color: rgb(255, 255, 255); box-sizing: border-box; font-size: 0px; text-align: center; " ><div class="m_-8993890158622981485layer_2" style=" max-width: 600px; display: inline-block; vertical-align: top; width: 100%; " >
            <table border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse; width: 100%" ><tbody><tr><td valign="top" style="padding: 20px"><table cellspacing="0" cellpadding="0" style="text-align: center; margin: 0px auto" align="center" ><tbody><tr><td align="center" style=" background: rgb(255, 173, 25); border-radius: 4px; padding: 12px; " ><a href="http://digiinvite.in/Forgot/${link}" style=" word-break: break-word; color: rgb(255, 255, 255); font-size: 16px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: normal; text-decoration: none; display: inline-block; " target="_blank" >
            <span style="color: rgb(255, 255, 255)" >Reset Your Password !</span ></a ></td></tr></tbody></table></td></tr></tbody></table><table style="border-collapse: collapse; width: 100%" border="0" cellpadding="0" cellspacing="0" ><tbody><tr><td valign="top" style=" padding: 20px; text-align: left; color: rgb(95, 95, 95); font-size: 13px;direction: ltr; box-sizing: border-box ; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; word-break: break-word; "><p style=" text-align: center; margin: 0px; padding: 0px; "> If you didn't request a password reset,<br> you can ignore this email. your password will not be changed</p>
            </td></tr></tbody></table></div></td></tr><tr><td valign="top" align="center" style=" background-color: rgb(255, 255, 255); box-sizing: border-box; font-size: 0px; text-align: center; " ><div class="m_-8993890158622981485layer_2" style=" display: inline-block; vertical-align: top; width: 100%; max-width: 600px; " ><table border="0" cellspacing="0" style="border-collapse: collapse; width: 100%" ><tbody><tr><td valign="top" style="padding: 20px"><table align="center" style="margin: 0 auto" class="m_-8993890158622981485edsocialfollowcontainer" cellpadding="0" border="0" cellspacing="0" ><tbody><tr><td><table align="left" border="0" cellpadding="0" cellspacing="0" >
            <tbody><tr><td align="center" valign="middle" style="padding: 10px" ><a href="https://www.facebook.com/people/Digiinvite/100085685974325/" target="_blank" ><img src="https://ci6.googleusercontent.com/proxy/LfCbjZ_xRPuM4YTVdHgeI1kZxQdnJxI89k4zzeLFrGuJ4jOIbVHzciF7wFvNCZNIKiIe5gLEPron54kRVYD_pc_5i6B0VXI0GkgJcRAvy_H9Ex7_oz54f9wEYVAlj6jk29yoQcnPrOiUG2e2q1nJkQ=s0-d-e1-ft#https://api.etrck.com/userfile/a18de9fc-4724-42f2-b203-4992ceddc1de/ro_sol_co_32_facebook.png" style=" display: block; max-width: 32px; width: 32px; border: none; " alt="Facebook" class="CToWUd" data-bit="iit" /></a></td></tr></tbody></table><table align="left" border="0" cellpadding="0" cellspacing="0" >
            <tbody><tr><td align="center" valign="middle" style="padding: 10px" ><a href="https://twitter.com/DigiInvite" target="_blank" ><img src="https://ci4.googleusercontent.com/proxy/U7q500ytKm5uxXhui3iwrkjL12tAZu_ZrjfSCbebk5Va3dTg6JJ84RvipJuCT3PjjyMMR79v1cXqtAaZTQvnWt0RGGr0K9OdjNmOu6RV6tn3WAUQHAL7nGNcM3Fxz12PueIhpt2fEi6jDKjqEmYE=s0-d-e1-ft#https://api.etrck.com/userfile/a18de9fc-4724-42f2-b203-4992ceddc1de/ro_sol_co_32_twitter.png" style=" display: block; max-width: 32px; width: 32px; border: none; " alt="Twitter" class="CToWUd" data-bit="iit" /></a></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></div></td></tr><tr>
            <td valign="top" align="center" style="background-color: rgb(255, 255, 255); box-sizing: border-box; font-size: 0px; text-align: center; " ><div class="m_-8993890158622981485layer_2" style=" max-width: 600px; display: inline-block; vertical-align: top; width: 100%; " ><table style="border-collapse: collapse; width: 100%" border="0" cellpadding="0" cellspacing="0" ><tbody><tr><td valign="top" style=" padding: 10px; text-align: left; color: rgb(95, 95, 95); font-size: 16px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; word-break: break-word; direction: ltr; box-sizing: border-box; " ><p style=" text-align: center; margin: 0px; padding: 0px; " >
            <span style="font-size: 12px" >Team Digiinvite, India</span ></p></td></tr></tbody></table></div></td></tr><tr><td valign="top" align="center" style=" background-color: rgb(255, 255, 255); box-sizing: border-box; font-size: 0px; text-align: center; " ><div class="m_-8993890158622981485layer_2" style=" display: inline-block; vertical-align: top; width: 100%; max-width: 600px; " ><table border="0" cellspacing="0" style="border-collapse: collapse; width: 100%" ><tbody><tr><td valign="top" style=" padding: 20px; text-align: left; color: rgb(95, 95, 95); font-size: 16px; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif; word-break: break-word; direction: ltr; box-sizing: border-box; " >
            <p style=" text-align: center; font-size: 10px; margin: 0px; padding: 0px; " > If you no longer wish to receive mail from us, you can<a href="https://186W6.trk.elasticemail.com/tracking/unsubscribe?d=9s6OCLvGraZImiEs8lFOwgOFTmyYcSw6bPjJd4TKMooo-F4p5WT6YyYOcZ7uQKIgHsi87p2d0JxwrDL43FdJW3WAJ_oxuos9Ebp3YVZn-q6z0" style=" background-color: initial; font-size: 10px; color: rgb(255, 173, 25); text-decoration: none; " target="_blank" >Unsubscribe</a ><span class="im" ><br />Team Digiinvite,India</span ></p></td></tr></tbody></table></div></td></tr></tbody></table></td></tr></tbody></table></div></div></div></body></html>
            `
            // Body : `<html><h2>login Now and change Password </h2><strong>login now and change Password </strong>
            // <br></br><em>login Now and your password click on link -</em><a href='http://digiinvite.in/Forgot/${link}' target='_blank'>login now</a> </html>`
        }).then(
          message =>console.log(message)
        );
    }
    const forgotHandel=(user,typ)=>{
        const auth  = 'sarwara774h6g2v2jig5d55hf77ks';
        const login=localStorage.getItem("login");
        const payloads = {keys: auth ,typ:typ, userName:`${user}`,login:login};
        apiHandel(payloads,typ);
    }


    return (
        <div>
            <Banner name={`Sign In & Up`}/>
            <Box sx={{width:'100%',mt:5,mb:5,pt:2}} >
            <div className="container" id='mc_embed_signup'>
            <div className="row   justify-content-center" >
                {/* <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4"> style={{maxHeight:"84vh"}} */}
                <DivHover sx={{width: { xs: "90%", md: "50%" },mt:3}}>
                    {after&&after.map((er,i)=><p key={i} style={{color:'red',textAlign:'center'}}>{er}</p>)}
                    {state&&state? 
                    <div id="signUp_page" className="">
                        <div className=" ">
                             
                            <h3 >Sign Up</h3>
                        </div>
                        {/* 
                            // 69E86C8BA08E6EF31A03EE737AB89D441449DE47D225692F5CD57A76F4CCB316E82B9BEBFC43E33C5A48AB994F2BEB76
                            // 7aad3d6f-6761-4a20-a0bd-fae3ec305526
                            // e7084f09-bc7d-46e5-b190-5068bb633dc6
                            // https://smtpjs.com/
                        <div id="form_errors" className="alert alert-danger fade in" style="display:none"></div> */}
                            {/* <a href='http://digiinvite.in/Forgot/id' target='_blank'>login now</a> */}
                            {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
                        {/* <!-- <div   className=" alert_placeholder" ></div> --> */}
                        <form  className='form-horizontal' onSubmit={(e)=>signHandel(e,2)}>
                        <div className="form-floating mb-4">
                            <label htmlFor="User">Your Name</label>
                            <input type="text" className="form-control" id="User" placeholder="your name" required
                            onBlur={(e)=>details('Name',e.target.value)}/>
                        </div>
                        <div className="form-floating mb-4">
                            <label htmlFor="Username">User Name</label>
                            <input type="text" className="form-control" id="Username" placeholder="User Name"  required
                            onBlur={(e)=>details('NewUser',e.target.value)}/>
                        </div> 

                        <div className="form-floating mb-4">
                            <label htmlFor="email">Email </label>
                            <input type="email" className="form-control" id="email" placeholder="name@example.com"  required
                            onBlur={(e)=>details('Email',e.target.value)}/>
                        </div>

                        <div className="form-floating mb-4">
                            <label htmlFor="mobile">Mobile </label>
                            <input type="text" className="form-control" id="Country" placeholder="Country Code" 
                            onBlur={(e)=>details('Country',e.target.value)} style={{width:'15%'}}/>
                            <input type="number" className="form-control" id="mobile" placeholder="your mobile" maxLength={10} required
                            onBlur={(e)=>details('Number',e.target.value)} style={{width:'60%'}}/>
                        </div>

                        <div className="form-floating mb-4">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Password" required
                            onBlur={(e)=>details('NewPassword',e.target.value)}/>
                        </div>
                        
                        <div className="form-floating mb-4">
                            <label htmlFor="password">Conform Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Conform Password" required
                            onBlur={(e)=>details('NewConPassword',e.target.value)}/>
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                            </div> 
                        </div> */}
                        <p className="text-center mb-0">Already have an Account? <span onClick={()=>setState(false)}>Sign In</span></p>
                        {/* {after&&after.map((er,i)=><p key={i} style={{color:'red'}}>{er}</p>)} */}
                        <button type="submit" className="btn btn-primary py-3 w-100 mb-4"    >Sign Up</button>
                        </form>
                    </div>
                    :
                    <div id="signIn_page" className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            {/* <a href="index.php" className="">
                                <h3 className="text-primary"><i className="fa fa-hashtag me-2"></i>WEBSITE</h3>
                            </a> */}
                            <h3>Sign In</h3>
                        </div>
                        <form id='contact_form' className='form-horizontal' onSubmit={(e)=>signHandel(e,1)}>
                            
                        <div id="form_errors" className="alert alert-danger fade in" style={{display:"none"}}></div>
                        {/* <!-- <div  className=" alert_placeholder fixed-bottom" ></div> --> */}
                        <div className="form-floating mb-4 ">
                            <label htmlFor="floatingUser " className='col-3'>User Name </label>
                            <input type="text" className="form-control" id="floatingUser" placeholder="User name or email address " 
                            // onChange={(e)=>details('UserName',e.target.value)}
                            required
                             onBlur={(e)=>details('UserName',e.target.value)} />
                        </div>
                        <div className="form-floating mb-4 ">
                            <label htmlFor="floatingPassword" className='col-3'>Password</label>
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" required
                            onBlur={(e)=>details('Password',e.target.value)}/>
                        </div> 
                        
                        {/* {after&&after.map((er,i)=><p key={i} style={{color:'red'}}>{er}</p>)} */}
                        <div className=''>
                            <p className="text-center mb-4">if you Forgotten password?  <span  className=" btn-primary  mb-4" onClick={()=>forgotHandel(userName,4)}  >Forgot password</span></p>
                            {/* Your security token is e7084f09-bc7d-46e5-b190-5068bb633dc6 Please take note of this. e7084f09-bc7d-46e5-b190-5068bb633dc6  */}
                            <button type="submit" className="btn btn-primary mb-4"   >Sign In</button>
                        </div>
                        <hr />
                        <p className="text-center mb-4">Don't have an Account?
                         {/* <span onClick={()=>setState(true)}>Sign Up</span> */}</p>
                        <button className="btn btn-primary mb-4"  onClick={()=>setState(true)}>Sign Up</button>
                        </form>
                    </div>
                    }
                </DivHover>
            </div>
        </div>
            </Box>
        </div>
    );
}

export default LogsHandler;
