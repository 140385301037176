// import React from 'react';

// import { UseProContext } from '../context/productContext';

const cartReducer = (state, action) => {
// const values=UseProContext();
  const product =action.pro;

//   const data =action.data;
    // if (action.type === "SET_LOADING") {
    //      return {...state,isLoading:true,};
    // }
        function filterByID(item,count,cart) {
            const fPro = [];
            // console.log(item);
            // const fCount = {'count':count};
            // eslint-disable-next-line array-callback-return
            product.map(cate =>cate.category.map((sub,i)=>sub.products.map((pro,index,arr) => {
                // const counts=parseInt(item);
                if(+pro.pro_id===+item){ 
                    pro.count = count; 
                    let merged = {...pro, ...cart};
                    fPro.push(merged); 
                }
               
            }))); 
            return fPro;
        }
        function Storage(StorPro_id) {
            const AddProId = StorPro_id.split(",");
            const count = [];
                AddProId.forEach(element => {
                    if(element>0){count[element] = (count[element] || 0) + 1;}
                });
                const cartCount = Object.entries(count);
                // ,cartCount[cartCount.length-1][0]{"id": "","typ": "","logo": 0,"Language": 0,"Version": 0}
            const ProRes = [];
                cartCount.forEach(cart => { 
                    let obj={"id": "","typ": "","logo": 0,"Language": 0,"Version": 0};
                    const result =filterByID(cart[0],cart[1],obj); 
                    ProRes.push(...result);
                });

            return ProRes;
        }

        function StorageData(arr) {
            // const ProRes =  arr.map(cart => filterByID(cart.id,1)); 
            const ProRes = [];
            if(arr){
                arr.forEach(cart => { 
                    const result =filterByID(cart.id,1,cart);
                    ProRes.push(...result);
                }); 
            }
            return ProRes;
        }


    switch (action.type) {
   
        case "MY_CART_DATA": 
        // count:cartCount,
            // const fProRes =Storage(action.payload); 
            const fDataStore =StorageData(action.data);

        return ({...state,cart:fDataStore,isLoading:false,});

        case "ADD_CART_DATA":
            
            // const ProRes =Storage(action.payload); 
            // console.log(ProRes);
            const dataStore =StorageData(action.data); 
            // console.log(dataStore);

        return ({...state,cart:dataStore,isLoading:false,});

        case "MY_WISHLIST_DATA":
            const fWishlistRes =Storage(action.payload); 
        return ({...state,wishlist:fWishlistRes,isLoading:false,});

        case "ADD_WISHLIST_DATA":
            const WishlistRes =Storage(action.payload); 
        return ({...state,wishlist:WishlistRes,isLoading:false,});
        
        case "REMOVE_CART_DATA":
        return ({...state,cart:[],isLoading:false,});

        default: return (state);
    };
    // return (state);

    // return ({...state,isLoading:true,products:action.payload,});
   
}

export default cartReducer;
