import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import {stereo} from '../comp/data';
import { styled } from '@mui/material/styles';
import Slider from "react-slick";


const stereo = [  
  {  
      id: 1,  
      title: 'Step 1',
      subtitle: 'Select the E-Card',
      url: 'https://www.digiinvite.in/ceo/assets/images/1.png',
  },
  {  
      id: 2,  
      title: 'Step 2',
      subtitle: 'Add to Cart',
      url: 'https://www.digiinvite.in/ceo/assets/images/2.png',

  },
  {  
      id: 3,  
      title: 'Step 3',
      subtitle: 'Provide Your Details',
      url: 'https://www.digiinvite.in/ceo/assets/images/3.png',

  },
  {  
      id: 4,  
      title: 'Step 4',
      subtitle: 'Make Payment',
      url: 'https://www.digiinvite.in/ceo/assets/images/4.png?fit=64%2C64&ssl=1',

  },
  {  
      id: 5,  
      title: 'Step 5',
      subtitle: 'Get your E-Card within 1-2 working days.',
      url: 'https://www.digiinvite.in/ceo/assets/images/5.png?fit=64%2C64&ssl=1',

  }
];



const StereoStep = () => {
    const StereoStyle = styled(Box)(({ theme }) => ({ 
        "& .slick-next":{opacity:'0',display:'none', }, 
        "& .slick-prev":{opacity:'0',display:'none',}, 
    }));
    const HoverStyle = styled(Box)(({ theme }) => ({
         //background:'red',filter: `drop-shadow(0.5rem 0.5rem 0.5rem black)`,filter: `drop-shadow(1rem 1rem 1rem black)`,
        "& img":{transition: `0.5s`,},
        '&:hover': {
            ' & img': {transform:`scale(0.9)`,},
        },
    }));
    var settings = {
        // dots: false,
        // infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                autoplaySpeed: 3000,
                // dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplaySpeed: 800,
              }
            }
          ]
    };
        // <MenuItem key={stereo.id} sx={{}} >
        // <Box  sx={{display: 'flex',justifyContent:'space-evenly',alignItems:'center',height:'10rem', }}>
        // </Box>
        // </MenuItem>
    return (
        <StereoStyle>
        <Slider {...settings}  >
            {stereo && stereo.map((stereo,index) => (
              <HoverStyle className='hovers' key={index} >
                <Box sx={{display: 'flex',alignItems:'center',flexDirection:'column',}}>
                <figure style={{textAlign:'center'}}><img src={stereo.url} alt='' width="80px" /></figure>
                <Typography textAlign="center" sx={{fontSize:{xs:'14px',md:'20px'},fontWeight:700,}}>{stereo.title}</Typography>
                <Typography textAlign="center" sx={{fontSize:{ xs: '12px', md: '14px' },}}>{stereo.subtitle}</Typography>
                </Box>
              </HoverStyle> 
            ))}
        </Slider>
         </StereoStyle>
    );
}

export default StereoStep;
