import React from 'react'
import Box from '@mui/material/Box'; 

export default function NoPage() {
  return (
    <>
      <Box sx={{width:'100%'}} >
        <Box  className="container" >
          Sorry this page currently not available 
        </Box>
    </Box>
    </>
  )
}
