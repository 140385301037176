import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
// import { pages } from "../../comp/data";
// import { UseProContext } from "../../context/productContext";
import Slider from "react-slick"; 
import Products from "../../comp/Products";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Features = ({feature,isLoading}) => {
  // const values = UseProContext();
  //   const product =values.products;
  // const feature = values.featureProducts;
  //   console.log(feature[0]);
  const pages = ["Featured", "Latest", "bestseller"];
  const [state, setState] = useState(0);

  function handelState(id) {
    setState(id);
  }

  var settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true, 
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      } 
    ]
  };
  return (
    <>
      <Box sx={{ mt: 3 }} className="product-tab-item">
        <Box sx={{ mt: 1 }} className="pro-tab">
          <h1 className="heading " style={{ float: "left", margin: "-8px 0" }}>
            <span>Top Templates</span>
          </h1>
          <Box
            component="ul"
            sx={{ textAlign: "right", padding: "2px 70px" }}
            className=" nav nav-tabs"
          >
            {pages.map((item, i) => (
              <Box key={i}
                component="li"
                className={state === i ? "nav-item active" : "nav-item"}
              >
                <Box
                  component="a"
                  className={i === 1 ? "tab-cen " : ""}
                  onClick={() => handelState(i)}
                >
                  {item}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box>
        {isLoading?
        <Box sx={{display:'flex',flexWrap:'wrap', justifyContent:'space-between'}}>
        <Stack spacing={1} sx={{width:{xs: '48%', md:'23%' },}}> 
          <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 200, md:332 },borderRadius:'8px'}} />
        </Stack>
        <Stack spacing={1} sx={{width:{xs: '48%', md:'23%' },}}>
          <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 200, md:332 },borderRadius:'8px'}} /> 
        </Stack>
        <Stack spacing={1} sx={{width:'23%',display:{xs: 'none', md:'flex'}}}>
          <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 },borderRadius:'8px'}} /> 
        </Stack>
        <Stack spacing={1} sx={{width:'23%',display:{xs: 'none', md:'flex'}}}>
          <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 },borderRadius:'8px'}} /> 
        </Stack>
        </Box>
        :<Slider {...settings}>

          {feature[state]?feature[state].pro.map((pro,i) => 
          i%2===0?"":
              <Box key={pro.code} sx={{p:1}}>
                {i>1?<Box sx={{p:1}}> <Products pro={feature[state].pro[i-1]} /></Box>:
                <Box sx={{p:1}}><Products pro={feature[state].pro[0]} /> </Box>}
                  <Box sx={{p:1}}><Products pro={pro} /> </Box>
              </Box>
              
          ):""}
        </Slider>}


          {/* {feature.map((page, i) => i===state ? page.pro.map((pro) => 
              <Box key={pro.code} sx={{p:3}}>
                  <Products pro={pro} />
              </Box>
          ):'')} */}
            {/* <Box>n2</Box>
            <Box>n3</Box>
            <Box>n4</Box>   */}
          {/* <Box>n1</Box> */}
        {/* {state === 0 ? <>ok</> : <><Box>no ok</Box></>} */}
          {/* {state===1?<>2 </>:<></>}
              {state===2?<>3 </>:<></>} */}
      </Box>
    </>
  );
};

// const Product = ({pro}) => {
//   const{code,pro_title,img,price,m_price}=pro;
//    const offPrice=m_price/1;
   
//   console.log(offPrice+" "+price);
//   return (
//     <div>
//       <div className="col-xs-12 item-row   " >
//         <div className="product-container">
//           <div className="imgblock">
//             <a href="/products/ornare-sed-tincidunt-non-feugiat-non">
//               <img
//                 className="img-responsive center-block"
//                 src={img[0].url&&img[0].url}
//                 alt="ornare sed tincidunt non, feugiat non"
//               />
//             </a>
//             <a href="/products/ornare-sed-tincidunt-non-feugiat-non">
//               <img
//                 src={img[0].url&&img[0].url}
//                 className="second-img featured-image img-responsive center-block"
//                 alt="ornare sed tincidunt non, feugiat non"
//               />{" "}
//             </a>
 
//           </div>
//           {offPrice===price? "":<span class="sale">sale</span>}
//           <div className="wbproductdes text-center">
//             <div className="rateop">
//               <span  className="spr-badge"  data-rating="5.0" >
//                 <span className="spr-starrating spr-badge-starrating">
//                   <i className="spr-icon spr-icon-star" aria-hidden="true"></i>
//                   <i className="spr-icon spr-icon-star" aria-hidden="true"></i>
//                   <i className="spr-icon spr-icon-star" aria-hidden="true"></i>
//                   <i className="spr-icon spr-icon-star" aria-hidden="true"></i>
//                   <i className="spr-icon spr-icon-star" aria-hidden="true"></i>
//                 </span>
//                 <span className="spr-badge-caption">1 review</span>
//               </span>
//             </div>
//             <h5>
//               <a
//                 href="/collections/frontpage/products/ornare-sed-tincidunt-non-feugiat-non"
//                 className="grid-link__title"
//               > {pro_title} || {code}
//               </a>
//             </h5>
//             <p className="thumbdes">{pro_title} 
//             </p>
//             <div className="product_price">
//               <span className="grid-link__org_price">
//                 <span
//                   className="Bold-theme-hook-DO-NOT-DELETE bold_product_price"
//                   data-override-value-set="1"
//                   data-override-value="15500"
//                   style={{display:"none !important"}}
//                 ></span>
//                 <span
//                   className="money"
//                   data-currency-usd="$155.00 USD"
//                   data-original-value="15500"
//                   data-inr="Rs. 12,694.55"
//                 >
//                   <FormatPrice price={price}/>
//                    {price===offPrice? <></> : <span><del><FormatPrice price={offPrice}/> </del></span>}
//                   Rs. {price} 
//                 </span>
//               </span>
//             </div>

//             <div className="button-group">
//               <div className="absbtn">
//                 <div className="addcart">
//                   <form
//                     action="/cart/add"
//                     method="post"
//                     className="variants clearfix"
//                     id="cart-form-6195390808254"
//                   >
//                     <input type="hidden" name="id" value="37973343273150" />
                    
//                   </form>
//                   <a className="add-cart-btn" href="/#" > 
//                     <AddShoppingCartIcon />
//                   </a>
                      
//                 </div>

//                 <div className="add-to-wishlist">
//                   <div className="show">
//                     <div className="default-wishbutton-ornare-sed-tincidunt-non-feugiat-non loading">
//                       <a href="/#">
//                          <StarBorderIcon/>
//                       </a>
//                     </div>
//                     <div
//                       className="loadding-wishbutton-ornare-sed-tincidunt-non-feugiat-non loading"
//                       style={{display:" none",pointerEvents:"none"}}
//                     >
//                       <a
//                         className="add_to_wishlist"
//                         href="ornare-sed-tincidunt-non-feugiat-non"
//                         title="Wishlist"
//                       >
//                         <i className="fa fa-circle-o-notch fa-spin"></i>
//                       </a>
//                     </div>
//                     <div
//                       className="added-wishbutton-ornare-sed-tincidunt-non-feugiat-non loading"
//                       style={{display:"none"}}
//                     >
//                       <a
//                         className="added-wishlist add_to_wishlist"
//                         href="/pages/wishlist"
//                         title="Wishlist"
//                       >
//                          <StarBorderIcon/>
//                       </a>
//                     </div>
//                   </div>
//                 </div>

//                 <a href="/#" className="quick-view-text">
//                   <VisibilityIcon/>
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Features;
