import  React, { createContext,useContext,useEffect,useReducer } from 'react';
import axios from 'axios';
import reducer from '../reducer/proReducer';
// import { type } from '@testing-library/user-event/dist/type';useState
// import { ConnectWithoutContactSharp } from '@mui/icons-material';
const AppContext= createContext();

// const API = "http://ceo.digiinvite.in/api/";
const API = "https://www.digiinvite.in/api/";

const initial={
  isLoading:true,
  isError:false,
  products:[],
  pro:[],
  proTeg:[],
  banners:[],
  reviews:[],
  category:[],
  subcategory:[],
  featureProducts:[],
};

const AppProvider = ({children}) => {

  const [state, dispatch] = useReducer(reducer, initial)
  // const [value, setValue] = useState('Karmveer');

  const getData= async (url)=>{
    dispatch({type:"SET_LOADING"});
    try{
      const res = await axios.get(url);
    const dates =await res.data;
    // console.log(dates);
    dispatch({type:"MY_API_DATA",payload:dates});
    // setValue(data);
    } catch(error){
      dispatch({type:"API_ERROR"});
    }
    
  };

  // localStorage.setItem('currency', 'en-IN,INR');
  
  useEffect(() => {
    getData(API);
  }, []);

    // console.log(value);
  
  return (<AppContext.Provider value={{ ...state}}>{children}</AppContext.Provider>);
};

const UseProContext = () => {
  return useContext(AppContext);
};


export {API,AppProvider, AppContext, UseProContext};