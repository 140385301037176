import React from "react";
// import  { useState, useRef, useEffect } from 'react';
import { Box } from "@mui/material";
import { UseProContext } from "../../context/productContext";
import { UseCartContext } from '../../context/CartContext';
// import Timer from "../../comp/timeDown";
import Countdown from 'react-countdown';
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import FormatPrice from "../../comp/FormatPrice";
import Slider from "react-slick";
import Offer_Tag from '../../img/Offer_Tag.png';
import Tooltip from '@mui/material/Tooltip';

export default function Deals({feature}) {


  var settings = { 
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      } 
    ]
  }

  return (
    <>
      <Slider {...settings}>
        {feature.pro.map((deal,i)=>deal.time>0?
          <Box key={i} className="product-container" sx={{overflow:'hidden'}} >
             <Product pro={deal} />
          </Box>:'')
        }
      </Slider>


      {/* <Slider {...settings}>
        {feature
          ? feature.pro.map((pro) => (
              <Box key={pro.code} className="product-container" sx={{overflow:'hidden'}} >
                <Product pro={pro} />
              </Box>
            ))
          : ""}
      </Slider> */}
    </>
  );
}

const Product = ({ pro }) => {
  const values=UseProContext();
  const product =values.products;
  // console.log(pro);
  const { code, pro_title,pro_dis,pro_id,img, price, m_price, offer,time } = pro;
  // const offPrice = m_price / 1;
  const href=`/product/${code}`;

 
  
  const img1=img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;
  // const img2=img.length>1?`${img[1].url}`:img.length>0?`${img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`;
  const OfferImg={ width: '80%',};
  const OfferStyle ={
    animation: 'bounce 5s linear 2s infinite alternate',
    width: '35%',display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color:'white',fontWeight: 700, 
    position: 'absolute',
    right: -10,bottom:0, 
    textAlign:'center',};
 

  const {setCarts}=UseCartContext(); 

  function handleAddProduct(productId) {
    // const AddProId=[];
    // AddProId.push(productId);
    // const saveProId =localStorage.getItem('pro_id');
    // AddProId.push(saveProId);
    // localStorage.setItem('pro_id', AddProId);
    // setCarts({type:"ADD_CART_DATA",payload:localStorage.pro_id,pro:product});

    let options={"id": productId,"typ": "","logo": 0,"Language": 0,"Version": 0,"Caricature":0,"Extra":0,};
    if (localStorage.getItem("pro_form") ===null || localStorage.getItem("pro_form") ==='' ) {
      const option=JSON.stringify([options]);
      localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:[options]});
    }else{
      const savePro =localStorage.getItem('pro_form');
      const Pro=JSON.parse(savePro);
      const cards=[options,...Pro];
        const unique = [...new Map(cards.map(item =>[item['id'], item])).values()]
        // console.log(unique);
        const option=JSON.stringify(unique);
        localStorage.setItem('pro_form', option);
      setCarts({type:"ADD_CART_DATA",pro:product,data:unique});
    }

  }

  function handleBuyProduct(productId) {
    handleAddProduct(productId);
    window.history.pushState({}, "", "/carts");
    const navEvent = new PopStateEvent('popstate');
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    window.dispatchEvent(navEvent);

  }
  function handleAddWish(productId) {
    const AddProId=[];
    AddProId.push(productId);
    const saveProId =localStorage.getItem('wish_id');
    AddProId.push(saveProId);
    localStorage.setItem('wish_id', AddProId);
    setCarts({type:"MY_WISHLIST_DATA",payload:localStorage.wish_id,pro:product});
  }

  const Completions = () => <span> Oh! No offer closed !</span>;
  
  const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) { 
      return <Completions />;
    } else { 
      return (
        <>
        <h4>Hurry up! Limited Time Offer:</h4> 
        <div className="timer lof-clock-6193885872318-detail">
          <ul className="list-inline">
            <li className="day">
              <span>
                <b>{days}</b>
              </span>
              <span>Days</span>
            </li>
            <li className="hours">
              <span>
                <b>{hours}</b>
              </span>
              <span>Hours</span>
            </li>
            <li className="mins">
              <span>
                <b>{minutes}</b>
              </span>
              <span>Minutes</span>
            </li>
            <li className="secs">
              <span>
                <b>{seconds}</b>
              </span>
              <span>Secs</span>
            </li>
          </ul>
        </div>
        <div className="button-group">
          <div className="add-to-wishlist">
            <div className="show"> 
            <Tooltip title="Add To Card " >
              <Box component="a" className="add-cart-btn" onClick={()=>handleAddProduct(pro_id)} >
                  <AddShoppingCartIcon />
              </Box> 
            </Tooltip>
            </div>
          </div>
          <div className="quick-view-text">
            <div className="show"> 
            <Tooltip title="Add To Wish List " >
              <Box component="a" className="add-wish-btn" onClick={()=>handleAddWish(pro_id)} >
                  <StarBorderIcon />
              </Box> 
            </Tooltip>
              
            </div>
          </div>
          <div className="addcart">
          <Tooltip title="Buy Now">
            <Box component="a" className="add-check-btn" onClick={()=>handleBuyProduct(pro_id)} >
              <ShoppingCartCheckoutIcon />
            </Box>
          </Tooltip>
            
          </div>

        </div>

        </>
      );
    }
  };
  return (
    <Box sx={{ display: { xs: "block", md: "flex" }  }}>
      <Box className="imgblock " sx={{ width: { xs: "100%", md: "25%" },p:3 }}>
        <a href={href}>
          <img className="img-responsive center-block"
            src={img1} alt={pro_title}/>
        </a>
        {/* <a href={href}  >
          <img  src={img2} alt={pro_title}
            className="second-img featured-image img-responsive center-block"/> 
        </a> */}

        <div style={OfferStyle}   >
          <img src={Offer_Tag} alt=""  style={OfferImg} className=' zoomBounce '  />
          <span style={{position: 'absolute',fontSize: 'larger',fontFamily: 'Ubuntu',}}>{offer}</span>
        </div>
      </Box>
      <Box className="wbproductdes text-left " sx={{ width: { xs: "100%", md: "75%" },p:3 }}>

        <h5>
          <a href={href}> {pro_title} || {code}</a>
        </h5>
        <div className="product_price">
          <span className="grid-link__org_price"><FormatPrice price={price} /></span>
          {+m_price=== +price?"":<>
          <span className="dis-price">{" "} <del> <FormatPrice price={m_price} /></del></span>
          <span>{offer}</span></>}
        </div>
        <p className="thumbdes">{pro_dis}</p>
        
        <hr />
        <Countdown date={Date.now() + time*1000} renderer={renderer}/>
 

      </Box>


      

          {/* <div className="addcart">
            <span className="hidden-lg hidden-md hidden-sm hidden-xs">
            <Box component="a" className="add-cart-btn" onClick={()=>handleBuyProduct(pro_id)} >
              <AddShoppingCartIcon /> 
            </Box>  
            </span> 
          </div> */}

          {/* <div className="add-to-wishlist">
            <div className="show">
              <div className="default-consectetur-adipiscing-elit-2 loading">
              <Box component="a" className="add-cart-btn" onClick={()=>handleAddProduct(pro_id)} >
                <StarBorderIcon />
              </Box> 
              </div>
              <div
                className="consectetur-adipiscing-elit-2 loading"
                style={{ display: " none", pointerEvents: "none" }}
              >
                <a href="/#">
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                </a>
              </div>
               
            </div>
          </div> */}

          {/* <a  href={href} >
            <VisibilityIcon />
          </a> */}

    </Box>
  );
};
