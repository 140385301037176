import React, { useState, useEffect } from "react";
// import { FaAngleUp } from "react-icons/fa";
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import "./index.css";
// import { Box } from "@mui/material";
const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="top-to-btm">
            {" "}
            {showTopBtn && (<>
                <div id="toTop" onClick={goToTop}>
                   <span id="toTopHover" style={{opacity:"0"}}>
                        </span><i className="fa fa-angle-up" style={{fontSize:'xx-large'}}></i>
                </div>
            </>
            )}{" "}
        </div>
    );
};
export default ScrollToTop;
