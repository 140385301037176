import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom'
// import {product} from '../comp/data';
import Review from './homes/review'; 
// import Helmet from "react-helmet";
// import { useRadioGroup } from '@mui/material/RadioGroup';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import Skeleton from '@mui/material/Skeleton';
import Fab from '@mui/material/Fab';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import StoreIcon from '@mui/icons-material/Store';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import SimpleImageSlider from "react-simple-image-slider";
// import YouTubeIcon from '@mui/icons-material/YouTube';
import YouTubeIcon from '../img/YouTubeIcon.png'; 
// import Tooltip from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
// import FormControl from '@mui/material/FormControl';
import { FormLabel } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormatPrice from '../comp/FormatPrice';
import Slider from "react-slick";
import Modal from '@mui/material/Modal';
import YouTube from 'react-youtube';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import ReactImageZoom from 'react-image-zoom';
import { UseProContext } from '../context/productContext';
// import { UseCartContext } from '../context/CartContext';
import { styled } from '@mui/material/styles';

// import Helmet from "react-helmet";

const BoxStyle = styled(Box)(({ theme }) => ({ 
    // padding:{ xs: '0', md: '0 50px 0 0'},
    "& .slick-next":{opacity:'0',display:'none', }, 
    "& .slick-prev":{opacity:'0',display:'none',}, 
    
}));

export default function Product() {
  const values=UseProContext();
  const product =values.products;
  const products =values.pro;
  let { pro } = useParams();

  const [image, setImage] = useState(0);
  const [options, setOptions] = useState({"id": 0,"typ": "","logo": 0,"Language": 0,"Version": 0,"Caricature":0,"Extra":0,});
  // const {setCarts}=UseCartContext();
  // const [price, setPrice] = useState(0);
  // console.log(price,options);
  // useEffect(() => {
  //   setPrice(options.logo+options.Language+options.Version+options.Caricature+options.Extra);
  // },[options])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    products.map((product)=>pro===product.code? 
      (document.title = `India's Best Digital Invitation E Card Maker - Animated Invitation Maker - Digi Invite.  ${product.pro_title} `) 
    :'');
    products.map((product)=>pro===product.code? 
      (document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content',`${product.img[0]&&product.img[0]?`${product.img[0].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`}`)) 
    :'');
  }, [products, pro]);
  
  // function handleAddProduct(productId) {
  //     // const AddProId=[];
  //     // AddProId.push(productId);
  //     // const saveProId =localStorage.getItem('pro_id');
  //     // AddProId.push(saveProId);
  //     // localStorage.setItem('pro_id', AddProId);
      
  //     if (localStorage.getItem("pro_form") ===null || localStorage.getItem("pro_form") ==='' ) {
  //       const option=JSON.stringify([options]);
  //       localStorage.setItem('pro_form', option);
  //       setCarts({type:"ADD_CART_DATA",pro:product,data:[options]});
  //     }else{
  //       const savePro =localStorage.getItem('pro_form');
  //       const Pro=JSON.parse(savePro); 
  //       const cards=[...Pro,options];
  //         const unique = [...new Map(cards.map(item =>[item['id'], item])).values()]
  //         const option=JSON.stringify(unique);
  //         localStorage.setItem('pro_form', option);
  //       setCarts({type:"ADD_CART_DATA",pro:product,data:unique});
  //     }
  // }
   

  // function handleBuyProduct(productId) {
  //   handleAddProduct(productId);
  //   window.history.pushState({}, "", "/carts");
  //   const navEvent = new PopStateEvent('popstate');
  //   window.dispatchEvent(navEvent);
  // }
  const opts = {height: '315',width: '560',playerVars: {autoplay: 1,},};
  const onReady=(event)=> {
    event.target.pauseVideo();
  }
  
  
  // const propHover = {width: 400, height: 250, zoomWidth: 500, img: "1.jpg"};
  const videoStyle ={color:'green',fontWeight: 700, position: 'absolute',width: '35%',right: 0,textAlign: 'center',bottom: '20px'};
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    
  };
  
      // position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',
                // width: { xs: '100%', md: '100%' },// justifyContent:'center',style={{background: '#abb8c3',}}
  return (
    <BoxStyle className="container" >
      {/* <Helmet>
                <title> - Digi Invite</title>
                <meta name="description" content='{item.pro_dis}'/>
                <meta property="og:image" content={`https://www.digiinvite.in/ceo/assets/images/pro.png`} />
              </Helmet> */}
    <Box sx={{display: { xs: 'block', md: 'flex' },flexWrap:'wrap', }} >
      {/* <div>Product code = {pro}</div> */}
        {product.map((page) => (
        <React.Fragment key={page.id}>
        {page.category && page.category.map((cate) => (
          <React.Fragment key={cate.id}> 
          {cate.products && cate.products.map((item) => (
            <React.Fragment key={item.pro_id}>
            {pro===item.code ? 
              <>
              {/* <Helmet>
                <title>{item.pro_title} - Digi Invite</title>
                <meta name="description" content={item.pro_dis}/>
                <meta property="og:image" content={item.img[0]&&item.img[0]?`${item.img[image].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`} />
              </Helmet> */}
              <Box  sx={{width:'100%',bgcolor: 'background.paper',p: '30px 0 ',
                  display: 'flex',justifyContent:'start',}} onLoad={()=>console.log('ok')} >
                    <Typography sx={{fontWeight: 600,fontSize:'1rem',color:'#3e445a'}} >
                      Home / {page.title} / {cate.titles}
                    </Typography>
              </Box>
              <Box sx={{display: { xs: 'block', md: 'flex' },flexWrap:'wrap',width:'100%',
                bgcolor: '#f5f5f5',p:2,borderRadius:'6px' }} >
                <Box sx={{width:{ xs: '100%', md: '100%' },p:{ xs:2,md:5},}}>
                  <Typography sx={{fontWeight: 600,fontSize:{ xs: '1rem', md: '1.5rem' },  }} className="sales">
                      {item.pro_title} 
                  </Typography>
                  <Typography sx={{fontWeight: 600,fontSize:'1rem',}} >Code: <span style={{color:'#9b9bb4'}}>{item.code}</span> </Typography>
                </Box>
                <Box sx={{width:{ xs: '100%', md: '40%' },display: 'flex',justifyContent:'center',flexWrap:'wrap',}}>
                  <Box sx={{display: 'flex',width:'90%',justifyContent:'center',flexWrap:'wrap',p:'0 5% '}}>
                    <Box  sx={{width :'100%', position:'relative',}}>
                      {/*  onMouseMove={(e)=>handelHover(e)} <ImageZoom url={item.img[0]&&item.img[0]?`${item.img[image].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`} /> */}
                      <figure >
                        {item.video&&item.video.length>0? <span style={videoStyle}>
                          <Button onClick={handleOpen} sx={{color:'black',}} >
                            <img src={YouTubeIcon} alt="" />
                            {/* <YouTubeIcon sx={{color:'red',fontSize:{xs:'16px',md:'26px'}}} />Watch Video */}
                          </Button>
                        </span>:''}

                        <img src={item.img[0]&&item.img[0]?`${item.img[image].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`}
                          alt={item.pro_title} width='100%' id='main-img' />
                          
                          {+item.price===+item.m_price? <></> : <div className='sale'>{item.offer}</div>}
                          {/*  <Box sx={{position: 'absolute',top:0,maxHeight: '100vh',maxWidth: '100vh',}}>
                            <Box sx={{ position: 'absolute',left:0,top:0,width: 400,height:300, pointerEvents: 'none',opacity: 0.5,transition: 'opacity 0.2s',
                                    background:`url("${item.img[0]&&item.img[image]?`${item.img[2].url}`:`https://www.digiinvite.in/ceo/assets/images/pro.png`}") no-repeat #fff`,
                                    backgroundPositionX:`${hover[0]}%`,backgroundPositionY:`${hover[1]}%`,backgroundSize:'cover'}}>ok
                                    </Box>
                          </Box>*/}
                      </figure>
                      <Box sx={{textAlign:'center',m:2}}>
                      {item.pdf&&item.pdf.length>0?<Button sx={{color:'white',background:'red'}} href={item.pdf[0].url} target={`_blank`} ><PictureAsPdfIcon /> View PDF</Button>:''}
                      </Box>
                      <Box  sx={{width :'80%',m:'auto'}}>
                        {item.img&&item.img.length<3?
                        <Box sx={{display:'flex',justifyContent:'space-evenly'}}>
                        {item.img&& item.img.map((img ,index) => (
                            <Box  key={index} sx={{width:'33%',border:image===index?`2px solid #9b9bb9`:``,p:1.5}} >
                          <figure onClick={()=>setImage(index)} >
                            <img src={img.url} alt={item.pro_title}  /></figure>
                            </Box>
                          ))} 
                        </Box>:
                        <Slider {...settings}  > 
                          {item.img&& item.img.map((img ,index) => (
                            // '2px solid black':''
                            <Box  key={index} sx={{border:image===index?`2px solid #9b9bb9`:``,p:1.5}} >
                          <figure onClick={()=>setImage(index)} >
                            <img src={img.url} alt={item.pro_title}  /></figure>
                            </Box>
                          ))} 
                        </Slider>}
                      </Box> 
                    </Box > 
                  </Box>
                  <Modal open={open} onClose={handleClose}>
                    <Box sx={{ position: 'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',width:'auto',bgcolor:'background.paper',boxShadow:24,}}>
                    {item.video&&item.video.length>0? <>
                      <YouTube videoId={item.video[0].url} opts={opts} onReady={onReady} />
                      {/* <iframe  width="560" height="315"  src={`https://www.youtube.com/embed/${item.video[0].url}`} title="YouTube video player" frameBorder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe> */}
                    </>:''}
                    </Box> 
                  </Modal>
                </Box>
                <Box sx={{ width:{ xs: '100%', md: '60%' },textAlign: { xs: 'start', md: 'start'},
                  display: 'flex',justifyContent:'center', }}>
                  <Box sx={{ml:2,}}>
                    <Box >
                       
                      <Typography sx={{fontWeight: 600,fontSize:'1.6rem',color:'green'  }} className="price">
                        {+item.price===+item.m_price? <></> : 
                        <span style={{fontSize:'1rem',padding:'0 10px 0 0',color:'#9b9bb4'}}>
                          <del><FormatPrice price={item.m_price}/> </del>
                        </span>}
                        <FormatPrice price={item.price}/>
                      </Typography>
                      <Typography sx={{fontWeight: 400,fontSize:'0.8rem',m:'10px 0'  }}>
                        {item.pro_dis}
                      </Typography>
                      <Typography sx={{fontWeight: 400,fontSize:'0.8rem',  }}>
                        {item.pro_shot_dis}
                      </Typography>
                      {/* {item.pdf&&item.pdf.length>0? <Button variant="outlined" href={item.pdf[0].url} target='_blink' > View PDF
                      </Button>:''}  */}
                    {/* <Button onClick={()=>handleBuyProduct(item.pro_id)} ><StoreIcon/> Buy now </Button>
                    <Button  sx={{color:'green'}} ><WhatsAppIcon/> WhatsApp</Button> */}
                    </Box>
                    <Forms  id={item.pro_id} typ={+page.id} setOptions={setOptions} price={item.price}/>
                    <Box sx={{width:'60%'}}>
                      <ul>
                        <li>
                        <Box sx={{display:'flex',justifyContent: 'space-between',}}>
                          <Typography sx={{fontWeight: 400,fontSize:'1rem',  }}>Product Price:</Typography>
                          <Typography sx={{fontWeight: 400,fontSize:'1rem', width:'100px', }}> <FormatPrice price={item.price}/></Typography>
                        </Box>
                        </li>
                        <li>
                        <Box sx={{display:'flex',justifyContent: 'space-between',}}>
                          <Typography sx={{fontWeight: 400,fontSize:'1rem',  }}>Options Price:</Typography>
                          <Typography sx={{fontWeight: 400,fontSize:'1rem', width:'100px', }}> <FormatPrice price={options.logo+options.Language+options.Version+options.Caricature+options.Extra}/></Typography>
                        </Box>
                        </li>
                        <li>
                        {/* setOptions({"id":id,"typ":typ,"logo":stateLogo,"Language":stateLang,"Version":stateVer,"Caricature":stateCaria,"Extra":stateExtra,}); */}
                        <Box sx={{display:'flex',justifyContent: 'space-between',}}>
                          <Typography sx={{fontWeight: 600,fontSize:'1rem',  }}>Total Price:</Typography>
                          <Typography sx={{fontWeight: 600,fontSize:'1rem', width:'100px', }}> <FormatPrice price={options.logo+options.Language+options.Version+options.Caricature+options.Extra+item.price}/></Typography>
                        </Box>
                        </li>
                      </ul>
                    </Box>
                      <Box sx={{pt:2}}>
                        {/* <Fab  size="small" variant="extended" component='a' color="primary" 
                            sx={{p:2,mr:{xs:0,md:1},mt:{xs:1,md:0},zIndex:1,boxShadow:'none',fontWeight:700}} onClick={()=>handleAddProduct(item.pro_id)}>
                          <ShoppingCartIcon sx={{ mr: 1 }} />
                          Add to cart
                        </Fab>

                        <Fab  size="small" variant="extended" component='a'
                          sx={{p:2,m:{xs:0,md:1},mt:{xs:1,md:0},zIndex:1,boxShadow:'none',fontWeight:700,bgcolor:'gray',color:'white','&:hover': {bgcolor:'gray',color:'#ffad19', },}} onClick={()=>handleBuyProduct(item.pro_id)}>
                          <StoreIcon sx={{ mr: 1 }} />
                          Buy now
                        </Fab>  */}
                        
                        <Fab  size="small" variant="extended" color="success" 
                          sx={{p:2,m:{xs:0,md:1},mt:{xs:1,md:0},zIndex:1,boxShadow:'none',fontWeight:700,bgcolor:'green',color:'white'}} href={`https://api.whatsapp.com/send?phone=+918858783587&text=I would like to buy this product(${item.code})`}  target={`_blank`}  >
                          <WhatsAppIcon sx={{ mr: 1 }} />
                          Order on WhatsApp
                        </Fab> 
                      </Box>
                    <hr/>
                    <Typography sx={{fontWeight: 400,fontSize:'0.8rem',  }}> {/* style="font-weight: bold" */}
                      <span >Categories:</span> {cate.titles},{page.title}
                    </Typography>
                    <Box sx={{mt:3}}> 
                    <Typography sx={{fontWeight: 500,fontSize:'1rem',  }}>Share on Social Media</Typography>
                      <Tooltip title="Facebook">
                        <Fab  size="small"  sx={{m:1,zIndex:1,bgcolor:'#3b5998',color:"white",boxShadow:'none'}} 
                          href={`https://www.facebook.com/sharer.php?u=http%3A%2F%2Fdigiinvite.in%2F&quote=${item.code}/${item.pro_title} `}   target={`_blank`}  >
                          <FacebookOutlinedIcon />
                        </Fab> 
                      </Tooltip>
                      <Tooltip title="Twitter ">
                        <Fab  size="small"  sx={{m:1,zIndex:1,bgcolor:'#1da1f2',color:"white",boxShadow:'none'}} 
                          href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fdigiinvite.in%2F&text=invitation%20ke%20rang%20digital%20ke%20sang&hashtags=invitation,digiinvite,Digital,inivite`}  target={`_blank`}  >
                          <TwitterIcon />
                        </Fab> 
                      </Tooltip>
                      <Tooltip title="WhatsApp ">
                        <Fab  size="small" color="success" sx={{m:1,zIndex:1,boxShadow:'none'}} href={`https://api.whatsapp.com/send?text=${item.pro_title} http://digiinvite.in/product/${item.code}`}  target={`_blank`}  >
                          <WhatsAppIcon />
                        </Fab> 
                      </Tooltip>
                      <Tooltip title="Telegram ">
                        <Fab  size="small"  sx={{m:1,zIndex:1,bgcolor:"#229ED9",color:"white",boxShadow:'none'}} 
                          href={`https://t.me/share/url?url=http%3A%2F%2Fdigiinvite.in&text=${item.pro_title}`}  target={`_blank`}  >
                          <TelegramIcon />
                        </Fab> 
                      </Tooltip>
                      <Tooltip title="Linked In ">
                        <Fab  size="small" sx={{m:1,zIndex:1,bgcolor:"#0077b5",color:"white",boxShadow:'none'}} 
                          href={`https://www.linkedin.com/sharing/share-offsite/&url=http%3A%2F%2Fdigiinvite.in%2F&title=digiinvite%20invitation%20ke%20rang%20digital%20ke%20sang&summary=digiinvite%20invitation%20ke%20rang%20digital%20ke%20sang`}  target={`_blank`}  >
                          <LinkedInIcon />
                        </Fab> 
                      </Tooltip>
                    </Box>
                    {/* <Typography sx={{fontWeight: 400,fontSize:'0.8rem',  }}>
                      Brand:{cate.titles},{page.title}
                    </Typography>
                    <Typography sx={{fontWeight: 400,fontSize:'0.8rem',  }}>
                      category -{cate.dis} https://api.whatsapp.com/send?text=[post-title] [post-url]
                    </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Box  sx={{width:'100%',bgcolor:'#f5f5f5',mt:3,mb:3,p: '30px 0 ',
                  display: 'flex',justifyContent:'start',}}>
                    <Additional cate={page.dis} sub={cate.dis} values={values}/>
              </Box>
              </>
              :<></>}
            </React.Fragment>))}
          </React.Fragment>))}
        </React.Fragment>))}
      </Box>
      
    </BoxStyle>
  )
} 
// const ImageZoom = ({url}) => {
//   scale:1.5,zoomWidth: 'default' width: 400,  
//   const propHovers = {width: 400, img: url,zoomPosition: "original"};
//   return (<ReactImageZoom {...propHovers} />);
// }
 
 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div  role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Additional = ({cate,sub,values}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <> 
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"  >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Reviews " {...a11yProps(1)} />
          {/* <Tab label="Additional information" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography sx={{fontSize:'1rem',}} >{cate}</Typography>
        <Typography sx={{fontSize:'1rem',}} >{sub}</Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* Item Two */}
        <Review  reviews={values.reviews} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Three ok
      </TabPanel> */}
    </Box>
    </>
  );
}
 


const Forms = ({id,typ,price,setOptions}) => {
  const Language =price/2;
  const OtherLanguage =60*price/100;
  const logos =299;
  const Version=price/2;
  const Caricature=1499;
  const Extra=499;
  const [formTyp, setFormTyp] = useState(0);
  useEffect(() => {
      if(typ===1||typ===2||typ===3||typ===15||typ===16){
        setFormTyp(1);
      }else if(typ===11||typ===17){
        setFormTyp(2);
      }else{setFormTyp(0);}
  }, [typ]);
  // console.log(formTyp);

  const [stateLang, setStateLang] = useState(0);
  function handleLangChange(params) {
    if(params===true){
      setStateLang(Language);
    }else{setStateLang(0);}
  }
  function handleSubLanguageChange(value) {
    // console.log(value);
    setStateLang(value);
  }
  


  const [stateCaria, setStateCaria] = useState(0);
  function handleCaricatureChange(params) {
    if(params===true){
      setStateCaria(Caricature);
    }else{setStateCaria(0);}
  }
  function handleSubCaricatureChange(value) {
    // console.log(value);
    setStateCaria(+value);
    
  }

  const [stateExtra, setStateExtra] = useState(0);
  function handleExtraChange(params) {
    if(params===true){
      setStateExtra(Extra);
    }else{setStateExtra(0);}
  }
  function handleSubExtraChange(value) {
    // console.log(value);
    setStateExtra(+value);
  }



  const [stateVer, setStateVer] = useState(0);
  function handleVersionChange(params) {
    if(params===true){
      setStateVer(Version);
    }else{setStateVer(0);}
  }


  const [stateLogo, setStateLogo] = useState(0);
  function handleLogoChange(params) {
    if(params===true){
      setStateLogo(logos);
    }else{setStateLogo(0);}
  }
  useEffect(() => {
    setOptions({"id":id,"typ":typ,"logo":stateLogo,"Language":Number(stateLang),"Version":stateVer,"Caricature":stateCaria,"Extra":stateExtra,});
  }, [id, typ, stateVer, stateLang, stateLogo, setOptions, stateCaria, stateExtra]);
  return (
    <Box  sx={{pt:3}} >
      <FormGroup >
        <FormLabel component="legend">Additional Charges (If Required)</FormLabel>
        {/* <FormsPrice name='Make Your Own Caricature' price={Caricature}/> */}
        {formTyp===1 ?<FormControlLabel onChange={(e, newValue)=>handleCaricatureChange(newValue)} control={<Checkbox sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<Typography sx={{fontSize:{ xs: '1rem', md: '1rem'},fontWeight:500}}>Make Your Own Caricature</Typography>} />:<></>}
        {stateCaria!==0?<>
          <RadioGroup onChange={(e, newValue)=>handleSubCaricatureChange(newValue)} sx={{flexDirection:'row',ml:4}}
            aria-labelledby="radio-Sub-Caricature" defaultValue={Caricature} name="radio-Sub-Caricature" >
            <FormControlLabel value={Caricature}  control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}}  />} label={<FormsPriceSub name='Single Caricature ' price={Caricature}/>}  />
            <FormControlLabel value="2499"   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='Couple  Caricature' price={2499}/>} />
          </RadioGroup>
        </>:<></>}



        <FormControlLabel onChange={(e, newValue)=>handleLangChange(newValue)} control={<Checkbox sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} 
        label={<Typography sx={{fontSize:{ xs: '1rem', md: '1rem'},fontWeight:500}}>Additional Language</Typography>} /> 
        {stateLang!==0?<>
          <RadioGroup onChange={(e, newValue)=>handleSubLanguageChange(newValue)} sx={{flexDirection:'row',ml:4}}
            aria-labelledby="radio-Sub-Language" defaultValue={Language} name="radio-Sub-Language">
            <FormControlLabel value={Language}  control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}}  />} label={<FormsPriceSub name='Punjabi/Hindi/English' price={Language}/>}  defaultValue />
            {/* <FormControlLabel value={Language+200}   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='' price={Language}/>} />
            <FormControlLabel value={Language+100}  control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}}  />} label={<FormsPriceSub name='English' price={Language}/>}/> */}
            <FormControlLabel value={OtherLanguage}   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='Other Language' price={OtherLanguage}/>} />
          </RadioGroup>
        
        
        </>:<></>}



        {formTyp===1 || formTyp===2 ?<FormControlLabel onChange={(e, newValue)=>handleExtraChange(newValue)} control={<Checkbox sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} 
        label={<Typography sx={{fontSize:{ xs: '1rem', md: '1rem'},fontWeight:500}}>Create Extra Pages </Typography>} />:<></>}
        {stateExtra!==0?<>
          <RadioGroup onChange={(e, newValue)=>handleSubExtraChange(newValue)} sx={{flexDirection:'row',ml:4}}
            aria-labelledby="radio-Sub-Extra" defaultValue={Extra} name="radio-Sub-Extra" >
            <FormControlLabel value={Extra}  control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}}  />} label={<FormsPriceSub name='1 Page ' price={Extra}/>}  />
            <FormControlLabel value="799"   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='2 Page ' price={799}/>} />
            <FormControlLabel value="1199"   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='3 Page ' price={1199}/>} />
            <FormControlLabel value="1599"   control={<Radio size="small" sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPriceSub name='4 Page ' price={1599}/>} />
          </RadioGroup>
        </>
        :<></>}



        <FormControlLabel onChange={(e, newValue)=>handleLogoChange(newValue)} control={<Checkbox sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} label={<FormsPrice name='Remove Branding Logo?' price={logos}/>} />

        {formTyp===1 ?<FormControlLabel onChange={(e, newValue)=>handleVersionChange(newValue)} control={<Checkbox sx={{ '&.Mui-checked': {color:'#ffad19', },}} />} 
        
        label={<FormsPrice name='Flat 50% OFF on 2nd Version' price={Version}/>} />:<></>}
        
      </FormGroup>



        {/* <Box  sx={{ml:2,width:'100%',display: 'flex',flexWrap: 'wrap', alignContent: 'center',justifyContent: 'start',}} >
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="ROLang" id="inlineLangRadio0" value={Language} onClick={(e)=>handleSubLanguageChange(e.target.value)} />
              <label  htmlFor="inlineLangRadio0">Hindi (<FormatPrice price={Language}/>)</label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="ROLang" id="inlineLangRadio1" value={Language} onClick={(e)=>handleSubLanguageChange(e.target.value)} defaultChecked />
              <label  htmlFor="inlineLangRadio1"> Punjabi  (<FormatPrice price={Language}/>) </label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="ROLang" id="inlineLangRadio3" value={Language} onClick={(e)=>handleSubLanguageChange(e.target.value)} defaultChecked />
              <label  htmlFor="inlineLangRadio3">  English  (<FormatPrice price={Language}/>) </label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="ROLang" id="inlineLangRadio2" value={Language} onClick={(e)=>handleSubLanguageChange(e.target.value)} />
              <label  htmlFor="inlineLangRadio2">Other Language (<FormatPrice price={Language}/>) *Note- uvhgv jhv hvg v ghv jgvg v gg</label>
               
          </Box>
        </Box> */}

{/* 
        <Box  sx={{ml:2,width:'100%',display: 'flex',flexWrap: 'wrap',alignContent: 'center',justifyContent: 'start',}} >
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="inlineExtra" id="inlineExtra1" value={Extra} onClick={(e)=>handleSubExtraChange(e.target.value)}  defaultChecked/>
              <label  htmlFor="inlineExtra1">1 Page   (<FormatPrice price={Extra}/>) </label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="inlineExtra" id="inlineExtra2" value={1000} onClick={(e)=>handleSubExtraChange(e.target.value)} />
              <label  htmlFor="inlineExtra2">2 Pages  (<FormatPrice price={1000}/>)</label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="inlineExtra" id="inlineExtra3" value={1500} onClick={(e)=>handleSubExtraChange(e.target.value)} />
              <label  htmlFor="inlineExtra3">3 Pages  (<FormatPrice price={1500}/>)</label>
          </Box>
          <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
              <input  type="radio" name="inlineExtra" id="inlineExtra4" value={2000} onClick={(e)=>handleSubExtraChange(e.target.value)} />
              <label  htmlFor="inlineExtra4">4 Pages  (<FormatPrice price={2000}/>)</label>
          </Box>
        </Box> */}
        {/* <Box  sx={{ml:2,width:'100%',display: 'flex',flexWrap: 'wrap',alignContent: 'center',justifyContent: 'start',}} >
              <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
                  <input  type="radio" name="ROCarica" id="inlineCarRadio1" value={Caricature} onClick={(e)=>handleSubCaricatureChange(e.target.value)} defaultChecked />
                  <label  htmlFor="inlineCarRadio1">Single Caricature  (<FormatPrice price={Caricature}/>) </label>
              </Box>
              <Box  sx={{ml:2,display: 'flex',alignContent: 'center',justifyContent: 'center',cursor:'pointer'}} >
                  <input  type="radio" name="ROCarica" id="inlineCarRadio2" value="1500" onClick={(e)=>handleSubCaricatureChange(e.target.value)} />
                  <label  htmlFor="inlineCarRadio2">Couple  Caricature (<FormatPrice price={1500}/>)</label>
              </Box> 
            </Box>*/}


          {/* <div className="row" style={{width:'33%'}} >
              <input  type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label  htmlFor="inlineRadio2">Caricature 2   (₹999.0) </label>
          </div> */}
      {/* <FormGroup>
      <FormControlLabel control={<Checkbox Disabled />} label="Disabled" /> 
        <FormControl >
          <RadioGroup onChange={(e, newValue)=>handleLangChange(newValue)} sx={{flexDirection:'row',}} 
            aria-labelledby="radio-buttons-group" defaultValue="no" name="radio-buttons-group" >
            <FormLabel >
                <Typography sx={{fontWeight: 600,fontSize:'0.9rem',m: '10px 10px 0 0' }} >Additional Language</Typography>
            </FormLabel>
            <FormControlLabel value="0"  control={<Radio   />} label={<FormsPrice name='Main' price={0}/>} />
            <FormControlLabel value="1"   control={<Radio />} label={<FormsPrice name='Other' price={Language}/>} />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <RadioGroup onChange={(e, newValue)=>handleLogoChange(newValue)} sx={{flexDirection:'row',}} 
            aria-labelledby="radio-buttons-group" defaultValue="no" name="radio-buttons-group">
            <FormLabel >
                <Typography sx={{fontWeight: 600,fontSize:'0.9rem',m: '10px 10px 0 0'   }} >Remove Branding Logo?</Typography>
            </FormLabel>
            <FormControlLabel value="1" sx={{fontSize:'0.5rem',}}  control={<Radio  />} label={<FormsPrice name='yes' price={logos}/>} />
            <FormControlLabel value="0"   control={<Radio />} label={<FormsPrice name='No' price={0}/>} />
          </RadioGroup>
        </FormControl>
        <FormControl>
          {id<5 ? <>
          <RadioGroup onChange={(e, newValue)=>handleVersionChange(newValue)} sx={{flexDirection:'row', }} 
            aria-labelledby="radio-buttons-group" defaultValue="no" name="radio-buttons-group">
            <FormLabel >
                <Typography sx={{fontWeight: 600,fontSize:'0.9rem',m: '10px 10px 0 0'   }} >*Offer: Flat 50% OFF on 2nd Version</Typography>
            </FormLabel>
            <FormControlLabel value="0"   control={<Radio  />} label={<FormsPrice name='No' price={0}/>} />
            <FormControlLabel value="1"   control={<Radio  />} label={<FormsPrice name='Bride Side' price={Version}/>} />
            <FormControlLabel value="2"  control={<Radio  />} label={<FormsPrice name='Groom Side' price={Version}/>} />
          </RadioGroup>
          </>
        :
        <RadioGroup onChange={(e, newValue)=>handleVersionChange(newValue)} sx={{flexDirection:'row', }} 
            aria-labelledby="radio-buttons-group" defaultValue="no" name="radio-buttons-group">
            <FormLabel >
                <Typography sx={{fontWeight: 600,fontSize:'1rem',m: '10px 10px 0 0'   }} >Add Google Location on PDF Card</Typography>
            </FormLabel>
            <FormControlLabel value="1"   control={<Radio  />} label={<FormsPrice name='yes' price={Version}/>} />
            <FormControlLabel value="0"   control={<Radio  />} label={<FormsPrice name='No' price={0}/>} />
          </RadioGroup>
        }
        </FormControl>
      </FormGroup> */}

    </Box>
  )
}
const FormsPrice = ({name,price}) => {
  return (<Typography sx={{fontSize:{ xs: '0.8rem', md: '1rem'},fontWeight:500}}>{name} (<FormatPrice price={price}/> )</Typography>);
}

const FormsPriceSub = ({name,price}) => {
  return (<Typography sx={{fontSize:{ xs: '0.7rem', md: '0.8rem'},fontWeight:500}}>{name} (<FormatPrice price={price}/> )</Typography>);
}



