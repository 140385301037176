import React from 'react';
import { createContext,useContext,useEffect,useReducer } from 'react'; 
// import { commerce } from '../lib/Commerce';
import reducer from '../reducer/cartReducer';
import { UseProContext } from '../context/productContext';

const CartContext = createContext();
//   {
//   cart: {},
//   setCart: () => {}
// }

// export default CartContext;
const initial={
  isLoading:false,
  isError:false,
  cart:[],
  wishlist:[],
  count:[],
};

function CartProvider({children}) {
  const values=UseProContext();
  const product =values.products;
          // console.log(product);
  
  
    // const [cart, setCart] = useState();

    const [carts, setCarts] = useReducer(reducer, initial);
    useEffect(() => {
      // const newCart ='pro_id';
      // let newCart = localStorage.getItem('pro_id');
      // if(!newCart){newCart=','}
      // setCarts({type:"MY_CART_DATA",payload:newCart,pro:product});
      if (localStorage.getItem("pro_form") ===null || localStorage.getItem("pro_form") ==='' ) {

      }
      const savePro =localStorage.getItem('pro_form');
      const Pro=JSON.parse(savePro);
      setCarts({type:"MY_CART_DATA",pro:product,data:Pro});



      
      let newWish = localStorage.getItem('wish_id');
      if(!newWish){newWish=','}
      setCarts({type:"MY_WISHLIST_DATA",payload:newWish,pro:product});
          // setCarts({type:"MY_CART_DATA",payload:newCart});
      // commerce.cart.retrieve()
      //   .then(cart => {
      //   });
    }, [product])

  return (
    <CartContext.Provider value={{carts, setCarts}}>{children}</CartContext.Provider>
  )
}
export const UseCartContext = () => {
    return useContext(CartContext);
  };

export {CartProvider, CartContext};