import React from 'react';
import { useEffect,useState } from 'react';
import { UseCartContext } from '../context/CartContext';
import { UseUserContext } from '../context/userContext '; 
import axios from 'axios';
// import Helmet from "react-helmet";

// import { UseProContext } from '../context/productContext';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import CloseIcon from '@mui/icons-material/Close';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FormatPrice from '../comp/FormatPrice';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { FormGroup ,FormControl} from '@mui/material';
import Banner from "./homes/banner";

// import Input from '@mui/material'; 
// import initialize from '../delete/paytem/initialize';
// import makePayment from '../delete/paytem/paytmButton';
// import openJsCheckoutPopup from './paytem/script';
// import Autocomplete from '@mui/material/Autocomplete'; 
const StyledCheckout= styled(Box)(({ theme }) => ({
    "& .MuiTypography-root":{fontFamily: 'Work Sans',},
    "& input":{border: 0,"&:focus":{border: 0, }, },
    "& .scrollModal":{ overflowY:'scroll',height: '90vh',color:'red',
        "&::-webkit-scrollbar":{display: 'none', }, 
    },
    "& .MuiButton-root":{background: '#ffad19',
    "&:hover":{background:'#1976d2'},
    },
    "table":{width:'100%',},
    "tbody":{width:'100%',},"tr":{width:'100%',},
    "th":{width:'50%',textAlign: 'start',},
    "td":{width:'50%',textAlign: 'end',fontWeight:600,},
}));

const Checkout = () => {
    // const values=UseProContext();
    // const product =values.products;
    // const [inputs, setInputs] = React.useState('INR, ₹');
    // const options=['INR, ₹','USD, $'];

    const {carts,setCarts}=UseCartContext();
    const users=UseUserContext(); 
//  const initialState={name:'ok',}
    const [user, setUser] = useState({});
    useEffect(() => {
        setUser(...users.user);
    }, [users]);
    // const cartCount =carts.count;setCarts
    const cartPros =carts.cart;
    const [cartPro, setCartPro] = useState(cartPros);
    useEffect(() => {
        setCartPro(cartPros);
    }, [cartPros]);
    console.log(cartPro);

    const [totalPrice, setTotalPrice] = useState(0);
    useEffect(() => {
        setTotalPrice(null);
        // eslint-disable-next-line array-callback-return
        Object.keys(cartPro).map((obj, i) => {
        // cartPro[obj].count*
        // const totals=cartPro[obj].price;
        const options=cartPro[obj]
        const totals=options.price+options.logo+options.Language+options.Version+options.Caricature+options.Extra;
        setTotalPrice(oldArray=>oldArray+totals);
        });
    }, [cartPro]);

    const [gstPrice, setGstPrice] = useState(0);
    useEffect(() => {
        setGstPrice(totalPrice+totalPrice*0/100);
    }, [totalPrice]);

   
    // const imgOffers={color:'green',fontWeight: 700, position: 'absolute',width: '100%',textAlign: 'center',};
    
    const [isLogIn, setIsLogIn] = useState(false);

    const [formRed, setFormRed] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState(null);
    const [link, setLink] = useState(null);
    const [info, setInfo] = useState(null);
    const [coupon, setCoupon] = useState(null);
//    const [openModal, setOpenModal] = useState(false);
//    const [payRes, setPayRes] = useState(null);
//    const [tranId, setTranId] = useState(null);

   const [orderId, setOrderId] = useState(null);
   const [paymentId, setPaymentId] = useState(null);
   const [totalPayment, setTotalPayment] = useState(null);

   useEffect(() => {
    const paymentIds =localStorage.getItem('paymentId');
    const payment =localStorage.getItem('payment');
    const order =localStorage.getItem('order');
    if(paymentIds){
        setPaymentId(paymentIds);
        setOrderId(order);
        setTotalPayment(payment);
    }
    if (localStorage.getItem("token-info") ==null) {
        setIsLogIn(false);
    } else{setIsLogIn(true);}
   }, []);
   useEffect(() => {
        if(isLogIn===true){
            
        user&&setUserName(user.userName);
        user&&setName(user.name);
        user&&setNumber(user.mobile);
        user&&setCountry(user.country);
        user&&setEmail(user.email);
        user&&setAddress(user.address);
        }
    }, [isLogIn, user]);

//    console.log(paymentId);country.length>1&&
   useEffect(() => {
    if(paymentId){setDisabled(true);}
    else{
        if(number.length>9&& name.length>5){
            setDisabled(false);setFormRed(false);
        }else{setDisabled(true);}
    }
   }, [country,number,name,paymentId]);

   function FormRed(e) {
    setFormRed(true);
   }

   const ApplyCoupon=(e)=>{ 
        localStorage.setItem('coupon', coupon);
    }
    function details(typ,value) { 
        switch (typ) {
            case "Name":
                setName(value);
                break;
            case "Country":
                setCountry(value);
                break;
            case "Number":
                setNumber(value);
                break;
            case "Email":
                setEmail(value);
                break;
            case "EAddress":
                setAddress(value);
                break;
            case "Link":
                setLink(value);
                break;
            case "AI":
                setInfo(value);
                break;
            case "Coupon":
                localStorage.setItem('coupon', value);
                setCoupon(value);
                break;
            default:
                break;
        }    
    }

 
    const handleCheckout=async (e,mode)=> {
        e.preventDefault();
        // setPaymentId(1);
        setDisabled(true);
        try{
            const currency = localStorage.getItem('curr');
            const coupons = localStorage.getItem('coupon');
            const auth  = 'j2v4h6g2v2j2l2big5hb5jdc45vd5hc';
            const payload = {key: auth ,title: 'title' ,message: 'message', cart:cartPro,country:country,
                totalPrice:totalPrice,gstPrice:gstPrice,currency:currency,PaymentTyp:1,coupon:coupons,
                userName:userName,name:name,number:number,address:address,link:link,info:info,email:email};
            const res = await axios.post('https://www.digiinvite.in/api/order/index.php', payload);
            const dates =await res.data;
            // console.log(dates);
            const payment =await dates.payment;
            setOrderId(dates.order);
            setPaymentId(payment);
            localStorage.setItem('order', dates.order);
            localStorage.setItem('paymentId', payment);
            localStorage.setItem('payment', totalPrice);
            localStorage.removeItem('coupon');


            console.log(coupon);
            // setOpenModal(true);
            // localStorage.removeItem('pro_id');
            // setCarts({type:"REMOVE_CART_DATA",payload:0,pro:[]});
            // const paymentData = [];
            // paymentData.order=await dates.order;
            // paymentData.token=await dates.userid;
            // paymentData.amount=totalPrice; 
            // const orderId = "Order_" + new Date().getTime();
            // const txnToken = "Order_" + new Date().getTime();
            // openJsCheckoutPopup(orderId,txnToken,gstPrice,);
          } catch(error){
            console.log('API_ERROR');
          }
      }
    return (
        <>{carts.isLoading? <>loading</>:<>
        <Banner name={`Checkout`}/>
        <StyledCheckout   className="container checkout" >
        
          <Box  sx={{width:'100%',bgcolor: 'background.paper', display: 'flex',justifyContent:'center',}}>
                <Typography sx={{fontWeight: 600,fontSize:'1rem',  }} >
                    <Link to='/'>Home </Link>/ Checkout
                </Typography>
          </Box>
          {/* <Autocomplete value={inputs}defaultExpanded
            onChange={(event, newValue) => {setInputs(newValue);}}
            id="controllable-states2" options={options} sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Change Currency" />}/> */}

        <Box sx={{display:'flex',flexWrap: 'wrap',p:2}}>
         {/* <Accordion sx={{width:'100%',margin:'15px 0'}} >
            <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1">
                <Typography>Log in the System </Typography>
               
            </AccordionSummary>
            <AccordionDetails>
                <Typography>Log in the System 
                </Typography>  
            </AccordionDetails>
         </Accordion>  */}
         <Accordion sx={{width:'100%',margin:'15px 0'}}>
            <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content">
                <Typography>Apply Coupon Code</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/*  */}
                <TextField label="Coupon Code" id='Coupon' variant="outlined"  onBlur={(e)=>details('Coupon',e.target.value)} />
                {/* <input type="text"  placeholder='Apply Coupon Code' /> */}
                <button className='btn' onClick={()=>ApplyCoupon(1)} >Apply Coupon Code</button>
            </AccordionDetails>
         </Accordion>
         
         <Accordion sx={{width:'100%',margin:'15px 0'}} expanded={paymentId?false:true} defaultExpanded={paymentId?false:true}>
         <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content">
             <Typography>Billing  Details</Typography>
         </AccordionSummary>
         <AccordionDetails>
         <Box component={`form`} sx={{width:'100%',display:'flex',flexWrap: 'wrap', }}
                onSubmit={(e)=>handleCheckout(e,'Checkout')}>
            {/* <hr />  */}
          <Box  sx={{width:{ xs: '100%', md: '50%' }}}>
            <Box sx={{px:{xs:1,md:3}, }}>
                <Typography sx={{fontWeight: 700,fontSize:{xs:'1rem',md:'1.5rem'},  }} >  BILLING DETAILS</Typography>
            </Box>
            <FormGroup sx={{p:'0 1%',width:'100%'}}>
            {isLogIn?<Box sx={{px:{xs:1,md:3},}}>
                <Typography sx={{fontWeight: 500,fontSize:'1.2rem',  }} >
                    Hy, {name}
                </Typography>
                <Typography sx={{fontWeight: 400,fontSize:'1rem',  }} >
                    Enter your details to the form below to process your order
                </Typography>
            </Box>:<> 
                <Typography sx={{fontWeight: 400,fontSize:'1rem',  }} >
                    Enter your details to the form below to process your order
                </Typography>
                <FormControl sx={{p:{xs:1,md:2}}}>
                    <TextField  required  id="standard-required" type='text' label="Enter Full Name"   variant="outlined" 
                    onBlur={(e)=>details('Name',e.target.value)} sx={{background:name.length>5?'':formRed?'#ffcece':''}} />
                </FormControl>
                <FormControl sx={{p:{xs:1,md:2},display:'flex',flexDirection:'row',}}>
                    <TextField required id="Country-input" type='text' label="Country Code" inputProps={{ maxLength: 4, }} sx={{width:'20%',}}  variant="outlined"
                    onBlur={(e)=>details('Country',e.target.value)}/> 
                    <TextField required id="standard-No-input" type='text' label="WhatsApp No. " inputProps={{ maxLength: 10 }}  variant="outlined"
                    onBlur={(e)=>details('Number',e.target.value)} sx={{width:'80%',background:number.length>9?'':formRed?'#ffcece':''}} /> 
                </FormControl>
                <FormControl sx={{p:{xs:1,md:2}}}>
                    <TextField required id="standard-Email-input" type='email'  label="Email Address"  variant="outlined" autoComplete="current-email" 
                    onBlur={(e)=>details('Email',e.target.value)}/> 
                </FormControl>
                <FormControl sx={{p:{xs:1,md:2}}}>
                    <TextField required  id="standard-Address-input"  label="Address"  variant="outlined" autoComplete="current-Address" 
                    onBlur={(e)=>details('Address',e.target.value)}/> 
                </FormControl>
            </>}
                <FormControl sx={{p:{xs:1,md:2}}}>
                    <TextField  id="standard-images-input"  label="Upload Multiple Images on WeTransfer.com"  variant="outlined"
                    onBlur={(e)=>details('Link',e.target.value)}/> 
                </FormControl>
                <FormControl  sx={{p:{xs:1,md:2}}}>
                    <TextareaAutosize  id="standard-info-input"  placeholder="Addition information"  variant="outlined"
                    onBlur={(e)=>details('AI',e.target.value)}/>
                </FormControl>
            </FormGroup>
          </Box>
          <Box  sx={{width:{ xs: '100%', md: '50%' }}}>
            <Box sx={{p:{xs:1,md:5},}}>
             <Typography sx={{fontWeight: 700,fontSize:{xs:'1rem',md:'1.5rem'},  }} >
                    CONFIRM YOUR ORDER
             </Typography>
             {Object.keys(cartPro).map((obj, i) =>
                <Box sx={{display:'flex',p:2}} key={i} >
                    <Box sx={{}}>
                    <figure> 
                    {/* <span  style={imgOffers} > {cartPro[obj].offer}</span> */}
                    <img alt={cartPro[obj].pro_title}  src={cartPro[obj].img[0]&&cartPro[obj].img[0]?`${cartPro[obj].img[0].url}`
                        :`https://www.digiinvite.in/ceo/assets/images/pro.png`} width='100px'  />
                    </figure>
                    </Box>
                    <Box sx={{pl:{xs:1,md:2},width: '100%',}}>
                    <Typography sx={{fontWeight: 600,fontSize:'1rem',  }} >
                        {cartPro[obj].pro_title} || {cartPro[obj].code}  
                    </Typography>
                    <Typography sx={{fontWeight: 600,fontSize:'1rem',textAlign:'start'  }} >
                        Price<FormatPrice price={cartPro[obj].price+cartPro[obj].logo+cartPro[obj].Language+cartPro[obj].Version+cartPro[obj].Caricature+cartPro[obj].Extra} />
                         {/* <FormatPrice price={cartPro[obj].price} />  */}
                    </Typography>
                    </Box> 
                </Box>
             )}
             <Box sx={{p:{xs:1,md:3},bgcolor:'lightgray'}}>
                <Typography sx={{fontWeight: 700,fontSize:'1.5rem',  }} >
                    Cart totals
                </Typography>
                <table><tbody>
                {/* <tr key="1">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem',}} >Subtotal</Typography>
                    </th>
                    <td><FormatPrice price={totalPrice} /></td>
                </tr> */}
                {/* <tr key="2">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem', }} >18% Gst </Typography>
                    </th>
                    <td><FormatPrice price={gstPrice-totalPrice}  /></td>
                </tr> */}
                <tr key="3">
                    <th>
                    <Typography sx={{fontWeight: 400,fontSize:'1rem'}}>Total</Typography>
                    </th>
                    <td><FormatPrice price={gstPrice}  /></td>
                </tr>
                </tbody></table>
                {/* {disabled? <Button variant="contained" onClick={(e)=>handleCheckout(e,'Checkout')} >Checkout</Button>:
                <Button variant="contained"  disabled="true" >Checkout</Button>} <Button variant="contained" onClick={()=>setOpenModal(true)} >Make Payment</Button> */}
                {/* <Button variant="contained" onClick={(e)=>handleCheckout(e,'Checkout')} disabled={disabled}>Checkout</Button> */}
                {paymentId?'':<>
                {/* <Button variant="contained"   onClick={(e)=>handleCheckout(e,'Checkout')} disabled={disabled}>Checkout</Button> */}
                {disabled?<Button variant="contained"   onClick={()=>FormRed(true)}  >Checkout</Button>:
                <Button variant="contained" type='submit'   disabled={disabled}>Checkout</Button>}
                </>
                }
                {formRed?<Typography sx={{fontWeight: 400,fontSize:'0.8rem',mt:2}}>*Fill your name and mobile number</Typography>:''}
                {/*  */}
                <PaymentModal orderId={orderId} paymentId={paymentId} totalPayment={totalPayment}/>
                
                 
             </Box>
            </Box>
          </Box>
         </Box>
         </AccordionDetails>
         </Accordion>
         <Accordion sx={{width:'100%',margin:'15px 0'}} expanded={paymentId?true:false} defaultExpanded={paymentId?true:false}>
            <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content">
                <Typography>Make Payment</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {paymentId?
                <PaymentModal orderId={orderId} paymentId={paymentId} totalPayment={totalPayment} 
                setCarts={setCarts} setPaymentId={setPaymentId}/>
            :<> <Typography>Fill Billing  Details </Typography></> }
                
            </AccordionDetails>
         </Accordion>
        </Box>
        </StyledCheckout>
        </>
        }
            
        </>
    );
}

export default Checkout;

 
const PaymentModal = ({orderId,paymentId,totalPayment,setCarts,setPaymentId}) => {
//     const [orderId, setOrderId] = useState(orderId);
//    const [paymentId, setPaymentId] = useState(paymentId);
//    const [totalPayment, setTotalPayment] = useState(totalPayment);
    const [openModal, setOpenModal] = useState(false);
    const [payRes, setPayRes] = useState(null);
    const [tranId, setTranId] = useState(null);
    // console.log(payRes);
    function details(typ,value) { 
        switch (typ) {
            case "payRes":
                setPayRes(value);
                break;
            case "tranId":
                setTranId(value);
                break;
            default:
                break;
        }    
    }
    const handleCloseModal = () =>{ 
        setOpenModal(false);
    }
    const paymentResponse=async()=>{
        try{
            const auth  = 'j2v4h6g2v2j2l2big5hb5jdc45vd5hc2';
            const payload = {key: auth ,title:'title',message:'message',file:payRes,tran:tranId,
                            totalPrice:totalPayment,paymentId:paymentId, orderId:orderId};
            const res = await axios.post('https://www.digiinvite.in/api/order/index.php', payload);
            const dates =await res.data;
            console.log(dates);
            localStorage.removeItem('pro_id');
            localStorage.removeItem('pro_form');
            localStorage.removeItem('paymentId');
            localStorage.setItem('paymentOldId', paymentId);
            setCarts({type:"REMOVE_CART_DATA",payload:0,pro:[]});
            setPaymentId(null);
            window.history.pushState({}, "", "/payment")
            const navEvent = new PopStateEvent('popstate');
            window.dispatchEvent(navEvent);

        } catch(error){
            console.log('API_ERROR');
      }
    }

    return (
        <>
        {paymentId?<>
        
        <Button variant="contained" onClick={()=>setOpenModal(true)} >Make Payment</Button>
        <Modal open={openModal} onClose={handleCloseModal}>
                    <Box className="scrollModal" sx={{overflowY:'scroll',height: '100vh', width:{ xs: '80%', md: '30%' },
                    position: 'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',}}>
                    <Box sx={{bgcolor:'background.paper',boxShadow:24,}} >
                    
                    <figure > <img src={`https://www.digiinvite.in/ceo/assets/images/qrcode.jpg`}alt={`title`} width='100%' />
                        <Button variant="contained" sx={{position:'absolute',top:'90%',left:'1%'}} 
                        href={`https://www.digiinvite.in/ceo/assets/images/qrcode.jpg`} target="_blink" downloaded="true" >Download</Button>
                    </figure>{/* https://api.whatsapp.com/send?phone=+918858783587&text=hello */}
                    <FormGroup>
                      <FormControl sx={{p:2}}>
                        {/* <TextField  id="standard-info-input" type="file"  placeholder="upload payment respite"  variant="outlined"
                        onChange={(e)=>details('payRes',e.target.files[0])}/>  */}
                        {/* // onChange={(e)=>console.log(e.target.files[0],e.target.value)} */}
                        <TextField  id="standard-info-input"   placeholder="Enter Transaction ID"  variant="outlined"
                        onBlur={(e)=>details('tranId',e.target.value)}/> 
                      </FormControl>
                      <Button variant="contained" sx={{m:1}} onClick={()=>paymentResponse()} >Submit</Button>
                    </FormGroup>
                    {/* <Button variant="contained" sx={{m:1}} href={`http://www.digiinvite.in/ceo/images/pro.jpg `} target="_blink">Google Pay</Button>
                    <Button variant="contained" sx={{m:1}} href={`http://www.digiinvite.in/ceo/images/pro.jpg `} target="_blink">Phone Pay</Button> */}
                    </Box> 
                    </Box> 
                  </Modal>

        </>:'' }
        </>
    );
}
 
