import React from 'react';
import { useState,useEffect } from 'react';
import { UseProContext } from '../context/productContext';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Products from '../comp/Products';
// import Button from '@mui/material/Button';
// import Helmet from "react-helmet";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

const Search = () => {
    const values=UseProContext();
    const product =values.pro;
    const {item,page}=useParams();
    const [filPro, setFilPro] = useState([]);
    const [pages, setPage] = useState([1]);
    const [number, setNumber] = useState(1);
    const [state, setState] = useState([0,8]);
    useEffect(() => {
      const filtered = product.filter(pro => {
        const title = pro.pro_title.toLowerCase();
        const code = pro.code.toLowerCase();
        const tag = pro.pro_tag.toLowerCase();
        return title.includes(item.toLowerCase())
              ||code.includes(item.toLowerCase())
              ||tag.includes(item.toLowerCase());
        }
      );
        setFilPro(filtered);
    }, [product,item]);

    // console.log(filPro);
    useEffect(() => {
      const number =parseInt(filPro.length/8+1);
      const pages=new Array(number)
      for (let i = 0; i < pages.length; i++) {pages[i]= i; }
      setPage(pages);

    }, [filPro]);

    useEffect(() => { 
      if(page){
        setNumber(+page);
        const state=[(+page-1)*8,(+page-1)*8+8];
        setState(state); 
      }
    }, [page]);

    const handleChange = (event, value) => {
        setNumber(value);
        const state=[(value-1)*8,(value-1)*8+8];
        setState(state); 
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
      window.history.pushState({}, "", "/search/"+item+"/"+value)
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    };
    

    return (
    <Box className='container'>
        <Box sx={{p:'16px 0'}}>
          <Box className='row'>
            {filPro.map((pro,i)=>state[0]<=i && state[1]>i?
              <Box key={i} sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}>
                <Products pro={pro} /> 
              </Box>:''
            )} 
          </Box>
          <Box className='row'>
            <Stack spacing={2}>
              <Pagination count={pages.length} page={number} onChange={handleChange} />
            </Stack>
          </Box>


        </Box>  
    </Box>
    );
}
export default Search;



// import { UseCartContext } from '../context/CartContext';
// import {CartContext} from '../context/CartContext';
// import { commerce } from '../lib/Commerce';
// import { styled, alpha } from '@mui/material/styles';
// import FormatPrice from '../comp/FormatPrice';
// import { Link } from "react-router-dom";
// import IconButton from '@mui/material/IconButton';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';

// const SearchCom = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 1),
//     '&:hover': {
//       backgroundColor: alpha(theme.palette.common.white, 0.9),
//     },
//     marginLeft: 0,
//     width: '100%',
//     zIndex:100,
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(1),
//       width: 'auto',
//     },
//   }));
//   const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',width: '100%',
//     '& .MuiInputBase-input': {
//       padding: theme.spacing(1, 1, 1, 0),
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//       transition: theme.transitions.create('width'),
//       width: '100%',
//       zIndex:100,
//       [theme.breakpoints.up('md')]: {
//         width: '100%',
//       },
//     },
//   }));
//   const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     // right:'0',
//     zIndex:'500',
//     cursor:'pointer',
//     // pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     // background:'aqua',
//   }));
  
          //   {/* {filPro.map((cate,index) => <>{ cate.map((sub,indexS)=> <>{ sub.map((pro,indexP)=><> 
          //     <Box sx={{width:{ xs: '50%', sm: '33.33%', md: '25%' },p:1}}><Products pro={pro} /> </Box>
          //   </>)} </>)} </> )} */}
          // {/* <Typography>Page: {number}</Typography>
          // <Button  onClick={()=>changePage(number-1)}> Priv</Button>
          //   <span>{number} of {pages.length} </span>
          // <Button  onClick={()=>changePage(number+1)}> Next</Button>
          // <Box className='row'>
          //   {pages.map((p,i)=>
          //   <Button key={i} onClick={()=>changePage(p)}> {p+1}</Button>
          //   )} 
          // </Box> */}

    // function changePage(p) {
    //   setNumber(p+1);
    //   const state=[p*8,p*8+8];
    //   setState(state);
      
    // }
      // console.log(state);
    // useEffect(() => {
    //   setState([0,4]);
    // }, [pages]);
      // console.log(number);

        //  <Box sx={{p:'2rem 40%',bgcolor:'#f7f7f7',}}>
        //     <SearchCom>
        //       <form className="commentForm" onSubmit={onFormSubmit}>
        //         <SearchIconWrapper type="submit" onClick={onFormSubmit} >
        //           <SearchIcon sx={{zIndex:'101',}}/>
        //         </SearchIconWrapper>
        //         <StyledInputBase
        //           id="searchValue"
        //           onChange={inputHandler}
        //           placeholder="Search…"
        //           type="search"
        //           inputProps={{ 'aria-label': 'search' }}
        //         />
        //       </form>
        //     </SearchCom>
        // </Box> 