import React from "react";
// import slicknpm install react-slick --save
// import { useState } from "react";
// import Payment from '../sgv/Online_Payment.svg'; 
// import './sgv/Quick_Deliver.svg'; 
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Slider from "react-slick";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

import { styled } from '@mui/material/styles';
const BoxStyle = styled(Box)(({ theme }) => ({ 
    "& .slick-next":{opacity:'0',display:'none',}, 
    "& .slick-prev":{opacity:'0',display:'none',}, 
    
}));
// import Slider from "react-slick";
const Slide = ({slides,isLoading}) => {
// const [style, setStyle] = useState( {});
// const [left, setLeft] = useState('auto');
  var settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: () => beforeChange(),
    afterChange: () => afterChange(),
  };
  function afterChange() {
    // console.log("end");
    // setLeft("auto");
    // setStyle({transform: `translate(0%)`,opacity:1,transition:`opacity 0.6s 0.8s,transform 0.5s 0.7s`});
    // setTimeout(() => {
    // }, 100);
  }
  function beforeChange() {
    // console.log("start");
    // setStyle({transform: `translate(-100%)`,opacity:0});
    // setLeft("-6%");

  }
  // sx={{left:left}} sx={{left:left}} style={style}style={style}

  return (
    <>
    <BoxStyle sx={{pt:3,borderRadius:'6px',}}>
      <Slider {...settings}  >
      {isLoading?<>
                <Stack spacing={1} sx={{width:'100%',}}> 
                    <Skeleton variant="rectangular" width={'100%'} sx={{height:{xs: 100, md:332 }}} /> 
                </Stack>
                </> :
        slides&&slides.map((slide,i)=><SlideDetails {...slide} key={i}  />)

      }


      {/* <Box sx={{borderRadius:'6px',position:'relative',}} key={slide.id} > 
          <img src={slide.url} alt="" style={{borderRadius:'6px',}} />
          <Box className="slidertext"  >
            <Box className="slideff" sx={{height:'100%'}}>
              <h2 style={{fontFamily:'Ubuntu'}}>{slide.name}</h2> 
              <Typography component="p"  variant="p" style={{fontFamily:'Ubuntu'}}>{slide.dis}</Typography>
              <Box sx={{position:'absolute',bottom:{xs:'20%',sm:'5%'}}}>
                <img src="https://www.digiinvite.in/ceo/assets/images/DigiInvite_Tagline.svg" alt="" />
              </Box>
            </Box>
          </Box>
        </Box> */}
      {/* <h3 style={{fontFamily:'Ubuntu',}}>Invitation ke Rang Digital ke Sang</h3> */}
      {/* <a  href="/collections"  className="btn btn-primary"  tabIndex="-1" >  Shop now </a> */}
         
      </Slider>
    </BoxStyle>

       <div className="slideright  ">
        
         
        <div className="ser-ap" style={{ width: "100%",textAlign:'left' }}>
          <div className="ser-appto">
            <div className="row deliveryinfo text-center">
              <Box sx={{width:{ xs: '100%', md: '33.33%' },p:'1%'}}>
                <ul>
                  <li>
                    {/* <span><img src="https://www.digiinvite.in/ceo/assets/images/reviews.png" alt="" /></span> */}
                    <span>
                      <svg id="support" viewBox="0 0 501.551 501.551">
                        <polygon
                          style={{ fill: "#F8B64C" }}
                          points="188.054,218.384 252.837,283.167 317.621,218.384 "
                        ></polygon>
                        <rect
                          x="132.674"
                          y="143.151"
                          style={{ fill: "#40596B" }}
                          width="239.282"
                          height="267.494"
                        ></rect>
                        <path
                          style={{ fill: "#F8B64C" }}
                          d="M329.115,392.882c-51.2,6.269-101.355,6.269-152.555,0c7.314-44.931,7.314-89.861,0-135.837
  c51.2-6.269,101.355-6.269,152.555,0C321.8,303.02,321.8,347.951,329.115,392.882z"
                        ></path>
                        <path
                          style={{ fill: "#FFD15C" }}
                          d="M146.258,117.029v101.355c0,58.514,48.065,106.58,106.58,106.58s106.58-48.065,106.58-106.58V117.029
  L146.258,117.029L146.258,117.029z"
                        ></path>
                        <path
                          style={{ fill: "#40596B" }}
                          d="M252.837,24.033c-65.829,0-120.163,53.29-120.163,119.118H373
  C373,77.322,318.666,24.033,252.837,24.033z"
                        ></path>
                        <path
                          style={{ fill: "#CDD6E0" }}
                          d="M394.943,148.376h-14.629c0-74.188-57.469-134.792-127.478-134.792S125.36,74.188,125.36,148.376
  h-14.629C110.731,65.829,174.47,0,252.837,0S394.943,66.873,394.943,148.376z"
                        ></path>
                        <path
                          style={{ fill: "#F2F2F2" }}
                          d="M341.654,290.482H272.69v-13.584h68.963c12.539,0,22.988-10.449,22.988-22.988v-26.122h14.629v26.122
  C379.27,273.763,362.551,290.482,341.654,290.482z"
                        ></path>
                        <path
                          style={{ fill: "#ACB3BA" }}
                          d="M268.511,266.449h-31.347c-8.359,0-14.629,7.314-14.629,14.629v3.135
  c0,8.359,7.314,14.629,14.629,14.629h31.347c8.359,0,14.629-7.314,14.629-14.629v-3.135
  C283.139,273.763,276.87,266.449,268.511,266.449z"
                        ></path>
                        <path
                          style={{ fill: "#CDD6E0" }}
                          d="M412.707,159.869h-8.359v53.29h8.359c6.269,0,10.449-4.18,10.449-10.449v-32.392
    C423.156,164.049,417.931,159.869,412.707,159.869z"
                        ></path>
                        <path
                          style={{ fill: "#CDD6E0" }}
                          d="M82.519,169.273v32.392c0,6.269,4.18,10.449,10.449,10.449h8.359v-53.29h-8.359
    C87.743,159.869,82.519,164.049,82.519,169.273z"
                        ></path>
                        <path
                          style={{ fill: "#FF7058" }}
                          d="M383.449,143.151h-24.033v84.637h24.033c11.494,0,20.898-9.404,20.898-20.898v-42.841
    C404.347,152.555,394.943,143.151,383.449,143.151z"
                        ></path>
                        <path
                          style={{ fill: "#FF7058" }}
                          d="M101.327,164.049v42.841c0,11.494,9.404,20.898,20.898,20.898h24.033v-84.637h-24.033
    C110.731,143.151,101.327,152.555,101.327,164.049z"
                        ></path>
                        <polygon
                          style={{ fill: "#FFD15C" }}
                          points="312.396,143.151 312.396,110.759 300.902,143.151  "
                        ></polygon>
                        <polygon
                          style={{ fill: "#FFD15C" }}
                          points="230.894,143.151 230.894,119.118 217.311,143.151  "
                        ></polygon>
                        <polygon
                          style={{ fill: "#F8B64C" }}
                          points="180.739,355.265 252.837,427.363 324.935,355.265 "
                        ></polygon>
                        <path
                          style={{ fill: "#FF7058" }}
                          d="M334.339,355.265H323.89l-72.098,72.098l-72.098-72.098h-10.449
  c-70.008,0-126.433,56.424-126.433,126.433v19.853h415.869v-19.853C460.772,411.69,404.347,355.265,334.339,355.265z"
                        ></path>
                      </svg>
                    </span>
                  </li>
                  {/* <br/> */}
                  <Box component='li' sx={{width:{ xs: '80%', md: '65%' },p:'0 0 0 7%' }} >
                    <h4>24X7 Service </h4>
                    <p>Support is offered Day and night, seven  days a week</p>
                  </Box>
                </ul>
              </Box>

              <Box className="sborder " sx={{width:{ xs: '100%', md: '33.33%' },p:'1%'}}>
                <ul>
                  <li >
                  
                  {/* <span><img src={Payment} alt="" /></span>  */}
                  <span><img src="https://www.digiinvite.in/ceo/assets/images/Online_Payment.svg" alt="" height='40px' /></span> 
                     
                  </li>
                  <Box component='li' sx={{width:{ xs: '80%', md: '65%' },p:'0 0 0 7%' }}>
                    <h4>Online Payment</h4>
                    <p>Pay using the safest method with a UPI, net banking, or any digital wallet</p>
                  </Box>
                </ul>
              </Box>

              <Box sx={{width:{ xs: '100%', md: '33.33%' },p:'1%'}}>
                <ul>
                  <li>
                  <span><img src="https://www.digiinvite.in/ceo/assets/images/Quick_Deliver.svg" alt="" 
                  style={{width:"45px",marginTop:"5px"}}/></span> 
 
                  </li>
                  <Box component='li' sx={{width:{ xs: '80%', md: '65%' },p:'0 0 0 7%' }}>
                    <h4>Quickest delivery</h4>
                    <p>Receive your customized e-card or video invitations within 1-2 working days</p>
                  </Box>
                </ul>
              </Box>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

const SlideDetails = ({id,url,name,dis,key}) => {
  const description =dis.split(".").filter(value=>value).map(value=>value.trim());
  return ( 
      <Box key={key} sx={{borderRadius:'6px',position:'relative',}} > 
        <img src={url} alt="" style={{borderRadius:'6px',}} />
        <Box className="slidertext"  >
          <Box className="slideff" sx={{height:'100%'}}>
            <h2 style={{fontFamily:'Ubuntu'}}>{name}</h2> 
            <Typography component="p"  variant="p" style={{fontFamily:'Ubuntu'}}>
              {/* {dis} */}
              {description.map((des,i)=><span key={i}>{des}<br/></span>)}
            </Typography>

            <Box sx={{position:'absolute',bottom:{xs:'20%',sm:'5%'}}}>
              <img src="https://www.digiinvite.in/ceo/assets/images/DigiInvite_Tagline.svg" alt="" />
            </Box>
          </Box>
        </Box>
      </Box> 
  );
}
export default Slide;
 

 

