import React from 'react';
import Box from '@mui/material/Box'; 
import Typography from '@mui/material/Typography';
import { Link} from "react-router-dom";
import Button from '@mui/material/Button';

const Currency = () => {
    return (
        <>
        <Box sx={{width:'100%'}} >
            <Box  className="container" >
            <Box  sx={{p:5,textAlign:'center'}} >

            <Typography sx={{fontSize:'1.5rem',color:'green'}}> 
                Currency Changed successfully.
                 {/* <br/>  Now, You can place your order in {`Us`} Currency. */}
            </Typography>
            <Link to="/">
                <Button >Go To Home</Button>
            </Link>
            </Box>
                
            </Box>
        </Box>
        </>
    );
}

export default Currency;
