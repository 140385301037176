// import React from 'react'

const FormatPrice=({price})=> {
    // let prices=price*100;82.54

    let newCurr = '';
    let prices = price;
    if (localStorage.getItem("curr") == null) {
      newCurr='en-IN,INR';
    }else{newCurr = localStorage.getItem('curr');}
     
    // console.log(newCurr);
    const types = newCurr.split(",");
    if(types[1]==='USD'){
      prices=price/82;
    }
    //else if(key==='USD, $'){
    //     types=['de-DE','EUR'];
    // }else{
    //     types=['de-DE','EUR'];
    // }
    // console.log(types);
  return  ( new Intl.NumberFormat(types[0], { style: 'currency', currency: types[1], }).format(prices));
}
export default FormatPrice;
// maximumSignificantDigits: 2