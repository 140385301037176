import React from 'react';

import ReactDOM from 'react-dom/client';
// import ReactDOMServer from 'react-dom/server';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from './context/productContext';
import { CartProvider } from './context/CartContext';
import { UserProvider } from './context/userContext ';
// import { UserProvider } from './context/userProvider';
// import { Auth0Provider } from "@auth0/auth0-react";
// font-family: 'Ubuntu', sans-serif;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <UserProvider keys={`ks774h6g2v2j2l2big5d55hf77sarwara`}>
        <AppProvider>
          <CartProvider>
            <App />
          </CartProvider>
        </AppProvider> 
      </UserProvider>
  </React.StrictMode>
);

// const element = ReactDOMServer.renderToString(
//     <React.StrictMode>
//       <UserProvider keys={`ks774h6g2v2j2l2big5d55hf77sarwara`}>
//         <AppProvider>
//           <CartProvider>
//             <App />
//           </CartProvider>
//         </AppProvider> 
//       </UserProvider>
//   </React.StrictMode>
//   );
// document.getElementById("root").innerHTML = element;

// React.useEffect=React.useLayoutEffect;renderToStaticMarkup
// ReactDOMServer.renderToPipeableStream(element, options);
// ReactDOMServer.renderToString(
// ReactDOMServer.renderToPipeableStream(
//   <React.StrictMode>
//       <UserProvider keys={`ks774h6g2v2j2l2big5d55hf77sarwara`}>
//         <AppProvider>
//           <CartProvider>
//             <App />
//           </CartProvider>
//         </AppProvider> 
//       </UserProvider>
//   </React.StrictMode>,document.getElementById('root')
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
