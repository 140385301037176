import { useEffect } from "react";
import { useLocation } from "react-router";

const RouterScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    // window.scrollTo(0, 'scroll pos');
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }, [location]);

  return <>{props.children}</>
};

export default RouterScrollToTop;