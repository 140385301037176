import React,{useEffect,useState} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Banner from "./homes/banner";
// import Helmet from "react-helmet";
import axios from 'axios';

// import Menu from '@mui/material/Menu'; 
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material/styles';
const StyledContact = styled(Box)(({ theme }) => ({
  "& .MuiTypography-root":{fontFamily: 'Work Sans',},
  // "& label":{width:'25%'},
  "& input,textarea":{width:'75%'},
 }));

export default function Contact() {
  
  const [after, setAfter] = useState(null);
  const [name, setName] = useState(null);
  const [number, setNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  function details(typ,value) { 
    switch (typ) {
        case "Name":
            setName(value);
            break;
        case "Number":
            setNumber(value);
            break;
        case "Email":
            setEmail(value);
            break;
        case "Title":
            setTitle(value);
            break;
        case "Message":
            setMessage(value);
            break;
        default:
            break;
    }    
  }

  const handleSubmit=async (e,mode)=> {
    e.preventDefault();
    // setDisabled(true);
    try{
        // const  currency = localStorage.getItem('curr');
        const auth  = 'ks4h6g2v2j2l2big5h7b5jdc45vd5hcy5hf77di';
        const payload = {key: auth ,title: title ,message: message, 
            name:`${name}`,email:email,number:number };
        const res = await axios.post('https://www.digiinvite.in/api/query/index.php', payload);
        const dates =await res.data;
        // const payment =await dates.payment; 
        // setPaymentId(payment);
        console.log(dates); 
        setAfter(dates.message);
        document.getElementById('contact_form').reset();

      } catch(error){
        console.log('API_ERROR');
      }
  }
  // const scriptURL = 'https://script.google.com/macros/s/AKfycbzJi58V2ax8CJQQ9XQXXiVVSmiDKomcKXqWSXf6MIx0pORo3GZn1wgNAKNld49kC5cw/exec';
	// const form = document.forms['google-sheet'];
  // const handleChange=(e)=>{
  //   e.preventDefault();
	// 		fetch(scriptURL, { method: 'POST', body: new FormData(form)})
	// 		.then(response =>  {console.log('ok form',response)})
	// 		.then(response =>setTimeout(() => {console.log('ok form after 3 sec')}, 3000))
	// 		.catch(error => {console.log('filed error form')});
			// $("#").prop("disabled",true);
			//  $("#").prop("disabled",true);
			// $('input[type="text"],input[type="email"], textarea').val('');
			// $("#").prop("disabled",false);
  // }
  
  useEffect(() => {
    document.title = `Contact Us || India's Best Digital Invitation E Card Maker - Animated Invitation Maker - Digi Invite`;  
  }, []);

  return (
    <StyledContact>
    {/* <Helmet>
        <title>Contact Us || India's Best Digital Invitation E Card Maker, Animated Invitation Maker - Digi Invite</title>
        <meta name="description" content="One of the top online makers of digital invitations is Digiinvite.
      Create personalized invitations for Rajasthani, Punjabi, Sikh, and Hindu wedding, Engagements, birthdays, housewarmings, parties, and anniversaries in 1-2 business days.
      create stylish digital invitations such as E-Cards, PDFs, and Animated video invitations. We are one of the only company that can provide caricature invitations as soon as possible."/>
    </Helmet> */}
      <Banner name={`Contact Us`}/>
      <Box sx={{width:'100%'}} >
        <Box   className="container" >
          <Box  sx={{width:'100%',bgcolor: 'background.paper',p: '30px 0 0 0',
            display: 'flex',justifyContent:'center',}}>
              {/* <Typography sx={{fontWeight: 400,fontSize:'0.9rem',  }} >
                Home /  Contact Us
              </Typography> */}
            
          </Box>       
        </Box>
      </Box>
      <Box sx={{width:'100%'}} >
        <Box   className="container" >
          <Box sx={{}} className="row">
            <Box  sx={{width:{ xs: '100%', md: '33%' },p:2,}} className='contp'> 
              <h4>Call US</h4>
              <MenuList className="list-unstyled list-inline">
                <MenuItem><i className="fa fa-phone"></i></MenuItem>
                <MenuItem><Typography><b>Mobile No: </b>88-587-83-587<br/><b>Phone No: </b>0164-796-8654</Typography></MenuItem>
              </MenuList> 
              <h4>CONTACT US</h4>
              <MenuList className="list-unstyled list-inline">
                <MenuItem><i className="fa fa-envelope"></i></MenuItem>
                <MenuItem><Typography><b>Mail: </b>info@digiinvite.in </Typography></MenuItem>
              </MenuList> 
              <h4>LOCATION</h4>
              <MenuList className="list-unstyled list-inline">
                <MenuItem><i className="fa fa-location-arrow"></i></MenuItem>
                <MenuItem><Typography><b>Visits: </b> 
                Ajit Road, Corner Building,<br/> 1st Floor, Near Ghora Chowk,<br/> Bathinda-151001, Punjab
                {/* Bathinda, Punjab 151001 India */}
                </Typography></MenuItem>
              </MenuList>
            </Box>
            <Box sx={{width:{ xs: '100%', md: '67%' },p:2,}} className="contact-address">
              <div>
                <form   id="contact_form" acceptCharset="UTF-8" className="contact-form"
                onSubmit={(e) => {handleSubmit(e,'Checkout')}}>
                  <div className="dzFormMsg">{after}</div>
                  {/* <input type="hidden" name="form_type" defaultValue="contact" />
                  <input type="hidden" name="utf8" defaultValue="✓" /> */}
                  <div className="row">
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}} > 
                      <label htmlFor="ContactFormName" className="text-right">Name</label>
                      <input className=" " type="text" id="ContactFormName" name="Name" placeholder="Name" autoCapitalize="words" defaultValue="" 
                      onChange={(e)=>details('Name',e.target.value)}/>
                    </Box>
                    {/* name="contact[name]" */}
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}} >
                      <label htmlFor="ContactFormEmail" className="text-right">Email</label>
                      <input className=" " type="email" id="ContactFormEmail" name="Email"  placeholder="Email" autoCorrect="off" autoCapitalize="off" defaultValue="" 
                      onChange={(e)=>details('Email',e.target.value)}/>
                    </Box>
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}} >
                      <label htmlFor="ContactFormMobile" className="text-right">Phone No</label>
                      <input className=" " type="tel" id="ContactFormMobile"  name="Mobile" placeholder="Mobile" autoCorrect="off" autoCapitalize="off" defaultValue="" 
                      onChange={(e)=>details('Number',e.target.value)}/>
                    </Box>
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}} >
                      <label htmlFor="ContactFormSub" className="text-right">Subject</label>
                      <input className="" type="text" id="ContactFormSub" name="Website" placeholder="Subject" autoCapitalize="words" defaultValue="" 
                      onChange={(e)=>details('Title',e.target.value)}/>
                    </Box>
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}} >
                      <label htmlFor="ContactFormMessage" className="text-right">Message</label>
                      <textarea   rows='3'  id="ContactFormMessage" name="MASSAGE" placeholder="Message"
                      onChange={(e)=>details('Message',e.target.value)}></textarea>
                    </Box>
                    <Box sx={{width:{ xs: '100%', sm: '50%' }}}>

                    </Box>
                  </div>
                  <button type="submit" className="btn"  >Send Message</button>
                </form>
              </div>
            </Box>
          </Box>        
          <Box className="row map">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3447.8691620813056!2d74.95098511446743!3d30.21228151758064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39173391c02f3089%3A0x666d35a28d53a347!2sI%20SMART%20MEDIA!5e0!3m2!1sen!2sin!4v1669891488815!5m2!1sen!2sin"
           width="100%" height="450" style={{border:"0"}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          </Box>        
        </Box>
      </Box>
    </StyledContact>
  )
}
